import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectors as UserSelectors } from '../../redux/UserRedux'
import { actions as BeneficiaryActions } from '../../redux/BeneficiaryRedux'
import { actions as OfficeActions, selectors as OfficeSelectors } from '../../redux/OfficeRedux'

import Logo from '../../static/assets/logo-my-talents.svg'
import WelcomePopin from '../../components/organisms/WelcomePopin/WelcomePopin'
import './BeneficiaryCreationPage.scss';
import { getActivityOptions, getSituationOptions } from '../../api/utils/OptionsConst'

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  office: OfficeSelectors.office(state),
})

const mapDispatchToProps = (dispatch) => ({
  getOffice: () => dispatch(OfficeActions.getCurrentUserOffice()),
  saveCurrentBeneficiary: (beneficiary) =>
    dispatch(BeneficiaryActions.saveCurrentBeneficiary(beneficiary)),
})

class BeneficiaryCreationPage extends Component {

  handleChange = (value) => {
    const newValues = {
      ...this.state.values,
      [value.id]: value.value,
    }

    if (newValues.situation !== 'cdd' && newValues.situation !== 'cdi') {
      newValues.activity = null
    }

    this.setState({
      values: newValues,
    })
  }

  handleSubmit = () => {
    const { user, saveCurrentBeneficiary } = this.props

    const newUser = {
      ...user,
      phone_mobile: this.state.values.mobile,
      cursus_professional_situation: this.state.values.situation,
      cursus_company_activity_sector: this.state.values.activity,
    }

    saveCurrentBeneficiary(newUser)

    window.location.href = '/';
  }

  buildSteps = () => {
    const { office, user } = this.props

    const situationOptions = getSituationOptions()
    const activityOptions = getActivityOptions()

    const disabledNextButtonStep1 = this.state.values.mobile === ''
    let disabledNextButtonStep2 = false

    const situationComboContent = [
      {
        title: "Situation professionnelle",
        id: 'situation',

        placeholder: "Veuillez choisir votre situation professionnelle",
        options: situationOptions,
        value: this.state.values.situation,
      },
    ]
    if (this.state.values.situation === 'cdd' || this.state.values.situation === 'cdi') {
      situationComboContent.push({
        title: "Secteur d'activité *",
        id: 'activity',
        placeholder: "Veuillez choisir votre secteur d'activité",
        options: activityOptions,
        value: this.state.values.activity,
      })

      if (this.state.values.activity === null) {
        disabledNextButtonStep2 = true
      }
    }

    return [
      {
        steps: 2,
        current: 1,
        mainTitle: "Bienvenue, ",
        name: `${user.first_name} ${user.last_name}`,
        content: `Merci de la confiance que vous accordez ainsi à notre cabinet ${office.name} ! Pour accèder à votre dossier il reste quelques informations personnelles à compléter`,
        inputType: WelcomePopin.inputTypes.phone,
        nextId: 'next-step',
        next: "Suivant",
        inputContent: {
          inputTitle: "Votre numéro portable *",
          placeholder: '06 45 25 15 15',
          inputName: 'mobile',
          inputValue: this.state.values.mobile,
        },
        onNext: () => {
          this.setState({
            step: 1,
          })
        },
        disabledNextButton: disabledNextButtonStep1,
      },
      {
        steps: 2,
        current: 2,
        mainTitle: "Bienvenue, ",
        name: `${user.first_name} ${user.last_name}`,
        content: `Merci de la confiance que vous accordez ainsi à notre cabinet ${office.name} ! Pour accèder à votre dossier il reste quelques informations personnelles à compléter`,
        inputType: WelcomePopin.inputTypes.situation,
        prevId: 'prev-step',
        nextId: 'next-step',
        prev: "Précédent",
        next: "Valider",
        comboContent: situationComboContent,
        onPrev: () => {
          this.setState({
            step: 0,
          })
        },
        onNext: this.handleSubmit,
        disabledNextButton: disabledNextButtonStep2,
      },
    ]
  }

  constructor(props) {
    super(props)

    this.state = {
      step: 0,
      values: {
        mobile: '',
        situation: getSituationOptions()[0].value,
        activity: null,
      },
    }
  }

  componentDidMount() {
    this.props.getOffice()
  }

  render() {
    const { office } = this.props

    if (!office) {
      return null
    }

    const steps = this.buildSteps()

    return (
      <div className="beneficiary-creation-page">
        <Logo className="logo" />

        <WelcomePopin {...steps[this.state.step]} onChange={this.handleChange} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryCreationPage)