import React, { useEffect, useState } from 'react';

import { NewTag } from '../../atoms/NewTag/NewTag';
import Icon from '../../atoms/Icon/Icon';
import { DeleteMemberGroupPopin } from '../../../containers/groups/DeleteMemberGroupPopin/DeleteMemberGroupPopin';
import { getBeneficiary } from '../../../api/BeneficiaryAPI';

import './MemberGroupCard.scss';

export const MemberGroupCard = ({ member, deleteMember, onClick, isConsultantOfTalent = null }) => {
  const [showModalDeleteMember, setShowModalDeleteMember] = useState(false);
  const [beneficiary, setBeneficiary] = useState({});
  const [showPopover, setShowPopover] = useState(false);

  const handleDeleteMember = () => {
    deleteMember(member);
    setShowModalDeleteMember(false);
  };
  const renderAvatar = (image) => {
    if (image) return <img className="avatar" src={image} alt="avatar-member" />;
    return (
      <div className="avatar">
        <Icon icon="person" color={'accent'} />
      </div>
    );
  };

  useEffect(() => {
    let mounted = true;
    
      getBeneficiary(member.id).then((response) => {
        if (mounted) {
          setBeneficiary(response);
        } else {
        }
      });
    
    return () => {
      mounted = false;
    }
  }, [member]);

  const statuses = [
    { key: '', value: 'Tous' },
    { key: 'created', value: 'Prospect' },
    { key: 'wait_financer', value: 'En attente de financement' },
    { key: 'in_validation', value: 'En attente de validation' },
    { key: 'in_progress', value: 'En cours' },
    { key: 'follow_up', value: 'Suivi professionel' },
    { key: 'archived', value: 'Historique' },
  ];
  const options = statuses.map((item) => ({
    value: item.key,
    label: item.value,
  }));

  const onHoverHandler = (value) => {
    setShowPopover(value);
  }

  const { first_name, last_name, email, phone, image_url } = member;
  return (
    <div className="member-group-card"   >
      <div className="member-group-card-header">
        <div style={{display: 'flex', gap: '10px'}} >
        <NewTag title={statuses.find((e) => e.key === beneficiary?.status ?? '')?.value ?? ''} />
          {(isConsultantOfTalent !== null) && (isConsultantOfTalent ? '' : <div onMouseEnter={() => setShowPopover(true)}
            onMouseLeave={() => setShowPopover(false)}><Icon icon={'error'} color={Icon.color.Red}   />{showPopover && <div style={{position: 'absolute', top: '-20px', fontSize: 14, whiteSpace: 'nowrap', textAlign: 'center', left: 0, backgroundColor: 'var(--red-medium)', color: 'white', padding: '1px 5px', borderRadius: '5px'}}>Vous n'êtes pas résponsable de ce talent</div>}</div>)}
          </div>
        <div onClick={(event) => {event.stopPropagation(); setShowModalDeleteMember(true); event.stopPropagation(); }}>
          <Icon icon={'trash2'} />
        </div>
      </div>
      <div className="member-group-card-content" onClick={() => {if (isConsultantOfTalent) {onClick()}}}>
        <div className="member-informations">
          {renderAvatar(
            `${process.env.REACT_APP_ASSETS_API_STATIC_URL}${
              image_url
                ? image_url.replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')
                : ''
            }`,
          )}
          <h1>
            {first_name} {last_name}
          </h1>
        </div>
        <p>{email}</p>
        <p>{phone}</p>
      </div>
      {/* {showModalDeleteMember && <DeleteMemberGroupPopin member={member} open={showModalDeleteMember} onClose={() => setShowModalDeleteMember(false)} onDeleteMemberGroup={() => {deleteMember(member); setShowModalDeleteMember(false)}}/>} */}
      {showModalDeleteMember && (
        <DeleteMemberGroupPopin
          member={member}
          open={showModalDeleteMember}
          onClose={() => setShowModalDeleteMember(false)}
          onDeleteMemberGroup={handleDeleteMember}
        />
      )}
    </div>
  );
};
