import { axiosInstance, axiosInstanceBigBoss } from "./baseApi";

export const getConsultantInfos = async (user_id) => {
    const response = await axiosInstance.get(`consultants/user/${user_id}`);
    return response.data;
}

export const getAllConsultants = async (office_id) => {
    const response = await axiosInstance.get(`offices/${office_id}/consultants/all`);
    return response.data;
}

export const getBeneficiary = async (beneficiary_id) => {
    const response = await axiosInstanceBigBoss.get(`/beneficiaries/${beneficiary_id}`);
    return response.data;
}

export const getTalentsByConsultant = async (consultant_id) => {
    const response = await axiosInstance.get(`/consultants/${consultant_id}/beneficiaries/all`);
    return response.data;
}

export const shareBeneficiary = async (sharing_data) => {
    const response = await axiosInstance.post(`beneficiaries/share`, sharing_data);
    return response.data;
}

export const getConsultantsByTalents = async (talent_id) => {
    const response = await axiosInstance.get(`beneficiaries/${talent_id}/consultants/all`);
    return response.data;
}

