import * as yup from 'yup'

import { Controller, useForm } from 'react-hook-form'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as userActions, selectors as userSelectors } from '../../redux/UserRedux'

import CheckBoxField from '../../components/molecules/CheckBoxField/CheckBoxField'
import { Helmet } from 'react-helmet'
import InputField from '../../components/molecules/InputField/InputField'
import { Link } from 'react-router-dom'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'
import { actions as navigationActions } from '../../redux/NavigationRedux'
import { usePortalCustomization } from '../../hooks/usePortalCustomization'
import { yupResolver } from '@hookform/resolvers/yup'
import { useParams } from 'react-router'
import './PortalLoginPage.scss'
const schema = yup
  .object({
    email: yup.string().email('Veuillez saisir une adresse email valide').required(),
    password: yup.string().required(),
  })
  .required()

const PortalLoginPage = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(userSelectors.isLoading)
  const { office } = useParams()
  const { labels, slug } = usePortalCustomization({ slug: office })

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
      persist: false,
    },
  })

  useEffect(() => {
    // clear the theme every time we get to this login page
    const items = ['_assets', '_theme', '_labels']

    for (const item of items) {
      localStorage.removeItem(item)
    }
  }, [])

  useEffect(() => {
    dispatch(navigationActions.evaluate())
  }, [dispatch])

  const onSubmit = ({ email, password, persist }) => {
    dispatch(userActions.setIsLoading(true))

    dispatch(userActions.login({ email, password, persist }))
  }

  return (
    <>
      <Helmet>
        <title>{labels?.title}</title>
      </Helmet>

      <div className="portal-login">
        <section className="login-form-container">
          <h2>
            {labels?.prompt} <b>{labels?.name}</b>
          </h2>

          <form onSubmit={handleSubmit(onSubmit)} className="login-form">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <InputField
                  name="email"
                  type="email"
                  id="email"
                  placeholder={"Insérez votre adresse email..."}
                  title={"Email"}
                  disabled={isLoading}
                  inputProps={field}
                  required
                  error={errors.email}
                />
              )}
            />

            <Controller 
              name="password"
              control={control}
              render={({ field }) => ( 
                <InputField
                  name="password"
                  id="password"
                  placeholder={"Insérez votre mot de passe..."}
                  title={"Mot de passe"}
                  type="password"
                  required
                  disabled={isLoading}
                  inputProps={field}
                  error={errors.password}
                />
              )}
            />

            <Link className="forgot-password" to={`/${slug}/auth/forgot-password`}>
              {"Mot de passe oublié ?"}
            </Link>

            <Controller
              name="persist"
              control={control}
              render={({ field }) => {
                return (
                  <CheckBoxField
                    name="persist"
                    label={"Rester connecté"}
                    disabled={isLoading}
                    value={field.value}
                    inputProps={field}
                  />
                )
              }}
            />
            <PrimaryButton label={"Se connecter"} id="connect" type="submit" loading={isLoading} />
          </form>
        </section>

        <section className="cover" />
      </div>
    </>
  )
}

export default PortalLoginPage
