import React from "react";
import Icon from "../../../../atoms/Icon/Icon";
import './LinkRessource.scss';

export const LinkRessource = ({ ressources, isExport = false }) => {
    return (
        <div className="links-ressource">
            {ressources.map((element,  index) => (
                <div className={element.type} key={index}>
                    {element.type === "file" && <Icon icon="document-read" />}
                    {element.type === "video" && <Icon icon="document-video" />}
                    {element.type === "link" && <Icon icon="document-link" />}
                    
                    {!isExport && <p>{element.title}</p>}
                    {isExport && <a href={element.url}>{element.title}</a>}
                    
                </div>
            ))}
        </div>
    )
}