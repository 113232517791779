import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Step from '../../atoms/Step/Step'
import './QuizStep.scss'
export default class QuizStep extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    stepLabel: PropTypes.string,
    stepOutline: PropTypes.bool,
  }

  static defaultProps = {
    stepLabel: null,
    stepOutline: true,
  }

  render() {
    const { label, stepLabel, stepOutline } = this.props
    return (
      <div className="quiz-step">
        {stepLabel !== null && <Step label={stepLabel} outline={stepOutline} />}
        <div className="label">{label}</div>
      </div>
    )
  }
}
