/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import QuizYesNo from '../../molecules/QuizYesNo/QuizYesNo';
import QuizTextArea from '../../molecules/QuizTextArea/QuizTextArea';
import QuizStars from '../../molecules/QuizStars/QuizStars';
import QuizQcm from '../../molecules/QuizQcm/QuizQcm';
import { updateModule } from '../../../api/CourseApi';
import './ParcoursQuiz.scss';
import QuizOpinionScale from '../../molecules/QuizOpinionScale/QuizOpinionScale';
import QuizDate from '../../molecules/QuizDate/QuizDate';
import { MultipleQuizQcm } from '../../molecules/MultipleQuizQcm/MultipleQuizQcm';

export const ParcoursQuiz = ({
  module,
  question,
  isAnswer,
  roleUser,
  setNewModule,
  isQuizDone,
}) => {
  const [answer, setAnswer] = useState({ id: null, value: null });
  const [options, setOptions] = useState([]);
  const [moduleUpdated, setModuleUpdated] = useState({});
  const onChangeAnswer = (event) => {
    setAnswer({ id: question.id, value: event.value });
    question.answer = event.value;
  };

  useEffect(() => {
    setModuleUpdated(module);
  }, [module]);

  useEffect(() => {
    if (question) {
      if (question.answer && question.type !== 'yes_no') {
        setAnswer({ id: question.id, value: question.answer });
      } else if (question.answer && question.type === 'yes_no') {
        if (question.answer === 'false') {
          setAnswer({ id: question.id, value: false });
        } else if (question.answer === 'true') {
          setAnswer({ id: question.id, value: true });
        }
      } else {
        if (question.type === 'yes_no') {
          setAnswer({ id: question.id, value: true });
        } else {
          setAnswer({ id: question.id, value: '' });
        }
      }
    }
  }, [question]);

  useEffect(() => {
    isAnswer(answer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer]);

  useEffect(() => {
    setNewModule(moduleUpdated);
  }, [moduleUpdated]);

  useEffect(() => {
    const debouncedUpdateModule = debounce(updateAllModule, 100);
    debouncedUpdateModule();
    return () => debouncedUpdateModule.cancel();
  }, [answer]);

  const updateAllModule = () => {
    if (question && moduleUpdated && answer) {
      const currentQuestion = moduleUpdated.data[0].module_form_questions.find(
        (element) => element.id === question.id,
      );

      const updatedQuestions = moduleUpdated.data[0].module_form_questions.map((question) => {
        if (question.id === currentQuestion.id) {
          if (question.type === 'yes_no') {
            question.answer = answer.value.toString();
            return { ...question, answer: answer.value.toString(), done: 3 };
          } else {
            question.answer = answer.value;
            return { ...question, answer: answer.value, done: 3 };
          }
        } else {
          return question;
        }
      });

      const updatedData = {
        ...moduleUpdated,
        data: [{ ...module.data[0], module_form_questions: updatedQuestions }],
      };

      updateModule(updatedData)
        .then((response) => {
          setModuleUpdated(response.module);
        })
        .finally(() => {
          isAnswer(answer);
          const questions = updatedData.data[0].module_form_questions;
          questions.forEach((element) => {
            if (element.id === question.id) {
              isAnswer(answer);
            }
          });
        });
    }
  };

  useEffect(() => {
    if (question && question.type === 'yes_no') {
      if (question.answer === 'false') {
        setAnswer({ id: question.id, value: false });
      } else if (question.answer === 'true') {
        setAnswer({ id: question.id, value: true });
      }
    } else if (question && question.type === 'select') {
      const options = JSON.parse(question.options);
      const choices = options.choices.map((choice) => ({
        label: choice,
        isSelected: question.answer === choice ? true : false,
      }));
      setOptions(choices);
    } else if (question && question.type === 'multiple_choice') {
      if (question.answer && question.answer.length > 0) {
        const options = JSON.parse(question.answer);
        setOptions(options);
      } else {
        const options = JSON.parse(question.options);
        const choices = options?.choices.map((choice) => ({
          label: choice.label ? choice.label : choice,
          isSelected: choice.isSelected,
        }));
        setOptions(choices);
      }
    }
  }, [question]);

  // yes_no
  const renderYesNo = () => {
    const onChangeAnswerYesNo = (event) => {
      if (event.value === null) return;
      setAnswer({ id: event.id, value: event.value });
      if (event.value)
        question.answer = event.value.toString();
    };

    return (
      <QuizYesNo
        id={question.id}
        label={question.title}
        stepLabel={Number(question.order).toString()}
        yesLabel={'Oui'}
        noLabel={'Non'}
        onChange={(e) => onChangeAnswerYesNo(e)}
        value={answer && answer.value === false ? answer.value : true}
        image={question.image}
      />
    );
  };

  // text
  const renderText = () => {
    return (
      <QuizTextArea
        isQuestion={true}
        placeholder={'Ecrivez votre réponse...'}
        stepLabel={Number(question.order).toString()}
        label={question.title}
        onChange={onChangeAnswer}
        value={question.answer && !answer ? question.answer : answer.value}
        image={question.image}
      />
    );
  };

  // multiple_choice
  const renderMultipleChoice = () => {
    const handleMultipleChoiceChange = (choices) => {
      choices.forEach((choice) => {
        if (choice.isSelected === undefined) {
          choice.isSelected = false;
        }
      });
      setAnswer({ id: question.id, value: JSON.stringify(choices) });
      question.answer = JSON.stringify(choices);
    };

    return (
      <>
        <p>Question à choix multiples</p>
        <MultipleQuizQcm
          label={`${question.title}${
            question.options.required != null && question.options.required ? ' *' : ''
          }`}
          stepLabel={Number(question.order).toString()}
          optionsProps={options}
          image={question.image}
          onChange={handleMultipleChoiceChange}
        />
      </>
    );
  };

  // rating
  const renderRating = () => {
    const handleQuizStarsOnChange = (choice) => {
      setAnswer(choice);
      question.answer = choice.value;
    };

    return (
      <QuizStars
        id={question.id}
        label={question.title}
        stepLabel={Number(question.order).toString()}
        starsCount={5}
        selectedStars={question.answer && !answer ? question.answer : answer.value}
        onChange={handleQuizStarsOnChange}
        image={question.image}
      />
    );
  };

  // select
  const renderSelect = () => {
    const handleMultipleChoiceChange = (choice) => {
      const selectedOption = options.find((option) => (option.label || choice) === choice.id);
      const updatedOptions = options.map((element) => ({
        ...element,
        isSelected: element?.label === selectedOption?.label,
      }));

      setOptions(updatedOptions);
      const newAnswer = { id: question.id, value: selectedOption.label };
      question.answer = newAnswer.value;
      setAnswer(newAnswer);
    };

    return (
      <QuizQcm
        label={`${question.title}${
          question.options.required != null && question.options.required ? ' *' : ''
        }`}
        stepLabel={Number(question.order).toString()}
        options={Array.isArray(options) ? options : []}
        onChange={handleMultipleChoiceChange}
        isSelected={answer && answer.value}
        image={question.image}
      />
    );
  };

  const renderOpinionScale = () => {
    const handleOpinionScaleChange = (choice) => {
      setAnswer(choice);
      question.answer = choice.value;
    };
    return (
      <QuizOpinionScale
        id={question.id}
        stepLabel={Number(question.order).toString()}
        label={`${question.title}${
          question.options.required != null && question.options.required ? ' *' : ''
        }`}
        scale={10}
        // value={answer && answer.value}
        value={answer && +answer.value}
        onChange={handleOpinionScaleChange}
        image={question.image}
      />
    );
  };

  const renderDate = () => {
    const month = [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ];

    const handleQuizDateOnChange = (choice) => {
      setAnswer(choice);
      question.answer = choice.value;
    };

    return (
      <QuizDate
        id={question.id}
        stepLabel={Number(question.order).toString()}
        label={`${question.title}${
          question.options.required != null && question.options.required ? ' *' : ''
        }`}
        monthLabels={month}
        value={answer && typeof answer.value === 'string' ? answer.value : ''}
        dayTitle={'Jour'}
        monthTitle={'Mois'}
        yearTitle={'Année'}
        onChange={handleQuizDateOnChange}
        image={question ? question.image : ''}
      />
    );
  };
  // group
  const renderGroup = () => {
    return (
      <div>
        <h3>{question.title}</h3>
      </div>
    );
  };

  return (
    <div className="parcours-quiz">
      {question ? (
        <div className="parcours-quiz-content">
          {question.type === 'yes_no' && renderYesNo()}
          {question.type === 'text' && renderText()}
          {question.type === 'rating' && renderRating()}
          {question.type === 'select' && renderSelect()}
          {question.type === 'multiple_choice' && renderMultipleChoice()}
          {question.type === 'group' && renderGroup()}
          {question.type === 'opinion_scale' && renderOpinionScale()}
          {question.type === 'date' && renderDate()}
          {/* {question === undefined && renderFinish()} */}
        </div>
      ) : (
        <div>Aucune réponse n'est attendue.</div>
      )}
      <div className="validation-btn"></div>
    </div>
  );
};
