/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from 'react-redux'
import { selectors as UserSelectors } from "../../../redux/UserRedux";

import { addGroup, addTalentGroup, deleteTalentGroup, updateGroup } from "../../../api/groupsApi";
import { addTalentMission, getMission } from "../../../api/MissionsApi";

import { AddGroupPopin } from "../AddGroupPopin/AddGroupPopin";
import { IconBack } from "../../../components/atoms/IconBack/IconBack";
import { GroupContainer } from "../GroupContainer/GroupContainer";
import PopinLayout from "../../../layouts/PopinLayout/PopinLayout";
import PrimaryButton from "../../../components/atoms/PrimaryButton/PrimaryButton";

import { useNavigation } from "../../../hooks/useNavigation";
import { useHistory } from 'react-router-dom';

import './AddGroupLayout.scss';
import { useLocation } from 'react-router-dom';
import { useToaster } from '../../../hooks/useToaster';
import { Toaster } from '../../../components/atoms/Toaster/Toaster';

export const AddGroupLayout = () => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();
    const userIdParams = query.get('user_id');
    const clientIdParams = query.get('client_id');
    const missionIdParams = query.get('mission_id');
    const user = useSelector(UserSelectors.user);
    const [newGroup, setNewGroup] = useState({});
    const [showAddModal, setShowAddModal] = useState(false);
    const [members, setMembers] = useState([]);
    const [disabledSaveButton, setDisabledSaveButton] = useState(true);
    const history = useHistory();
  const { toasters, addToaster, removeToaster } = useToaster();


    const handleShareGroupOnClick = () => { }
    const { redirect } = useNavigation();

    useEffect(() => {
        if (userIdParams && clientIdParams && missionIdParams) {
            setNewGroup({ ...newGroup, user_id: parseInt(userIdParams), client_id: parseInt(clientIdParams), mission_id: parseInt(missionIdParams) })
        } else {
            setNewGroup({ ...newGroup, user_id: user.id })
        }
    }, [user.id, userIdParams, clientIdParams, missionIdParams])

    useEffect(() => {
        const { name, user_id, client_id, mission_id } = newGroup;
        name && user_id && setDisabledSaveButton(false);

        name && user_id && client_id && !mission_id && setDisabledSaveButton(true);
    }, [newGroup])

    const handleSaveGroupOnClick = () => {
        const { id } = newGroup;
        if (id) {
            handleUpdateGroup()
            // redirect('/consultant/beneficiaries/groups');
            history.push({ pathname: '/consultant/beneficiaries/groups', state: { newGroup} });
        }
        else {
            handleAddGroup();
        }
    }

    const handleDropdownChange = (dropdown) => {
        const { value, id } = dropdown
        setNewGroup({ ...newGroup, [id]: value.value })

        if (id === "client_id" && value === null) {
            setNewGroup({ ...newGroup, mission_id: null })
        }
    }

    const handleInputChange = (input) => {
        const { id, value } = input;
        id === "name" && setNewGroup({ ...newGroup, name: value });
    }

    const getMissionAndAddToGroup = async(mission_id, groupId) => {
        await getMission(mission_id).then((response) => {
            if (response.mission && response.mission.talents) {
                const talents = response.mission.talents.map(talent => {return {email: talent.email, id: talent.id, first_name: talent.first_name, last_name: talent.last_name, phone: talent.phone, image_url : talent.image_url}});
                getNewMembers(response.mission.talents, groupId);
                // window.location.href = `/consultant/beneficiaries/groups/${groupId}`;
            }
        })
    }

    // Add group 
    const handleAddGroup = async () => {
        const grouptmp = await addGroup(newGroup).then((response) => {
            setNewGroup(response.group);
            setDisabledSaveButton(true);
            getMissionAndAddToGroup(response.group.mission_id, response.group.id);
            addToaster("Création groupe", "Le groupe à été créé avec succès", "success");

            return response.group
        }).catch((error) => {
            addToaster("Erreur", "Une erreur est survenue lors de l'ajout du groupe", "error");
        });
        // window.location.href = `/consultant/beneficiaries/groups/${grouptmp.id}`;


    }

    // Add talent mission
    const handleAddTalentMission = (mission_id, talent_id) => {
        addTalentMission(mission_id, talent_id);
    }

    // Update group
    const handleUpdateGroup = () => {
        updateGroup(newGroup.id, newGroup).then(() => {
            addToaster("Groupe ajouté", `Le groupe ${newGroup.name} a été ajouté avec succès`, "success");
           

        })
        .catch(() => {
            addToaster("Erreur", `Une erreur est survenue lors de l'ajout du groupe ${newGroup.name}`, "error")
        })
        ;
    }

    // Delete talents
    const handleDeleteMember = (member) => {
        const filterMembers = members.filter(m => m.id !== member.id);
        setMembers(filterMembers)
        deleteTalentGroup(newGroup.id, member.id)
    }

    const getNewMembers = useCallback((newMembers, groupId = null) => {
        setMembers((prevMembers) => [...prevMembers, ...newMembers]);
        setShowAddModal(false);
        setNewGroup((prevGroup) => ({ 
          ...prevGroup, 
          group_users: [...(prevGroup.group_users || []), ...newMembers] 
        }));
      
        newMembers.forEach(async (talent) => {
          const new_talent = { user_id: talent.user_id || talent.id };
          await addTalentGroup(groupId || newGroup.id, new_talent);
          handleAddTalentMission(newGroup.mission_id, new_talent);
        });
      }, [newGroup, handleAddTalentMission]);

    const renderContent = () => {
        return (
            <GroupContainer
                user={user}
                group={newGroup}
                members={members}
                deleteMember={handleDeleteMember}
                showModalAddMember={() => setShowAddModal(true)}
                handleInputChange={handleInputChange}
                handleDropdownChange={handleDropdownChange} />
        )
    }

    const renderHeader = () => {
        const { name } = newGroup
        return (
            <div className="group-header">
                <div className="group-title">
                    <IconBack iconColor={"accent"} />
                    <input value={name ? name : ""} placeholder="Nouveau groupe" onChange={(name) => setNewGroup({ ...newGroup, name: name.target.value })} />
                </div>
                <div className="group-actions">
                    <PrimaryButton id={"share"} outline={true} label={"Partager"} onClick={handleShareGroupOnClick} />
                    <PrimaryButton id={"save"} label={"Sauvegarder"} onClick={handleSaveGroupOnClick} disabled={disabledSaveButton} />
                </div>
            </div>
        )
    }

    return (
        <>
            <PopinLayout header={renderHeader()} content={renderContent()} />
            {showAddModal && <AddGroupPopin open={showAddModal} onClose={() => setShowAddModal(false)} user={user} setMembers={getNewMembers} />}
            <div className="notification-container" style={{zIndex: 99999}}>
        {toasters.map((toaster) => (
          <Toaster
            key={toaster.id}
            title={toaster.title}
            message={toaster.message}
            type={toaster.type}
            onClose={() => removeToaster(toaster.id)}
          />
        ))}
      </div>
        </>
    )
}