import React, { Component } from 'react'
import { connect } from 'react-redux'

import Page from '../../containers/global/Page/Page'

import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../redux/BeneficiaryRedux'
import { actions as BeneficiaryGroupActions } from '../../redux/BeneficiaryGroupRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import ConsultantBeneficiaryExplorerContainer from '../../containers/consultant/ConsultantBeneficiaryExplorerContainer/ConsultantBeneficiaryExplorerContainer'


const mapStateToProps = (state, props) => ({
  id: props.match.params.user_id,
  pathname: NavigationSelectors.pathname(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
})

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  getBeneficiary: (id) => dispatch(BeneficiaryActions.getBeneficiary(id)),
  getCurrentUserGroups: () => dispatch(BeneficiaryGroupActions.getCurrentUserGroups()),
  resetGroups: () => dispatch(BeneficiaryGroupActions.resetGroups()),
})

class ConsultantBeneficiaryExplorerPage extends Component {
  componentDidMount() {
    if (!/\/(explorer)$/.test(this.props.pathname)) {
      this.props.redirect(`${this.props.pathname}/explorer`)
    }

    this.props.getBeneficiary(this.props.id)
  }

  render() {
    const { beneficiary } = this.props

    if (beneficiary === null) {
      return null
    }

    return <ConsultantBeneficiaryExplorerContainer beneficiary={beneficiary} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(ConsultantBeneficiaryExplorerPage))