import React from 'react'
import Modal from 'react-responsive-modal'
import './Popin.scss';


import { useSelector } from "react-redux";
import { selectors as ColorThemeSelectors } from "../../../redux/ColorThemeRedux";

export const Popin = ({ open, onClose, overrideModalStyles, focusTrapped, children, classes, clickOutSide = true }) => {
  const currentTheme = useSelector(ColorThemeSelectors.getTheme);

  const styles = { modal: { padding: 0, borderRadius: 6, ...overrideModalStyles } }
  return (
    <Modal
      open={open}
      onClose={onClose}
      showCloseIcon={false}
      styles={styles}
      classNames={{ modal: classes + " responsive-modal" }}
      center
      focusTrapped={focusTrapped}
      closeOnOverlayClick={clickOutSide}
    >
      <div className='App' data-theme={currentTheme ? currentTheme : "mytalents"}>
        {open && children}
      </div>
    </Modal>
  )
}
