import React, { useState, useEffect } from 'react';

const IframeChecker = ({ url }) => {
  const [iframeLoaded, setIframeLoaded] = useState(null);
  const [isEmbeddable, setIsEmbeddable] = useState(false);

  const embeddableMimeTypes = [
    'text/html',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/svg+xml',
    'application/pdf',
    'video/mp4',
    'video/webm',
    'video/ogg',
    'audio/mpeg',
    'audio/ogg',
    'audio/wav',
    'text/plain',
    'text/css',
    'text/javascript',
    'application/xml',
    'application/json',
  ];

  useEffect(() => {
    const checkEmbeddable = async () => {
      try {
        const response = await fetch(url, { method: 'HEAD' });
        const contentType = response.headers.get('content-type');

        // Vérifiez si le type de contenu est embeddable
        if (contentType && embeddableMimeTypes.includes(contentType)) {
          setIsEmbeddable(true);
        } else {
          setIsEmbeddable(false);
        }
      } catch (error) {
        setIsEmbeddable(false);
      }
    };

    checkEmbeddable();
  }, [url]);

  useEffect(() => {
    if (isEmbeddable === false) {
      setIframeLoaded(false);
      return;
    }

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none'; // Ne pas afficher l'iframe
    iframe.src = url;

    const handleLoad = () => {
      setIframeLoaded(true);
      document.body.removeChild(iframe);
    };

    const handleError = () => {
      setIframeLoaded(false);
      document.body.removeChild(iframe);
    };

    iframe.onload = handleLoad;
    iframe.onerror = handleError;

    document.body.appendChild(iframe);

    // Nettoyage
    return () => {
      if (document.body.contains(iframe)) {
        document.body.removeChild(iframe);
      }
    };
  }, [url, isEmbeddable]);

  if (iframeLoaded === null) {
    return <p>Checking iframe...</p>;
  }

  return (
    <div>
      {iframeLoaded ? (
        <iframe src={url} width="600" height="400" title="Content" style={{ width: '100%', height: '400px' }} />
      ) : (
        <p>The URL does not allow embedding in an iframe.</p>
      )}
    </div>
  );
};

export default IframeChecker;