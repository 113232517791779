import React, { useEffect, useState } from "react";
import IconRessource from "../../atoms/IconRessource/IconRessource";
import "./ParcoursAppointment.scss";
import PrimaryButton from "../../atoms/PrimaryButton/PrimaryButton";
import moment from "moment";
import 'moment/locale/fr';
import { createVisioRoom } from "../../../api/TwilioApi";
import { getAppointmentById } from "../../../api/AppointmentsApi";
import { getConsultantInfos } from "../../../api/ConsultantApi";

export const ParcoursAppointment = ({ appointmentId }) => {
    const [appointment, setAppointment] = useState()
    const [date, setDate] = useState('');
    const [time, setTime] = useState({ rdv_to: "", rdv_at: "" });
    const [consultant, setConsultant] = useState({ first_name: "", last_name: "" })


    useEffect(() => {
        if (appointmentId) {

            getAppointmentById(appointmentId).then((response) => {
                setAppointment(response.appointment)
                getConsultantInfos(response.appointment.user_from).then((response) => {
                    setConsultant({ first_name: response.consultant.user.first_name, last_name: response.consultant.user.last_name })
                })
            })
        }
        else {
            setAppointment(null)
        }
    }, [appointmentId])

    useEffect(() => {
        if (appointment) {
            const rdvAtMoment = moment(appointment.rdv_at);
            const rdvToMoment = moment(appointment.rdv_to);

            if (!rdvAtMoment.isValid() || !rdvToMoment.isValid()) {
                return
            }

            else {
                const date = rdvAtMoment.format('dddd DD MMMM YYYY');
                const rdv_at = rdvAtMoment.format('HH:mm');
                const rdv_to = rdvToMoment.format('HH:mm');

                setDate(date);
                setTime({ rdv_at: rdv_at, rdv_to: rdv_to });
            }
        }


    }, [appointment]);

    const handleGenerateAndJoinVisioRoom = (e) => {
        if (appointment.videocall_type === 'twilio') {
            createVisioRoom(appointment.id).then((res) => {
                const join_url = process.env.REACT_APP_VISIO_FRONT_URL + appointment.id;
            });
        }
        window.open(appointment.videocall_url, '_blank');
    };


    if (appointment) {

        return (
            <div className="parcours-appointment">

                <h1>{appointment.title}</h1>
                {appointment.description && <p>{appointment.description}</p>}
                <div className="illustration-container">
                    <IconRessource icon={"appointmentEvo"} />
                </div>
                {(date && time)
                    ?
                    <div className="parcours-appointment-content">
                        <p>Un rendez-vous est programmé le <b> {date} </b> de <b>{time.rdv_at}</b> à <b>{time.rdv_to} | Avec {consultant.first_name} {consultant.last_name}</b></p>
                        {appointment.address
                            ? <p>Lieu du rendez-vous : {appointment.address}</p>
                            : <PrimaryButton label={"Rejoindre"} disabled={false} onClick={(e) => handleGenerateAndJoinVisioRoom(e)} />
                        }
                        <p className="parcours-appointment-content-no-validate">Vous ne pouvez pas assister au rendez-vous ? Demandez un report dans l'onglet "Agenda".</p>
                    </div>
                    : <div className="parcours-appointment-content-invalid-date">Ce rendez-vous n'a pas été encore programmé par votre consultant.</div>
                }

            </div>
        )
    }
    else {
        return (
            <div className="parcours-appointment-content-invalid-date">Ce rendez-vous n'a pas été encore programmé par votre consultant.</div>
        )
    }
}