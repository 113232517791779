import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import Icon from '../../atoms/Icon/Icon';
import AvatarField from '../../molecules/AvatarField/AvatarField';
import InputField from '../../molecules/InputField/InputField';
import InputFieldContainer from '../../molecules/InputField/InputFieldContainer';
import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle';
import RadioField from '../../molecules/RadioField/RadioField';
import DateField from '../../molecules/DateField/DateFieldContainer';
import ComboField from '../../molecules/ComboField/ComboField';
import './PresentationCard.scss';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectors as UserSelectors } from '../../../redux/UserRedux';



const PresentationCard = ({
  title,
  photoUrl,
  gender,
  lastName,
  firstName,
  birth,
  group,
  locale,
  readOnly,
  uploading,
  isNew,
  onChange,
  onPictureClick,
  source,
  required = false

}) => {
  const [sourceOther, setSourceOther] = useState(false);
  const [sourceValue, setSourceValue] = useState(null);
  const [age, setAge] = useState(null);
  const user = useSelector(UserSelectors.user);
  const handleChange = ({ id, value }) => {
    onChange({ id, value });
  };

  const handlePictureClick = () => {
    onPictureClick(handleChange);
  };

  const handleSourceChange = ({ value }) => {
    setSourceValue(value);
    setSourceOther(value === 'other');
    handleChange({ id: 'source', value });
  };

  const handleSourceInputChange = ({ value }) => {
    setSourceValue(value);
    handleChange({ id: 'source', value });
  };

  useEffect(() => {
    const sources = locale.sources;
    const s = sources.find((element) => element.value === source.value);
    const valueExistsInSources = sources.some((item) => item.value === source.value);

    if (!valueExistsInSources) {
      setSourceOther(true);
      setSourceValue(source.value);
    } else {
      if (s && s.value === 'other') {
        setSourceOther(true);
        setSourceValue(source.value);
      } else {
        setSourceOther(false);
        setSourceValue(source.value);
      }
    }
  }, [source, locale.sources]);

  useEffect(() => {
    if (birth) {
      const isValidDate = moment(birth.value, 'DD/MM/YYYY').isValid();
      const age = moment().diff(moment(birth.value, 'DD/MM/YYYY'), 'years');
      isValidDate ? setAge(age) : setAge(null);
    } else {
      setAge(null)
      birth.value = null;
    }
  }, [birth]);

  return (
    <div className="presentation-card">
      <IconCardTitle label={title} icon={''} />
      <div className="form">
        <div className="photo">
          <AvatarField
            photoUrl={photoUrl}
            onClick={handlePictureClick}
            readOnly={readOnly}
            uploading={uploading}
          />
        </div>

        <div className="fields">
          <RadioField
            title={locale.title}
            name={gender.id}
            inputList={gender.inputList}
            value={gender.value}
            onChange={handleChange}
            readOnly={readOnly}
            required={required}
          />

          <div className="fields-flex">
            <InputFieldContainer
              title={locale.firstName}
              name={firstName.id}
              placeholder={locale.firstNamePlaceHolder}
              value={firstName.value}
              onChange={handleChange}
              readonly={readOnly}
            required={required}

            />

            <InputFieldContainer
              title={locale.name}
              name={lastName.id}
              placeholder={locale.name}
              value={lastName.value}
              onChange={handleChange}
              readonly={readOnly}
            required={required}

            />
          </div>

          <div className="fields-flex">
            { age !== null && (
              <InputField title={locale.age} name="age" placeholder="age" value={age} readonly />
            )}

            { !isNew && birth && (
              <>
              <DateField
                title={locale.birth}
                placeholder={locale.birthPlaceHolder}
                name={birth.id}
                value={birth.value ?? null}
                calendarLabels={{
                  dayShortLabels: locale.dayShortLabels,
                  monthLabels: locale.monthLabels,
                }}
                onChange={handleChange}
                locale={locale.locale}
                readOnly={readOnly}
                required={false}
              />
              </>
            )}
          </div>

          {!isNew && group && (
            <ComboField
              value={group.value}
              title={locale.groupLabel}
              name={group.id}
              options={group.options}
              onChange={handleChange}
              readOnly={readOnly}
            />
          )}

          {(!isNew && user.role !== "beneficiary") && (
            <ComboField
              title="Source"
              options={locale.sources}
              onChange={handleSourceChange}
              readOnly={readOnly}
              name="Source"
              placeholder="Veuillez choisir"
              value={sourceOther ? 'other' : sourceValue}
            />
          )}
          {(!isNew && user.role !== "beneficiary" && sourceOther)   && (
              <InputField
                title="Autre source"
                name="source"
                placeholder="Entrez la source"
                onChange={handleSourceInputChange}
                value={sourceValue === 'other' ? '' : sourceValue}
              />
              )}
        </div>
      </div>
    </div>
  );
};

PresentationCard.propTypes = {
  title: PropTypes.string.isRequired,
  // ... (other prop types)
};

export default PresentationCard;
