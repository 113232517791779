import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectors as UserSelectors } from '../../../redux/UserRedux'
import { selectors as BeneficiarySelectors } from '../../../redux/BeneficiaryRedux'

import SharedNotes from '../../../components/organisms/SharedNotes/SharedNotes'
import { getBeneficiaryNotes, updateNotes } from '../../../api/BeneficiaryAPI';
import { debounce } from 'lodash';

const ConsultantBeneficiaryAside = ({ newBeneficiary = false }) => {
  const [authenticatedUserNote, setAuthenticatedUserNote] = useState()
  const [otherNotes, setOtherNotes] = useState([])
  const authenticatedUser = useSelector(UserSelectors.user);
  const beneficiary = useSelector(BeneficiarySelectors.beneficiary);
  const [openNotesId, setOpenNotesId] = useState()
  const [currentNoteText, setCurrentNoteText] = useState("")

  useEffect(() => {
    const fetchNotes = async () => {
      if (!newBeneficiary) {
        const notes = await getBeneficiaryNotes(beneficiary.id)

        const otherNotes = []

        notes.map((note) => {
          if (note.consultant_id === authenticatedUser.profile_id) {
            setAuthenticatedUserNote(note)
            setCurrentNoteText(note.notes)
            setOpenNotesId(String(note.id))
          } else {
            otherNotes.push(note)
          }
        })

        setOtherNotes(otherNotes)
      }
    }
    fetchNotes()
  }, []);

  const handleHeaderClick = ({ id }) => {
    if (openNotesId === id) {
      setOpenNotesId("0")
    } else {
      setOpenNotesId(String(id))
    }
  }

  const handleChange = ({ value }) => {
    setCurrentNoteText(value)
    debounceUpdateNotes(beneficiary.profile_id, authenticatedUser.profile_id, value)
  }

  const debounceUpdateNotes = useCallback(
    debounce((beneficiaryId, userId, value) => {
      updateNotes(beneficiaryId, userId, value);
    }, 800),
    []
  );

  return (
    <div className="consultant-beneficiary-aside">
      {authenticatedUserNote && (
        <SharedNotes
          key={authenticatedUserNote.id}
          id={String(authenticatedUserNote.id)}
          label={"Mes notes"}
          isExpanded={openNotesId === String(authenticatedUserNote.id)}
          value={currentNoteText}
          onHeaderClick={handleHeaderClick}
          editable
          onChange={(value) => handleChange(value)}
          placeholder={"Ecrivez vos notes ici..."}
        />
      )}
      {otherNotes && otherNotes.map((otherNote) => (
        <SharedNotes
          key={otherNote.id}
          id={String(otherNote.id)}
          label={
            otherNote.is_primary ? "Mes notes" : "Notes partagées"
          }
          isExpanded={openNotesId === String(otherNote.id)}
          value={otherNote.notes}
          onHeaderClick={handleHeaderClick}
        />
      ))}
    </div>
  )
}

export default ConsultantBeneficiaryAside