import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actions as NavigationActions } from '../../redux/NavigationRedux';
import { actions as UserActions } from '../../redux/UserRedux';
import { selectors as WSSelectors } from '../../redux/WSRedux';
import Logo from '../../static/assets/logo-my-talents-white.svg';
import LoginForm from '../../components/organisms/LoginForm/LoginForm';
// import Config from '../../config/AppConfig';
import backgroundImage from '../../static/images/background-login.webp';
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton';
import './LoginPage.scss';
// import { Toaster } from '../../components/atoms/Toaster/Toaster';
// import { useToaster } from '../../hooks/useToaster';
import { useState } from 'react';
import CheckBoxField from '../../components/molecules/CheckBoxField/CheckBoxField';
import config from '../../config/AppConfig';


const LoginPage = ({ pending, error, evaluate, login, sendInvitation }) => {
  // const { toasters, addToaster, removeToaster } = useToaster();

  const [generalError, setGeneralError] = useState('');

  const handleSubmit = (values) => {
    if (!values.email || !values.password) {
      setGeneralError("Veuillez remplir le/les champ(s).");
      return;
    }
   
    login(values);
  };

  const handleSendInvitation = () => {
    const { details } = error;
    sendInvitation(details.id, details.token.token);
  };

  useEffect(() => {
    evaluate();
  }, [evaluate]);


  useEffect(() => {
    if (error) {
      if (error.message.includes('E_PASSWORD_MISMATCH') || error.message.includes('E_USER_NOT_FOUND')) {
        setGeneralError("L'email ou le mot de passe est incorrect. Veuillez réessayer.");
      } else if (error.message.includes('E_USER_NOT_ACTIVATED')) {
        setGeneralError("Votre compte n'est pas activé. Veuillez activer votre compte.");
      } else {
        setGeneralError('Une erreur est survenue. Veuillez réessayer.');
      }
    } else {
      setGeneralError('');
    }
  }, [error]);

 const renderNotActivated = () => {
    const notActivated = error && error.message === 'E_USER_NOT_ACTIVATED';

    if (!notActivated) {
      return null;
    }

    return (
      <section className="error">
        <span>{"Vous n'avez pas activé votre compte"}</span>
        <PrimaryButton
          label={"Renvoyer l'invitation"}
          onClick={handleSendInvitation}
        />
      </section>
    );
  };

  

  const renderForm = () => (
    <LoginForm
      persist
      onSubmit={handleSubmit}
      disabled={pending}
      locale={{
        email: 'Email',
        emailPlaceholder: 'Insérer votre adresse email...',
        password: 'Mot de passe',
        passwordPlaceholder: 'Insérer votre mot de passe...',
        persist: 'true',
        cta: 'Se connecter',
      }}
    />
  );

  return (
    <div className="login">
      <section className="cover">
        <div className="cover-my-talents-logo">
          <Logo />
        </div>
        <div className="presentation-my-talents">
          <img src={backgroundImage} alt="presentation-my-talents" />
          <div className="presentation-my-talents-title">
            <h2>Bienvenue sur mytalents</h2>
            <p>From MyTalents to Your Talents</p>
          </div>
        </div>
      </section>
      <section className="login-form-container">
        <div className="login-forms">
          <h2>Connectez - vous à MyTalents</h2>
          {generalError && (
          <div className="error-message">
            <span>{generalError}</span>
          </div>
        )}
          {renderForm()}
          {renderNotActivated()}
          
        </div>
      </section>
      {/* {toasters.map((toast) => (
        <Toaster key={toast.id} title={toast.title} message={toast.message} type={toast.type} onClose={() => removeToaster(toast.id)} />
      ))} */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  pending: WSSelectors.pending(state, 'auth.login'),
  error: WSSelectors.error(state, 'auth.login'),
});

const mapDispatchToProps = (dispatch) => ({
  evaluate: () => dispatch(NavigationActions.evaluate()),
  login: (email, password, persist) => dispatch(UserActions.login(email, password, persist)),
  sendInvitation: (id, token) => dispatch(UserActions.sendInvitation(id, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
