import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './LineMenuLink.scss';
import Step from '../../atoms/Step/Step'

// PropTypes
const propTypes = {
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  countIndicator: PropTypes.number,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  sublabel: PropTypes.string,
  withSublabel: PropTypes.bool,
}

// DefaultProps
const defaultProps = {
  countIndicator: 0,
  sublabel: '',
  withSublabel: false,
}

const LineMenuLink = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const onClick = (e) => {
    if (props.isAvailable)
      props.onClick(e, props.id)
  }

  const countLabel = () => {
    const { countIndicator } = props
    if (countIndicator < 10) return countIndicator.toString()
    return '9+'
  }

  const { id, isSelected, countIndicator, label, sublabel, withSublabel, isAvailable } = props

  return (
    <div
      className={classnames('line-menu-link', withSublabel && 'with-sublabel')}
      style={{position: 'relative', overflow: 'visible'
      }}
      id={id}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {(isHovered && !isAvailable) && <p style={{position: 'absolute', bottom: 0, whiteSpace: 'nowrap', backgroundColor: 'var(--text-primary)', borderRadius: '5px', padding: '1px 5px', color: 'white', fontSize: '14px'}}>Contactez-nous pour connaître nos offres</p>}
      
      <div className={`label-container ${isSelected ? 'selected' : 'default'}`}>
        <div className={`label ${isSelected ? 'selected' : (isAvailable ? 'default' : 'none')}`}>
          {label}
          {countIndicator > 0 && <Step label={countLabel()} />}
        </div>
        {withSublabel && <p className="sublabel">{sublabel}</p>}
      </div>
      {isSelected && <div className="selector" />}
       {/* Example hover indicator */}
    </div>
  )
}

LineMenuLink.propTypes = propTypes
LineMenuLink.defaultProps = defaultProps

export default LineMenuLink
