import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import IconFormQuestionType from '../../../components/atoms/IconFormQuestionType/IconFormQuestionType'
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton'
import DropStepZone from '../../../components/molecules/DropStepZone/DropStepZone'
import FormMultipleChoiceConfigurationRow from '../../../components/molecules/FormMultipleChoiceConfigurationRow/FormMultipleChoiceConfigurationRow'
import InputField from '../../../components/molecules/InputField/InputField'
import QuizCombo from '../../../components/molecules/QuizCombo/QuizCombo'
import QuizDate from '../../../components/molecules/QuizDate/QuizDate'
import QuizGroup from '../../../components/molecules/QuizGroup/QuizGroup'
import QuizOpinionScale from '../../../components/molecules/QuizOpinionScale/QuizOpinionScale'
import QuizQcm from '../../../components/molecules/QuizQcm/QuizQcm'
import QuizQcmRow from '../../../components/molecules/QuizQcmRow/QuizQcmRow'
import QuizStars from '../../../components/molecules/QuizStars/QuizStars'
import QuizTextArea from '../../../components/molecules/QuizTextArea/QuizTextArea'
import QuizYesNo from '../../../components/molecules/QuizYesNo/QuizYesNo'
import TextAreaField from '../../../components/molecules/TextAreaField/TextAreaField'
import ToggleButton from '../../../components/molecules/ToggleButton/ToggleButton'
import LeftAsideLayout from '../../../layouts/LeftAsideLayout/LeftAsideLayout'
import Draggable from '../../dragdrop/Draggable/Draggable'
import Droppable from '../../dragdrop/Droppable/Droppable'
import { Popin } from '../../global/Popin/Popin'
import './EditQuestionFormModulePopin.scss'

class EditQuestionFormModulePopin extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    question: PropTypes.object,
    questionKey: PropTypes.string,
  }

  static defaultProps = {
    open: false,
    questionKey: null,
    question: null
  }

  handleSaveQuestion = () => {
    const { onClose, question, setNewQuestion } = this.props;
    setNewQuestion(question)
    onClose()
  }

  handleTitleChange = ({ value }) => {
    const { question, setQuestion } = this.props
    const newQuestion = {
      ...question,
      title: value,
    }

    setQuestion(newQuestion)
  }

  handleImageChange = ({ id, value }) => {
    const { question, setQuestion } = this.props
    if (value === false) {
      const newQuestion = {
        ...question,
        image: null
      }
      setQuestion(newQuestion)

    }
    else {
      const newQuestion = {
        ...question,
        image: ""
      }
      setQuestion(newQuestion)
    }

  }

  handleConfigurationChange = (update) => {
    const { question, setQuestion } = this.props

    if (Array.isArray(update)) {
      const newOptions = update.reduce(
        (res, newOpt) => ({
          ...res,
          [newOpt.id]: newOpt.value,
        }),
        {},
      )

      const newQuestion = {
        ...question,
        options: {
          ...question.options,
          ...newOptions,
        },
      }

      setQuestion(newQuestion)
    } else {
      const { id, value } = update

      const newQuestion = {
        ...question,
        options: {
          ...question.options,
          [id]: value,
        },
      }

      setQuestion(newQuestion)
    }
  }

  handleAnswer = (answer) => {
    this.setState({
      results: answer.value,
    })
  }

  handleDropFile = (file) => {
    const {question, setQuestion, setNewQuestion} = this.props
    const image = file.file.name;
    const reader = new FileReader();
    reader.onload = () => {
      const newQuestion = {...question, upload_name: image, upload: reader.result}

      setQuestion(newQuestion)
      setNewQuestion(newQuestion);
    }
    reader.readAsDataURL(file.file);
  }

  handleClickFile = (event) => {
  }

  constructor(props) {
    super(props)

    this.state = {
      question: null,
      results: null,
    }
  }

  render() {
    const { onClose, open, question, questionKey } = this.props

    if (!question) {
      return null
    }

    let questionTypeContainerNode = null
    let questionTypeSpecificNode = null
    let questionConfigurationNode = null
    let questionOverviewNode = null
    switch (question.type) {
      case 'text': {
        questionTypeContainerNode = (
          <div className="question-type-container">
            <IconFormQuestionType questionType={IconFormQuestionType.questionType.Text} />
            <div className="title">{"Texte"}</div>
          </div>
        )

        const handleEnableMaxLength = ({ id, value }) => {
          if (value === false) {
            this.handleConfigurationChange([
              { id, value },
              { id: 'max_length', value: 0 },
            ])
          } else {
            this.handleConfigurationChange({ id, value })
          }
        }

        const handleMaxLengthConfiguration = ({ id, value }) => {
          if (value === '') {
            this.handleConfigurationChange({ id, value: 0 })
          } else {
            this.handleConfigurationChange({ id, value: parseInt(value, 10) })
          }
        }

        questionConfigurationNode = (
          <div className="question-prop-list">
            <div className="configuration-prop-container">
              <div className="configuration-prop-description">{"Obligatoire"}</div>
              <div className="configuration-prop-action">
                <ToggleButton
                  id="required"
                  onChange={this.handleConfigurationChange}
                  labelOff={""}
                  labelOn={""}
                  isOn={question.options.required != null && question.options.required}
                />
              </div>
            </div>
            <div className="configuration-prop-container-with-details">
              <div className="configuration-prop-container">
                <div className="configuration-prop-description">
                  {"Nombre maximum de caractères"}
                </div>
                <div className="configuration-prop-action">
                  <ToggleButton
                    id="max_length_enabled"
                    onChange={handleEnableMaxLength}
                    labelOff={""}
                    labelOn={""}
                    isOn={
                      (question.options.max_length_enabled != null &&
                        question.options.max_length_enabled) ||
                      (question.options.max_length != null &&
                        question.options.max_length !== '' &&
                        parseInt(question.options.max_length, 10) > 0)
                    }
                  />
                </div>
              </div>
              {((question.options.max_length_enabled != null &&
                question.options.max_length_enabled) ||
                (question.options.max_length != null &&
                  question.options.max_length !== '' &&
                  parseInt(question.options.max_length, 10) > 0)) && (
                  <div className="configuration-prop-details">
                    <InputField
                      name="max_length"
                      onChange={handleMaxLengthConfiguration}
                      title={"Nombre"}
                      value={
                        question.options.max_length != null && question.options.max_length !== ''
                          ? `${parseInt(question.options.max_length, 10)}`
                          : '0'
                      }
                      type="number"
                      min={0}
                    />
                  </div>
                )}
            </div>
          </div>
        )

        questionOverviewNode = (
          <QuizTextArea
            label={`${question.title}${question.options.required != null && question.options.required ? ' *' : ''
              }`}
            name={question.type}
            value={this.state.results ? this.state.results : ''}
            onChange={this.handleAnswer}
            placeholder={"Entrez votre réponse ici..."}
            maxLength={
              question.options.max_length != null && question.options.max_length
                ? parseInt(question.options.max_length, 10)
                : 0
            }
          />
        )
        break
      }
      case 'select': {
        questionTypeContainerNode = (
          <div className="question-type-container">
            <IconFormQuestionType questionType={IconFormQuestionType.questionType.Select} />
            <div className="title">{"Liste déroulante"}</div>
          </div>
        )

        questionConfigurationNode = (
          <div className="question-prop-list">
            <div className="configuration-prop-container">
              <div className="configuration-prop-description">{"Question obligatoire"}</div>
              <div className="configuration-prop-action">
                <ToggleButton
                  id="required"
                  onChange={this.handleConfigurationChange}
                  labelOff={""}
                  labelOn={""}
                  isOn={question.options.required != null && question.options.required}
                />
              </div>
            </div>
          </div>
        )

        // const handleChoicesChange = ({ value }) => {
        //   const newChoices = value.split('\n')

        //   this.handleConfigurationChange({
        //     id: 'choices',
        //     value: newChoices,
        //   })
        // }

        // const choicesValue = question.options.choices.join('\n')

        

        // questionTypeSpecificNode = (
        //   <div className="question-specific">
        //     <TextAreaField
        //       name="question-choices"
        //       onChange={handleChoicesChange}
        //       value={choicesValue}
        //       title={"Choix"}
        //       placeholder={"Ajouter un choix par ligne"}
        //     />
        //   </div>
        // )

        const handleChoiceChange = ({id, value}) => {
          const newChoices = question.options.choices.map((choice, choiceKey) => {
            if (choiceKey.toString() === id) return value
            return choice
          })

          this.handleConfigurationChange({
            id: 'choices',
            value: newChoices,
          })
        }
        const handleAdd = ({id}) => {
          const newChoices = question.options.choices.reduce((res, choice, choiceKey) => {
            res.push(choice)
            if (choiceKey.toString() === id) {
              res.push('')
            }
            return res
          }, [])

          this.handleConfigurationChange({
            id: 'choices',
            value: newChoices,
          })
        }
        const handleRemove = ({id}) => {
          let newChoices = question.options.choices.reduce((res, choice, choiceKey) => {
            if (choiceKey.toString() === id) {
              return res
            }
            res.push(choice)
            return res
          }, [])

          if (newChoices.length === 0) {
            newChoices = ['']
          }

          this.handleConfigurationChange({
            id: 'choices',
            value: newChoices,
          })
        }

        questionTypeSpecificNode = (
          <div className="question-specific question-specific-multiple-choice">
            <div className="question-specific-multiple-choice-title">
              {"Choix"}
            </div>
            <Droppable
              id={`${questionKey}_choices`}
              className="question-specific-multiple-choice-list"
              type="multiple-choice-list"
            >
              {question.options.choices.map((choice, choiceKey) => (
                <Draggable id={choiceKey.toString()} key={choiceKey} index={choiceKey}>
                  <FormMultipleChoiceConfigurationRow
                    key={choiceKey}
                    id={choiceKey.toString()}
                    alphaKey={choiceKey}
                    value={choice}
                    onChange={handleChoiceChange}
                    onAdd={handleAdd}
                    onRemove={handleRemove}
                  />
                </Draggable>
              ))}
            </Droppable>
          </div>
        )

        const comboFieldProps = {
          name: questionKey,
          options: question.options.choices.map((opt) => ({ value: opt, label: opt })),
          placeholder: "Sélectionner une réponse",
          onChange: this.handleAnswer,
          value: this.state.results
            ? this.state.results
            : question.options.required != null && question.options.required
              ? question.options.choices[0]
              : '',
          clearable: question.options.required == null || !question.options.required,
        }
        questionOverviewNode = (
          <QuizCombo
            label={`${question.title}${question.options.required != null && question.options.required ? ' *' : ''
              }`}
            comboFieldProps={comboFieldProps}
          />
        )
        break
      }
      case 'multiple_choice': {
        questionTypeContainerNode = (
          <div className="question-type-container">
            <IconFormQuestionType questionType={IconFormQuestionType.questionType.MultipleChoice} />
            <div className="title">{"Choix multiples"}</div>
          </div>
        )

        questionConfigurationNode = (
          <div className="question-prop-list">
            <div className="configuration-prop-container">
              <div className="configuration-prop-description">{"Question obligatoire"}</div>
              <div className="configuration-prop-action">
                <ToggleButton
                  id="required"
                  onChange={this.handleConfigurationChange}
                  labelOff={""}
                  labelOn={""}
                  isOn={question.options.required != null && question.options.required}
                />
              </div>
            </div>
            <div className="configuration-prop-container">
              <div className="configuration-prop-description">{"Ajouter l'option 'autre'"}</div>
              <div className="configuration-prop-action">
                <ToggleButton
                  id="allow_other"
                  onChange={this.handleConfigurationChange}
                  labelOff={""}
                  labelOn={""}
                  isOn={question.options.allow_other != null && question.options.allow_other}
                />
              </div>
            </div>
          </div>
        )


        const handleChoiceChange = ({ id, value }) => {
          const newChoices = question.options.choices.map((choice, choiceKey) => {
            if (choiceKey.toString() === id) return value
            return choice
          })

          this.handleConfigurationChange({
            id: 'choices',
            value: newChoices,
          })
        }

        const handleAdd = ({ id, value }) => {
          const newChoices = question.options.choices.reduce((res, choice, choiceKey) => {
            res.push(choice)
            if (choiceKey.toString() === id) {
              res.push('')
            }
            return res
          }, [])

          this.handleConfigurationChange({
            id: 'choices',
            value: newChoices,
          })
        }

        const handleRemove = ({ id, value }) => {
          let newChoices = question.options.choices.reduce((res, choice, choiceKey) => {
            if (choiceKey.toString() === id) {
              return res
            }
            res.push(choice)
            return res
          }, [])

          if (newChoices.length === 0) {
            newChoices = ['']
          }

          this.handleConfigurationChange({
            id: 'choices',
            value: newChoices,
          })
        }

        questionTypeSpecificNode = (
          <div className="question-specific question-specific-multiple-choice">
            <div className="question-specific-multiple-choice-title">
              {"Choix"}
            </div>
            <Droppable
              id={`${questionKey}_choices`}
              className="question-specific-multiple-choice-list"
              type="multiple-choice-list"
            >
              {question.options.choices.map((choice, choiceKey) => (
                <Draggable id={choiceKey.toString()} key={choiceKey} index={choiceKey}>
                  <FormMultipleChoiceConfigurationRow
                    key={choiceKey}
                    id={choiceKey.toString()}
                    alphaKey={choiceKey}
                    value={choice}
                    onChange={handleChoiceChange}
                    onAdd={handleAdd}
                    onRemove={handleRemove}
                  />
                </Draggable>
              ))}
            </Droppable>
          </div>
        )

        const choices = question.options.choices.map((choice) => ({
          label: choice,
          isSelected:
            this.state.results && this.state.results[choice] ? this.state.results[choice] : false,
        }))

        if (question.options.allow_other != null && question.options.allow_other) {
          choices.push({
            label: "Autre",
            isSelected:
              this.state.results && this.state.results["Autre"]
                ? this.state.results["Autre"]
                : false,
          })
        }

        const handleMultipleChoiceChange = (value) => {
          const newState = choices.reduce(
            (res, choice) => ({
              ...res,
              [choice.label]: value.id === choice.label ? value.value : choice.isSelected,
            }),
            {},
          )

          this.handleAnswer({ id: questionKey, value: newState })
        }

        questionOverviewNode = (
          <QuizQcm
            label={`${question.title}${question.options.required != null && question.options.required ? ' *' : ''
              }`}
            options={choices}
            onChange={handleMultipleChoiceChange}
          />
        )

        break
      }
      case 'group': {
        questionTypeContainerNode = (
          <div className="question-type-container">
            <IconFormQuestionType questionType={IconFormQuestionType.questionType.Group} />
            <div className="title">{"Groupe de questions"}</div>
          </div>
        )

        questionOverviewNode = (
          <QuizGroup label={question.title}>
            <QuizQcmRow
              id="group_1"
              stepLabel="1"
              label={"Sous-question 1 à paraître ici"}
              stepOutline
              isSelected={false}
              onClick={() => null}
            />
            <QuizQcmRow
              id="group_2"
              stepLabel="2"
              label={"Sous-question 2 à paraître ici"}
              stepOutline
              isSelected={false}
              onClick={() => null}
            />
            <div className="other-questions">...</div>
          </QuizGroup>
        )
        break
      }
      case 'yes_no': {
        questionTypeContainerNode = (
          <div className="question-type-container">
            <IconFormQuestionType questionType={IconFormQuestionType.questionType.YesNo} />
            <div className="title">{"Oui / Non"}</div>
          </div>
        )

        questionConfigurationNode = (
          <div className="question-prop-list">
            <div className="configuration-prop-container">
              <div className="configuration-prop-description">{"Question obligatoire"}</div>
              <div className="configuration-prop-action">
                <ToggleButton
                  id="required"
                  onChange={this.handleConfigurationChange}
                  labelOff={""}
                  labelOn={""}
                  isOn={question.options.required != null && question.options.required}
                />
              </div>
            </div>
          </div>
        )

        questionOverviewNode = (
          <QuizYesNo
            label={`${question.title}${question.options.required != null && question.options.required ? ' *' : ''
              }`}
            id={questionKey}
            value={this.state.results ? this.state.results : ''}
            yesLabel={"Oui"}
            noLabel={"Non"}
            onChange={this.handleAnswer}
          />
        )
        break
      }
      case 'rating': {
        questionTypeContainerNode = (
          <div className="question-type-container">
            <IconFormQuestionType questionType={IconFormQuestionType.questionType.Rating} />
            <div className="title">{"Evaluation"}</div>
          </div>
        )

        questionConfigurationNode = (
          <div className="question-prop-list">
            <div className="configuration-prop-container">
              <div className="configuration-prop-description">{"Question obligatoire"}</div>
              <div className="configuration-prop-action">
                <ToggleButton
                  id="required"
                  onChange={this.handleConfigurationChange}
                  labelOff={""}
                  labelOn={""}
                  isOn={question.options.required != null && question.options.required}
                />
              </div>
            </div>
          </div>
        )

        questionOverviewNode = (
          <QuizStars
            id={questionKey}
            label={`${question.title}${question.options.required != null && question.options.required ? ' *' : ''
              }`}
            starsCount={5}
            selectedStars={this.state.results ? this.state.results : 0}
            onChange={this.handleAnswer}
          />
        )
        break
      }
      case 'opinion_scale': {
        questionTypeContainerNode = (
          <div className="question-type-container">
            <IconFormQuestionType questionType={IconFormQuestionType.questionType.OpinionScale} />
            <div className="title">{"Echelle de mesure"}</div>
          </div>
        )

        questionConfigurationNode = (
          <div className="question-prop-list">
            <div className="configuration-prop-container">
              <div className="configuration-prop-description">{"Question obligatoire"}</div>
              <div className="configuration-prop-action">
                <ToggleButton
                  id="required"
                  onChange={this.handleConfigurationChange}
                  labelOff={""}
                  labelOn={""}
                  isOn={question.options.required != null && question.options.required}
                />
              </div>
            </div>
          </div>
        )

        questionOverviewNode = (
          <QuizOpinionScale
            id={questionKey}
            label={`${question.title}${question.options.required != null && question.options.required ? ' *' : ''
              }`}
            scale={question.options.scale ? question.options.scale : 10}
            value={this.state.results ? this.state.results : -1}
            onChange={this.handleAnswer}
          />
        )
        break
      }
      case 'date': {
        questionTypeContainerNode = (
          <div className="question-type-container">
            <IconFormQuestionType questionType={IconFormQuestionType.questionType.Date} />
            <div className="title">{"Date"}</div>
          </div>
        )

        questionConfigurationNode = (
          <div className="question-prop-list">
            <div className="configuration-prop-container">
              <div className="configuration-prop-description">{"Question obligatoire"}</div>
              <div className="configuration-prop-action">
                <ToggleButton
                  id="required"
                  onChange={this.handleConfigurationChange}
                  labelOff={""}
                  labelOn={""}
                  isOn={question.options.required != null && question.options.required}
                />
              </div>
            </div>
          </div>
        )

        questionOverviewNode = (
          <QuizDate
            id={questionKey}
            label={`${question.title}${question.options.required != null && question.options.required ? ' *' : ''
              }`}
            monthLabels={["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]}
            dayTitle={"Jour"}
            monthTitle={"Mois"}
            yearTitle={"Année"}
            value={this.state.results ? this.state.results : ''}
            onChange={this.handleAnswer}
          />
        )
        break
      }
      default: {
        throw new Error(`Incompatible question type: "${question.type}"`)
      }
    }

    if (!questionTypeContainerNode || !questionOverviewNode) {
      throw new Error(`Incompatible question type: "${question.type}"`)
    }

    const asideNode = (
      <div className="edit-question-aside">
        {questionTypeContainerNode}
        <div className="question-title">
          <TextAreaField
            name="question-title"
            onChange={this.handleTitleChange}
            value={question.title}
            maxLength={255}
            title={"Question (255 caractères max)"}
            focus={Boolean(!question.title)}
          />
        </div>
        {questionTypeSpecificNode}
        <div className="question-image">
          <div className='question-image-title'>

            <ToggleButton
              id="image"
              onChange={this.handleImageChange}
              labelOff={""}
              labelOn={""}
              isOn={question.image !== null}
            />
            <p>Image</p>
          </div>


          {question.image !== null &&
            <div className='drag-n-drop'>
              <DropStepZone onDrop={this.handleDropFile} onClick={this.handleDropFile} label={"Glisser ou déposez votre image ici"} />
            </div>

          }
        </div>
        <div className="question-configuration">
          {questionConfigurationNode !== null && (
            <div className="question-configuration-title">
              {"Configuration"}
              {questionConfigurationNode}
            </div>
          )}
        </div>
        <div className="actions">
          <PrimaryButton label={"Annuler"} id="cancel-button" onClick={onClose} outline />

          <PrimaryButton
            label={"Sauvegarder"}
            id="save-button"
            onClick={this.handleSaveQuestion}
          />
        </div>
      </div>
    )

    const contentNode = (
      <div className="question-overview">
        <header />
        {question.image &&
          <img src={question.image} alt={"question_image"}/>
        }
        <div className="overview">{questionOverviewNode}</div>
      </div>
    )

    return (
      <Popin onClose={onClose} open={open} overrideModalStyles={{ maxWidth: '90vw' }}>
        <div className="edit-question-form-module-popin">
          <LeftAsideLayout aside={asideNode} content={contentNode}  showNavBar={false}/>
        </div>
      </Popin>
    )
  }
}

export default EditQuestionFormModulePopin;