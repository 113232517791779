import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle';
import ComboField from '../../molecules/ComboField/ComboField';
import DateField from '../../molecules/DateField/DateFieldContainer';
import InputField from '../../molecules/InputField/InputField';

import moment from 'moment';
import 'moment/locale/fr';

import './AccompEditionCard.scss';
import RadioField from '../../molecules/RadioField/RadioField';

export default class AccompEditionCard extends Component {
  static propTypes = {
    accompanimentType: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    accompanimentCollectiveType: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.object),
    }),
    duration: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.number,
    }),
    durationType: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.object),
    }),
    startDate: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    endDate: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    dateSixMonths: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    accompanimentSurveyCount: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.number,
    }),
    bilanType: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    bilanAvailability: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    bilanFranceTravailId: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    group: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.object),
    }),
    homeOffice: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    synchronousTime: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    asynchronousTime: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    globalTime: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    terms: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.object),
    }),
    vaeAvailability: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    vaeEligibilityDate: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    bookletDate: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    otherAccompaniment: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    accompanimentCollectiveTypeLabel: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    accompanimentSurveyType: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    HandicapAmendmentRequirement: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.bool,
    }),
    firstAppointmentDate: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
    situationFollowUp: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.object),
    }),
    formation: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.object),
    }),

    title: PropTypes.string,

    locale: PropTypes.shape({
      accompanimentTypeLabel: PropTypes.string,
      durationLabel: PropTypes.string,
      startDateLabel: PropTypes.string,
      endDateLabel: PropTypes.string,
      bilanTypeLabel: PropTypes.string,
      bilanAvailabilityLabel: PropTypes.string,
      accompanimentSurveyCountLabel: PropTypes.string,
      bilanFranceTravailIdLabel: PropTypes.string,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    duration: null,
    startDate: null,
    endDate: null,
    bilanType: null,
    bilanAvailability: null,
    readOnly: false,
    accompanimentSurveyCount: null,
  };

  handleChange = (newValue) => {
    if (!this.props.readOnly) {
      this.props.onChange(newValue);
    }
  };

  render() {
    const {
      accompanimentType,
      duration,
      startDate,
      endDate,
      bilanType,
      bilanAvailability,
      readOnly,
      accompanimentSurveyCount,
      title = '',
      homeOffice,
      synchronousTime,
      asynchronousTime,
      globalTime,
      terms,
      vaeAvailability,
      vaeEligibilityDate,
      bookletDate,
      otherAccompaniment,
      accompanimentCollectiveType,
      accompanimentSurveyType,
      HandicapAmendmentRequirement,
      dateSixMonths,
      firstAppointmentDate,
      situationFollowUp,
      formation,
      bilanFranceTravailId,
      formationTitle,
    } = this.props;

    const {
      accompanimentTypeLabel = '',
      startDateLabel = '',
      endDateLabel = '',
      bilanTypeLabel = '',
      bilanAvailabilityLabel = '',
      synchronousTimeLabel,
      asynchronousTimeLabel,
      termsLabel,
      dayShortLabels,
      monthLabels,
      locale,
      accompanimentSurveyCountLabel,
      homeOfficeLabel,
      vaeAvailabilityLabel,
      vaeEligibilityDateLabel,
      vaeBookletDateLabel,
      otherAccompanimentLabel,
      accompanimentCollectiveTypeLabel,
      accompanimentSurveyTypeLabel,
      HandicapAmendmentRequirementLabel,
      dateSixMonthsLabel,
      firstAppointmentDateLabel,
      situationFollowUpLabel,
      formationLabel,
      bilanFranceTravailIdLabel,
      globalTimeLabel,
      formationTitleLabel,
    } = this.props.locale;

    const surveryTargets = [
      'outplacement_individual',
      'outplacement_group',
      'appraisal',
      'orientation_review',
    ];

    const targets = ['appraisal', 'vae'];

    const all_collective_accompaniment = [
      'collective_accompaniment',
      'outplacement_individual',
      'collective_scheme',
    ];

    const showSurveryField = surveryTargets.includes(accompanimentType.value);
    const showTypeField = all_collective_accompaniment.includes(accompanimentType.value);

    const showTargetField = targets.includes(accompanimentType.value);
    const isAppraisal = accompanimentType.value === 'appraisal';
    const isAccompanimentCollective = accompanimentType.value === 'collective_accompaniment';
    const isVae = accompanimentType.value === 'vae';
    const isOther = accompanimentType.value === 'other';

    // eslint-disable-next-line no-unused-vars
    let durationAccompaniment = '';

    if (startDate && endDate && startDate.value && endDate.value) {
      const startDateISO = moment(startDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
      const endDateISO = moment(endDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD');

      const start = moment(startDateISO);
      const end = moment(endDateISO);

      const differenceInDays = end.diff(start, 'days');

      if (differenceInDays < 30) {
        durationAccompaniment = `${differenceInDays} jours`;
      } else {
        const differenceInMonths = end.diff(start, 'months');
        const remainingDays = differenceInDays % 30;

        let resultString = `${differenceInMonths} mois`;
        if (remainingDays > 0) {
          resultString += ` et ${remainingDays} jours`;
        }

        durationAccompaniment = resultString;
      }
    }
    return (
      <div className="accomp-edition-card">
        <IconCardTitle icon="clipboard" color="accent" label={title} />
        <div className="accomp-edition-card-content">
          <ComboField
            key={accompanimentType.id}
            value={accompanimentType.value}
            title={accompanimentTypeLabel}
            name={accompanimentType.id}
            options={accompanimentType.options}
            onChange={this.handleChange}
            readOnly={readOnly}
          />
          {formation && (
            <>
              <ComboField
                title={formationLabel}
                key={formation.id}
                value={formation.value}
                name={formation.id}
                options={formation.options}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
              <InputField
              title={formationTitleLabel}
              name={formationTitle.id}
              value={formationTitle.value}
              onChange={this.handleChange}
              type="string"
              readonly={readOnly}
            />
            </>
          )}

          {isOther && (
            <InputField
              title={otherAccompanimentLabel}
              name={otherAccompaniment.id}
              value={otherAccompaniment.value}
              onChange={this.handleChange}
              type="string"
              readonly={readOnly}
            />
          )}

          {isAccompanimentCollective && (
            <ComboField
              key={accompanimentCollectiveType.id}
              value={accompanimentCollectiveType.value}
              title={accompanimentCollectiveTypeLabel}
              name={accompanimentCollectiveType.id}
              options={accompanimentCollectiveType.options}
              onChange={this.handleChange}
              readOnly={readOnly}
            />
          )}

          {isAppraisal && (
            <div className="bilan-accompaniment flex-space-between-container">
              {bilanType !== null && (
                <ComboField
                  key={bilanType.id}
                  value={bilanType.value}
                  title={bilanTypeLabel}
                  name={bilanType.id}
                  options={bilanType.options}
                  onChange={this.handleChange}
                  readOnly={readOnly}
                />
              )}
              {bilanType.value === 'pole_emploi' && (
                
                <InputField
                      title={bilanFranceTravailIdLabel}
                      name={bilanFranceTravailId?.id}
                      value={bilanFranceTravailId?.value}
                      onChange={this.handleChange}
                      type="text"
                      readonly={readOnly}
                    />
              )}
              {bilanAvailability !== null && (
                <ComboField
                  key={bilanAvailability.id}
                  value={bilanAvailability.value}
                  title={bilanAvailabilityLabel}
                  name={bilanAvailability.id}
                  options={bilanAvailability.options}
                  onChange={this.handleChange}
                  readOnly={readOnly}
                />
              )}
            </div>
          )}

          {isVae && (
            <ComboField
              key={vaeAvailability.id}
              value={vaeAvailability.value}
              title={vaeAvailabilityLabel}
              name={vaeAvailability.id}
              options={vaeAvailability.options}
              onChange={this.handleChange}
              readOnly={readOnly}
            />
          )}

          {/* {showTargetField &&
            <ComboField
              key={group.id}
              value={group.value}
              title={groupLabel}
              name={groupLabel.id}
              options={group.options}
              onChange={this.handleChange}
              readOnly={readOnly}
            />
          } */}

          {(duration !== null || startDate !== null || endDate !== null) && !showTypeField && (
            <>
              <div className="flex-space-between-container">
                {firstAppointmentDate && (
                  <DateField
                    title={firstAppointmentDateLabel}
                    name={firstAppointmentDate.id}
                    value={firstAppointmentDate.value}
                    calendarLabels={{ dayShortLabels, monthLabels }}
                    onChange={this.handleChange}
                    locale={locale}
                    readOnly={readOnly}
                  />
                )}
              </div>
              <div className="accompaniment-duration">
                {startDate !== null && (
                  <DateField
                    title={startDateLabel}
                    name={startDate.id}
                    value={startDate.value}
                    calendarLabels={{ dayShortLabels, monthLabels }}
                    onChange={this.handleChange}
                    locale={locale}
                    readOnly={readOnly}
                    disableAfterDate={endDate.value}
                  />
                )}
                {endDate !== null && (
                  <DateField
                    title={endDateLabel}
                    name={endDate.id}
                    value={endDate.value}
                    calendarLabels={{ dayShortLabels, monthLabels }}
                    onChange={this.handleChange}
                    locale={locale}
                    readOnly={readOnly}
                    disablePreviousDate={startDate.value}
                  />
                )}
                {isAppraisal && dateSixMonths && (
                  <DateField
                    title={dateSixMonthsLabel}
                    name={dateSixMonths.id}
                    value={dateSixMonths.value}
                    calendarLabels={{ dayShortLabels, monthLabels }}
                    onChange={this.handleChange}
                    locale={locale}
                    readOnly={readOnly}
                    // disablePreviousDate={endDate.value}
                  />
                )}

                {/* DUREE DE L'ACCOMPAGNEMENT */}
                {/* {startDate !== null && endDate !== null &&
                  <InputField
                  title={"Durée de l'accompagnement"}
                  name={"accompaniment_total_duration"}
                  value={durationAccompaniment}
                  onChange={this.handleChange}
                  type="string"
                  readonly={true}
                />
                } */}
              </div>

              {isVae && (
                <div className="flex-space-between-container">
                  <DateField
                    title={vaeEligibilityDateLabel}
                    name={vaeEligibilityDate.id}
                    value={vaeEligibilityDate.value}
                    calendarLabels={{ dayShortLabels, monthLabels }}
                    onChange={this.handleChange}
                    locale={locale}
                    readOnly={readOnly}
                  />
                  <DateField
                    title={vaeBookletDateLabel}
                    name={bookletDate.id}
                    value={bookletDate.value}
                    calendarLabels={{ dayShortLabels, monthLabels }}
                    onChange={this.handleChange}
                    locale={locale}
                    readOnly={readOnly}
                  />
                </div>
              )}
              <div className="flex-space-between-container">
                {synchronousTime && (
                  <div className="flex-container ">
                    <InputField
                      title={synchronousTimeLabel}
                      name={synchronousTime.id}
                      value={synchronousTime.value}
                      onChange={this.handleChange}
                      type="number"
                      readonly={readOnly}
                      min={0}
                    />
                    <p>heure(s)</p>
                  </div>
                )}

                {asynchronousTime && (
                  <div className="flex-container ">
                    <InputField
                      title={asynchronousTimeLabel}
                      name={asynchronousTime.id}
                      value={asynchronousTime.value}
                      onChange={this.handleChange}
                      type="number"
                      readonly={readOnly}
                      min={0}
                    />
                    <p>heure(s)</p>
                  </div>
                )}
                {globalTime && (
                  <div className="flex-container ">
                    <InputField
                      title={globalTimeLabel}
                      name={globalTime.id}
                      value={globalTime.value}
                      onChange={this.handleChange}
                      type="number"
                      readonly={readOnly}
                      min={0}
                    />
                    <p>heure(s)</p>
                  </div>
                )}
              </div>

              <div className="flex-space-between-container">
                {showTargetField && (
                  <InputField
                    title={homeOfficeLabel}
                    name={homeOffice.id}
                    value={homeOffice.value}
                    onChange={this.handleChange}
                    type="text"
                    readonly={readOnly}
                  />
                )}

                {/* {showSurveryField && (
                  <InputField
                    title={accompanimentSurveyCountLabel}
                    name={accompanimentSurveyCount.id}
                    value={accompanimentSurveyCount.value}
                    onChange={this.handleChange}
                    type="number"
                    readonly={readOnly}
                    min={0}
                  />
                )} */}
              </div>

              <div className="flex-space-between-container">
                {isAppraisal && accompanimentSurveyType && showSurveryField && (
                  <InputField
                    title={accompanimentSurveyTypeLabel}
                    name={accompanimentSurveyType.id}
                    value={accompanimentSurveyType.value} // accompanimentSurveyType.value}
                    onChange={this.handleChange}
                    type="string"
                    readonly={readOnly}
                  />
                )}

                {showSurveryField && (
                  <InputField
                    title={accompanimentSurveyCountLabel}
                    name={accompanimentSurveyCount.id}
                    value={accompanimentSurveyCount.value}
                    onChange={this.handleChange}
                    type="number"
                    readonly={readOnly}
                    min={0}
                  />
                )}
              </div>

              {terms && (
                <>
                  <ComboField
                    title={termsLabel}
                    key={terms.id}
                    value={terms.value}
                    name={terms.id}
                    options={terms.options}
                    onChange={this.handleChange}
                    readOnly={readOnly}
                  />
                </>
              )}
              {HandicapAmendmentRequirement && (
                <RadioField
                  title={HandicapAmendmentRequirementLabel}
                  value={
                    HandicapAmendmentRequirement.value ? HandicapAmendmentRequirement.value : (HandicapAmendmentRequirement.value === 0 ? 0 : -1)
                  }
                  name={HandicapAmendmentRequirement ? HandicapAmendmentRequirement.id : 'no'} //HandicapAmendmentRequirement.id}
                  inputList={[
                    { id: 'yes', value: 1, label: 'Oui' },
                    { id: 'no', value: 0, label: 'Non' },
                  ]}
                  onChange={this.handleChange}
                />
              )}
              {situationFollowUp && (
                <ComboField
                  key={situationFollowUp.id}
                  value={situationFollowUp.value}
                  title={situationFollowUpLabel}
                  name={situationFollowUp.id}
                  options={situationFollowUp.options}
                  onChange={this.handleChange}
                  readOnly={readOnly}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
