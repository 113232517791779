import React from "react";
import { Modal } from "../../../../components/atoms/Modal/Modal"

export const EmptyTitleFormModal = ({open, onClose }) => {

    const renderContent = () => {
        return (
           <p>Attention, le formulaire n'a pas de titre. Veuillez insérer un titre pour continuer.</p>
        )
    }

    return (
       <Modal
       onClosePopin={onClose}
       openPopin={open}
       title={"Formulaire sans titre"}
       content={renderContent()}
       cancelButton={"Retour"}
       actionButton={"Insérer un titre"}
       handleActionButton={onClose}
       />
    )
}