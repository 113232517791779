import React from "react";
import { Modal } from "../../../components/atoms/Modal/Modal";

export const EditModuleNoAttributeCoursePopin = ({ open, onClose }) => {

    const renderContent = () => {
        return (
            <div>Pour programmer un rendez-vous directement dans les parcours, vous devez d'abord attribuer ce parcours à un de vos talent.</div>
        )
    }

    return (
        <Modal
        onClosePopin={onClose}
        openPopin={open}
        title={"Attribution de parcours"}
        content={renderContent()}
        cancelButton={"Retour"}
        actionButton={"Confirmer"}
        handleActionButton={onClose}
    />
    )
}