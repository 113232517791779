import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '../../../components/atoms/Icon/Icon'
import LogoutButton from '../../../components/atoms/LogoutButton/LogoutButton'
import SideMenu from '../../../components/organisms/SideMenu/SideMenu'
import UserLeftColumnLayout from '../../../layouts/UserLeftColumnLayout/UserLeftColumnLayout'
import {
  actions as MessageActions,
  // selectors as MessageSelectors,
} from '../../../redux/MessageRedux'
import { actions as OfficeActions, selectors as OfficeSelectors } from '../../../redux/OfficeRedux'
import { actions as UserActions, selectors as UserSelectors } from '../../../redux/UserRedux'
import { useNavigation } from '../../../hooks/useNavigation'
import { selectors as portalSelectors } from '../../../redux/PortalRedux'
import './ConsultantPageAside.scss';

// import logo from "../../../static/images/logotest.png"


export const getLinks = () => [
  {
    id: '/consultant/beneficiaries',
    label: "Talents",
    options: [
      { id: '/consultant/beneficiaries/me', label: "Individuels" },
      { id: '/consultant/beneficiaries/groups', label: "Groupes"},
    ],
    icon: Icon.icon.People,
  },
  {
    id: '/consultant/clients-missions',
    label: "Clients/Missions",
    options: [
      { id: '/consultant/clients-missions/clients', label: "Clients" },
      { id: '/consultant/clients-missions/missions', label: "Missions"},
    ],
    icon: Icon.icon.Building,
  },
  {
    id: '/consultant/templates',
    label: "Modèles",
    icon: Icon.icon.Paperboard,
    options: [
      { id: '/consultant/templates/courses', label: "Parcours" },
      // { id: '/consultant/templates/modules', label: "Modules"},
      // { id: '/consultant/templates/resources', label: "Ressources" },
      // { id: '/consultant/templates/synthesis', label: "Synthèses" },

    ],
  },
  {
    id: '/consultant/calendar',
    label: "Agenda",
    icon: Icon.icon.Calendar,
  },
   /*
  {
    id: '/consultant/messages',
    label: "Messages",
    icon: Icon.icon.Inbox,
    count: unreadMessages,
  },
  {
    id: '/consultant/profile',
    label: "Profil",
    icon: Icon.icon.Settings,
  },
  */
]

const ConsultantPageAside = () => {
  const dispatch = useDispatch()
  const office = useSelector(OfficeSelectors.office)
  const user = useSelector(UserSelectors.user)
  // const unreadMessages = useSelector(MessageSelectors.unreadMessages)
  const isActive = useSelector(portalSelectors.isActive)
  const { pathname, redirect } = useNavigation()

  const handleMenuChange = ({ id }) => {
    const link = getLinks().find((link) => link.id === id)

    if (!link || !link.options) {
      redirect(id)
    }
  }

  useEffect(() => {
    dispatch(OfficeActions.getCurrentUserOffice())
    dispatch(MessageActions.getUnreadMessages())
  }, [dispatch])

  const logout = () => dispatch(UserActions.logout({force: true}))

  

  const renderHeader = () => {
    if (!office || !user) {
      return null
    }

    return (
      <>
        <div className="logos">
          {!isActive && (
            <>
              <img
                className="mytalents-logo"
                alt="Logo"
                src="/static/logos/logo-my-talents-dark.svg"
                width={60}
                height={"auto"}
              />
              <div className="separator" />
            </>
          )}
          {/* <img className="office-logo" src={office.image_url} alt={office.name} /> */}
          <img className='office-logo' src={`${process.env.REACT_APP_ASSETS_API_STATIC_URL}${(office.image_url)?.replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')}`} alt={office.name} />
        </div>
      </>
    )
  }

  const renderMenu = () => {
    const links = getLinks()

    return (
      <SideMenu
        links={links}
        pathname={pathname}
        onClick={handleMenuChange}
        onClickOption={handleMenuChange}
      />
    )
  }

  const renderFooter = () => {
    return (
      <div className="footer">
        <div className="tutorials">
          <Icon icon="help" color="grey2"/>
          <a href="https://youtube.com/playlist?list=PL7A0hnS_VPccSaRDxOHrhYGOr5Ke0DniJ&si=nIZrgctI5nEGbJ5t" target="_blank">
            Tutoriels
          </a>
        </div>
        <LogoutButton label={"Déconnexion"} onClick={logout} />
      </div>

    )
  }

  if (!user) {
    return null
  }

  return (
    <div className="beneficiary-page-aside">
      <UserLeftColumnLayout header={renderHeader()} menu={renderMenu()} footer={renderFooter()} />
    </div>
  )
}

export default ConsultantPageAside
