import React from 'react'
import PropTypes, { func } from 'prop-types'
import './Icon.scss';
// Icons
import BackIcon from './icons/back.svg'
import BookIcon from './icons/book.svg'
import BuildingIcon from './icons/building.svg'
import BurgerMenuIcon from './icons/burger-menu.svg'
import CalendarIcon from './icons/calendar.svg'
import CentralIcon from './icons/centraltest.svg'
import CardIcon from './icons/card.svg'
import CheckIcon from './icons/check.svg'
import ChevronDownIcon from './icons/chevron-down.svg'
import ChevronUpIcon from './icons/chevron-up.svg'
import ClipboardIcon from './icons/clipboard.svg'
import CloseIcon from './icons/close.svg'
import Close2Icon from './icons/close2.svg'
import ComboIcon from './icons/combo.svg'
import CopyIcon from './icons/copy.svg'
import DateIcon from './icons/date.svg'
import DocumentIcon from './icons/document.svg'
import DragIcon from './icons/drag.svg'
import DuplicateIcon from './icons/duplicate.svg'
import DotIcon from './icons/dot.svg'
import DownloadIcon from './icons/download.svg'
import EditIcon from './icons/edit.svg'
import Edit2Icon from './icons/edit2.svg'
import ErrorIcon from './icons/error.svg'
import ExerciceIcon from './icons/exercice.svg'
import ExportIcon from './icons/export.svg'
import FileIcon from './icons/file.svg'
import FileReturnIcon from './icons/file-return.svg'
import FinishIcon from './icons/finish2.svg'
import FreeActiveIcon from './icons/free-active.svg'
import FreeIcon from './icons/free.svg'
import GroupIcon from './icons/group_blue.svg'
import HelpIcon from './icons/help.svg'
import HouseIcon from './icons/house.svg'
import ImageIcon from './icons/image.svg'
import InboxIcon from './icons/inbox.svg'
import KeyIcon from './icons/key.svg'
import LockedIcon from './icons/locked.svg'
import MailIcon from './icons/mail.svg'
import MoreIcon from './icons/more.svg'
import MultipleChoiceIcon from './icons/choix_blue.svg'
import NextIcon from './icons/next.svg'
import OngoingIcon from './icons/ongoing.svg'
import OpinionScaleIcon from './icons/ruler_blue.svg'
import PaperboardIcon from './icons/paperboard.svg'
import PencilIcon from './icons/pencil.svg'
import PeopleIcon from './icons/people.svg'
import PersonIcon from './icons/person.svg'
import PersonPlusIcon from './icons/person-plus.svg'
import PiechartIcon from './icons/piechart.svg'
import PlusIcon from './icons/plus.svg'
import Plus2Icon from './icons/plus2.svg'
import PreviewIcon from './icons/preview.svg'
import Preview2Icon from './icons/preview2.svg'
import PrivateIcon from './icons/private.svg'
import Publish from './icons/publish.svg'
import QuizIcon from './icons/quiz.svg'
import RadioChecked from './icons/radio-checked.svg'
import RadioUnchecked from './icons/radio-unchecked.svg'
import RatingIcon from './icons/evaluation_blue.svg'
import RdvIcon from './icons/rdv.svg'
import RefreshIcon from './icons/refresh.svg'
import ReloadIcon from './icons/reload.svg'
import RepeatIcon from './icons/repeat.svg'
import ReportIcon from './icons/report.svg'
import RingIcon from './icons/ring.svg'
import RoundMinusIcon from './icons/round-minus.svg'
import RoundPlusIcon from './icons/round-plus.svg'
import SearchIcon from './icons/search.svg'
import SelectIcon from './icons/dropdown_blue.svg'
import SettingIcon from './icons/setting.svg'
import SettingsIcon from './icons/settings.svg'
import ShareIcon from './icons/share.svg'
import StarIcon from './icons/star.svg'
import SuitcaseIcon from './icons/suitcase.svg'
import TextIcon from './icons/text_blue.svg'
import TrashIcon from './icons/trash.svg'
import Trash2Icon from './icons/trash2.svg'
import UntouchedIcon from './icons/untouched.svg'
import UploadIcon from './icons/upload.svg'
import VideoIcon from './icons/video.svg'
import WarningIcon from './icons/warning.svg'
import YesNoIcon from './icons/yesno_blue.svg'
import VisioIcon from './icons/visio.svg'
import MessageIcon from './icons/message.svg';
import NotificationIcon from './icons/notification.svg';
import ChevronLeft from './icons/chevron-left.svg'
import ChevronRight from './icons/chevron-right.svg'
import Grid from './icons/grid.svg'
import Clock from './icons/clock.svg'
import PlayIcon from './icons/play.svg'
import LinkIcon from './icons/link.svg'
import ToStartIcon from "./icons/tostart.svg";
import LockIcon from './icons/lock.svg'
import CircleCheck from "./icons/circle-check.svg";
import DocumentLinkIcon from "./icons/document-link.svg";
import DocumentReadIcon from "./icons/document-read.svg";
import DocumentVideoIcon from "./icons/document-video.svg";
import MoreHorizontal from "./icons/more-horizontal.svg";
import Unlocked from "./icons/unlock.svg";
import CalendarEditIcon from "./icons/calendar-edit.svg";
import PinIcon from "./icons/pin.svg";
import PhoneIcon from "./icons/phone.svg"
import FeedbackIcon from "./icons/thumbs-up.svg";
import StopIcon from "./icons/stop.svg";
import CropIcon from "./icons/crop.svg";
import SlidersIcon from "./icons/sliders.svg";
import QuestionIcon from "./icons/question.svg";

// Icon
const icon = {
  Back: 'back',
  Book: 'book',
  Building: 'building',
  BurgerMenu: 'burger-menu',
  Calendar: 'calendar',
  Card: 'card',
  CentralTest: 'centraltest',
  Check: 'check',
  ChevronDown: 'chevron-down',
  ChevronUp: 'chevron-up',
  Clipboard: 'clipboard',
  Close: 'close',
  Close2: 'close2',
  Combo: 'combo',
  Copy: 'copy',
  Date: 'date',
  Document: 'document',
  Drag: 'drag',
  Duplicate: 'duplicate',
  Dot: 'dot',
  Download: 'download',
  Edit: 'edit',
  Edit2: 'edit2',
  Error: 'error',
  Exercice: 'exercice',
  Export: 'export',
  File: 'file',
  FileReturn: 'file-return',
  Finish: 'finish',
  FreeActive: 'free-active',
  Free: 'free',
  Group: 'group',
  Help: 'help',
  House: 'house',
  Image: 'image',
  Inbox: 'inbox',
  Key: 'key',
  Lock: 'lock',
  Locked: 'locked',
  Mail: 'mail',
  More: 'more',
  MultipleChoice: 'multiple_choice',
  Next: 'next',
  Ongoing: 'ongoing',
  OpinionScale: 'opinion_scale',
  Paperboard: 'paperboard',
  Pencil: 'pencil',
  People: 'people',
  Person: 'person',
  PersonPlus: 'person-plus',
  Piechart: 'piechart',
  Plus: 'plus',
  Plus2: 'plus2',
  Preview: 'preview',
  Preview2: 'preview2',
  Private: 'private',
  Publish: 'publish',
  Quiz: 'quiz',
  Form: 'form',
  RadioChecked: 'radio-checked',
  RadioUnchecked: 'radio-unchecked',
  Rating: 'rating',
  Rdv: 'rdv',
  Refresh: 'refresh',
  Reload: 'reload',
  Repeat: 'repeat',
  Report: 'report',
  Ring: 'ring',
  RoundMinus: 'round-minus',
  RoundPlus: 'round-plus',
  Search: 'search',
  Select: 'select',
  Setting: 'setting',
  Settings: 'settings',
  Share: 'share',
  Star: 'star',
  Suitcase: 'suitcase',
  Text: 'text',
  Trash: 'trash',
  Trash2: 'trash2',
  Untouched: 'untouched',
  Upload: 'upload',
  Video: 'video',
  Warning: 'warning',
  YesNo: 'yes_no',
  Visio: 'visio',
  Message: 'message',
  Notification: 'notification',
  ChevronLeft: 'chevron_left',
  ChevronRight: 'chevron_right',
  Grid: 'grid',
  Clock: 'clock',
  Play: 'play',
  Link: 'link',
  ToStart: 'start',
  CircleCheck: 'circle_check',
  DocumentLink: 'document-link',
  DocumentVideo: 'document-video',
  DocumentRead: 'document-read',
  MoreHorizontal: 'more-horizontal',
  Unlocked: 'unlocked',
  CalendarEdit: 'calendar-edit',
  Pin: 'pin',
  Phone: 'phone',
  Feedback: 'feedback',
  Stop: 'stop',
  Sliders: 'sliders',
  Crop: 'crop',
  Question: 'question'
}

const color = {
  Accent: 'accent',
  Grey1: 'grey1',
  Grey2: 'grey2',
  Grey3: 'grey3',
  Red: 'red',
  Orange: 'orange',
  Green: 'green',
  White: 'white',
}

// Proptypes
const propTypes = {
  icon: PropTypes.oneOf(Object.values(icon)).isRequired,
  color: PropTypes.oneOf(Object.values(color)),
  onClick: func
}

// DefaultProps
const defaultProps = {
  color: null,
}

// Component
const Icon = ({ icon, color, onClick }) => (
  <div className={`icon ${icon}`} data-color={color}>
    {icon === Icon.icon.Back && <BackIcon />}
    {icon === Icon.icon.Book && <BookIcon />}
    {icon === Icon.icon.Building && <BuildingIcon />}
    {icon === Icon.icon.BurgerMenu && <BurgerMenuIcon />}
    {icon === Icon.icon.Calendar && <CalendarIcon />}
    {icon === Icon.icon.Card && <CardIcon />}
    {icon === Icon.icon.CentralTest && <CentralIcon />}
    {icon === Icon.icon.Check && <CheckIcon />}
    {icon === Icon.icon.ChevronDown && <ChevronDownIcon onClick={onClick}/>}
    {icon === Icon.icon.ChevronUp && <ChevronUpIcon onClick={onClick}/>}
    {icon === Icon.icon.Clipboard && <ClipboardIcon />}
    {icon === Icon.icon.Close && <CloseIcon />}
    {icon === Icon.icon.Close2 && <Close2Icon />}
    {icon === Icon.icon.Combo && <ComboIcon />}
    {icon === Icon.icon.Copy && <CopyIcon />}
    {icon === Icon.icon.Date && <DateIcon />}
    {icon === Icon.icon.Document && <DocumentIcon />}
    {icon === Icon.icon.Drag && <DragIcon />}
    {icon === Icon.icon.Duplicate && <DuplicateIcon />}
    {icon === Icon.icon.Dot && <DotIcon />}
    {icon === Icon.icon.Download && <DownloadIcon />}
    {icon === Icon.icon.Edit && <EditIcon />}
    {icon === Icon.icon.Edit2 && <Edit2Icon />}
    {icon === Icon.icon.Error && <ErrorIcon />}
    {icon === Icon.icon.Exercice && <ExerciceIcon />}
    {icon === Icon.icon.Export && <ExportIcon />}
    {icon === Icon.icon.File && <FileIcon />}
    {icon === Icon.icon.FileReturn && <FileReturnIcon />}
    {icon === Icon.icon.Finish && <FinishIcon />}
    {icon === Icon.icon.FreeActive && <FreeActiveIcon />}
    {icon === Icon.icon.Free && <FreeIcon />}
    {icon === Icon.icon.Question && <QuestionIcon />}
    {icon === Icon.icon.Group && <GroupIcon />}
    {icon === Icon.icon.Help && <HelpIcon />}
    {icon === Icon.icon.House && <HouseIcon />}
    {icon === Icon.icon.Image && <ImageIcon />}
    {icon === Icon.icon.Inbox && <InboxIcon />}
    {icon === Icon.icon.Key && <KeyIcon />}
    {icon === Icon.icon.Lock && <LockIcon />}
    {icon === Icon.icon.Locked && <LockedIcon />}
    {icon === Icon.icon.Mail && <MailIcon />}
    {icon === Icon.icon.More && <MoreIcon />}
    {icon === Icon.icon.MultipleChoice && <MultipleChoiceIcon />}
    {icon === Icon.icon.Next && <NextIcon />}
    {icon === Icon.icon.Ongoing && <OngoingIcon />}
    {icon === Icon.icon.OpinionScale && <OpinionScaleIcon />}
    {icon === Icon.icon.Paperboard && <PaperboardIcon />}
    {icon === Icon.icon.Pencil && <PencilIcon />}
    {icon === Icon.icon.People && <PeopleIcon />}
    {icon === Icon.icon.Person && <PersonIcon />}
    {icon === Icon.icon.PersonPlus && <PersonPlusIcon />}
    {icon === Icon.icon.Piechart && <PiechartIcon />}
    {icon === Icon.icon.Plus && <PlusIcon />}
    {icon === Icon.icon.Plus2 && <Plus2Icon />}
    {icon === Icon.icon.Preview && <PreviewIcon />}
    {icon === Icon.icon.Preview2 && <Preview2Icon />}
    {icon === Icon.icon.Private && <PrivateIcon />}
    {icon === Icon.icon.Publish && <Publish />}
    {icon === Icon.icon.Quiz && <QuizIcon />}
    {icon === Icon.icon.Form && <QuizIcon />}
    {icon === Icon.icon.RadioChecked && <RadioChecked />}
    {icon === Icon.icon.RadioUnchecked && <RadioUnchecked />}
    {icon === Icon.icon.Rating && <RatingIcon />}
    {icon === Icon.icon.Rdv && <RdvIcon />}
    {icon === Icon.icon.Refresh && <RefreshIcon />}
    {icon === Icon.icon.Reload && <ReloadIcon />}
    {icon === Icon.icon.Repeat && <RepeatIcon />}
    {icon === Icon.icon.Report && <ReportIcon />}
    {icon === Icon.icon.Ring && <RingIcon />}
    {icon === Icon.icon.RoundMinus && <RoundMinusIcon />}
    {icon === Icon.icon.RoundPlus && <RoundPlusIcon />}
    {icon === Icon.icon.Search && <SearchIcon />}
    {icon === Icon.icon.Select && <SelectIcon />}
    {icon === Icon.icon.Setting && <SettingIcon />}
    {icon === Icon.icon.Settings && <SettingsIcon />}
    {icon === Icon.icon.Share && <ShareIcon />}
    {icon === Icon.icon.Star && <StarIcon />}
    {icon === Icon.icon.Suitcase && <SuitcaseIcon />}
    {icon === Icon.icon.Text && <TextIcon />}
    {icon === Icon.icon.Trash && <TrashIcon />}
    {icon === Icon.icon.Trash2 && <Trash2Icon />}
    {icon === Icon.icon.Untouched && <UntouchedIcon />}
    {icon === Icon.icon.Upload && <UploadIcon />}
    {icon === Icon.icon.Video && <VideoIcon />}
    {icon === Icon.icon.Warning && <WarningIcon />}
    {icon === Icon.icon.YesNo && <YesNoIcon />}
    {icon === Icon.icon.Visio && <VisioIcon />}
    {icon === Icon.icon.Message && <MessageIcon />}
    {icon === Icon.icon.Notification && <NotificationIcon />}
    {icon === Icon.icon.ChevronLeft && <ChevronLeft />}
    {icon === Icon.icon.ChevronRight && <ChevronRight />}
    {icon === Icon.icon.Grid && <Grid />}
    {icon === Icon.icon.Clock && <Clock />}
    {icon === Icon.icon.Play && <PlayIcon />}
    {icon === Icon.icon.Link && <LinkIcon />}
    {icon === Icon.icon.ToStart && <ToStartIcon />}
    {icon === Icon.icon.CircleCheck && <CircleCheck />}
    {icon === Icon.icon.DocumentLink && <DocumentLinkIcon/>}
    {icon === Icon.icon.DocumentRead && <DocumentReadIcon/>}
    {icon === Icon.icon.DocumentVideo && <DocumentVideoIcon/>}
    {icon === Icon.icon.MoreHorizontal && <MoreHorizontal/>}
    {icon === Icon.icon.Unlocked && <Unlocked/>}
    {icon === Icon.icon.CalendarEdit && <CalendarEditIcon/>}
    {icon === Icon.icon.Pin && <PinIcon/>}
    {icon === Icon.icon.Phone && <PhoneIcon/>}
    {icon === Icon.icon.Feedback && <FeedbackIcon/>}
    {icon === Icon.icon.Stop && <StopIcon/>}
    {icon === Icon.icon.Sliders && <SlidersIcon/>}
    {icon === Icon.icon.Crop && <CropIcon/>}

  </div>
)

Icon.propTypes = propTypes
Icon.defaultProps = defaultProps
Icon.icon = icon
Icon.color = color

export default Icon
