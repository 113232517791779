import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Icon from '../Icon/Icon'
import { SpinnerCircularFixed } from 'spinners-react'
import './PrimaryButton.scss'
const PrimaryButton = ({
  id,
  label,
  icon,
  type = 'button',
  onClick,
  loading = false,
  cancel,
  disabled = false,
  outline = false,
  nowrap = true,
  badge = null,
  ...rest
}) => {
  const className = classnames('primary-button', {
    outline,
    disabled,
    cancel
  })

  const handleOnClick = () => {
    if (!disabled || !loading) {
      onClick && onClick({ id })
    }
  }

  return (
    <button type={type} className={className} disabled={disabled} onClick={handleOnClick} id={id} style={{ position: "relative" }}  {...rest}>
      {loading && (
        <SpinnerCircularFixed
          size={16}
          thickness={200}
          speed={150}
          color={!outline ? Icon.color.White : Icon.color.Accent}
          secondaryColor={Icon.color.Grey3}
          className="icon"
        />
      )}
      {icon && !loading && <Icon icon={icon} color={Icon.color.White} />}
      <div className="label" style={{ whiteSpace: nowrap ? 'nowrap' : 'normal' }}>{label}</div>
      {badge && <div style={{ position: "absolute", top: 0, right: 0, backgroundColor: "red", transform: "translate(20%, -50%)", padding: "2px 10px", borderRadius: "25px", fontSize: "12px" }}>{badge}</div>}
    </button>
  )
}

PrimaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.oneOf(Object.values(Icon.icon)),
  type: PropTypes.oneOf(['button', 'submit']),
}

export default PrimaryButton
