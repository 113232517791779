import React, { Component } from 'react'
import { connect } from 'react-redux'
import Page from '../../containers/global/Page/Page'
import FileUpload from '../../containers/global/FileUpload/FileUpload'
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside'

import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'
import AvatarField from '../../components/molecules/AvatarField/AvatarField'
import UserCard from '../../components/organisms/UserCard/UserCard'
import CheckBoxField from '../../components/molecules/CheckBoxField/CheckBoxField'

import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'

import { selectors as UserSelectors } from '../../redux/UserRedux'
import { actions as ConsultantActions, selectors as ConsultantSelectors } from '../../redux/ConsultantRedux'
import { selectors as OfficeSelectors } from '../../redux/OfficeRedux'
import { generateCalendarSyncUrl, storeToken, generateVisioSyncUrl, getUserCalendar, checkCalendarSync } from '../../api/CronofyApi'
import CronofySync from '../../components/molecules/CronofyConnect/CronofySync'

import './ConsultantProfilePage.scss';

const mapStateToProps = (state) => ({
  // valid: isValid('user-card')(state),
  user: UserSelectors.user(state),
  consultant: ConsultantSelectors.consultant(state),
  hasChanged: ConsultantSelectors.hasChanged(state),
  office: OfficeSelectors.office(state),
  syncInProgress: ConsultantSelectors.syncInProgress(state),
})

const mapDispatchToProps = (dispatch) => ({
  saveConsultant: (consultant, isCurrent) => dispatch(ConsultantActions.saveConsultant(consultant, isCurrent)),
  getConsultant: (id) => dispatch(ConsultantActions.getConsultant(id)),
  setConsultant: (consultant) => dispatch(ConsultantActions.setConsultant(consultant)),
  updateConsultant: (consultant) => dispatch(ConsultantActions.updateConsultant(consultant)),
  syncCronofy: (code, user) => dispatch(ConsultantActions.syncCronofy({ code, user })),
})


class ConsultantModulesPage extends Component {

  state = {
    syncUrl: null,
    visioSyncUrl: null,
    currentUrl: window.location.href,
    authCode: "",
    calendar_id: "",
    cronofy_synced: false,
    isValid: false
  }

  createPopup = (url) => {
    const width = 375;
    const height = 790;

    const options = {
      width: width,
      height: height,
      left: window.screenX + (window.outerWidth - width) / 2,
      top: window.screenY + (window.outerHeight - height) / 2.5,
      scrollbars: 'yes'
    };

    const popup = window.open(
      url,
      "Cronofy_Sync",
      `width=${options.width},height=${options.height},top=${options.top},left=${options.left},scrollbars=${options.scrollbars}`
    );

    return popup;
  }

  handleFileChange = ({ file }) => {
    this.handleUserChange({ id: 'image_url', value: file.url })
  }

  handleUserChange = ({ id, value }) => {
    const map = {
      name: 'last_name',
      firstName: 'first_name',
    }
    const update = {}

    update[map[id] || id] = value
    this.setState({ isValid: true })
    this.props.updateConsultant(update)
  }

  handleGenerateVisioUrl = () => {

    const data = {
      user_id: this.props.user.id
    }

    generateVisioSyncUrl(data).then((response) => {
      this.setState({ visioSyncUrl: response.authorization_request.url })
    })
  }

  handleGenerateSyncUrl = () => {

    const data = {
      user_id: this.props.user.id
    }

    generateCalendarSyncUrl(data).then((response) => {
      this.setState({ syncUrl: response });
    })
  }

  handleSyncAccount = (code, user_id) => {
    const data = {
      code: code,
      user_id: user_id,
      token_refresh: true
    }

    storeToken(data).then((response) => {
      if (response != null && response !== undefined) {
        this.handleUserCalendars();
      }
    })
  }

  handleUserCalendars = () => {
    getUserCalendar(this.props.user.id).then((response) => {
      this.setState({ calendar_id: response.calendars[0].calendar_id })
      this.handleCheckSync();
    })
  }

  getCodeFromURL = (url) => {
    const searchParams = new URLSearchParams(url.split("?")[1]);
    return searchParams.get("code");
  }

  handleOAuthClick = () => {

    const popup = this.createPopup(this.state.syncUrl);

    const checkRedirectInterval = setInterval(() => {
      try {
        if (popup.location.href.includes('code=')) {
          const code = this.getCodeFromURL(popup.location.href);
          this.setState({ authCode: code });
          this.handleSyncAccount(code, this.props.user.id);
          popup.close();

          clearInterval(checkRedirectInterval);
        }
      } catch (error) {
        // Erreur d'accès au href sur cross-origin
      }
    }, 500);
  }

  handleVisioClick = () => {

    const popup = this.createPopup(this.state.visioSyncUrl);

    const checkRedirectInterval = setInterval(() => {
      try {
        if (popup.location.href.includes('profile_id=')) {
          popup.close();

          clearInterval(checkRedirectInterval);
        }
      } catch (error) {
        // Erreur d'accès au href sur cross-origin
      }
    }, 500);

  }

  handleSyncAgenda = (data) => {
    const { code } = data
    if (code) {
      this.props.syncCronofy(data.code, this.props.user)
    }
  }

  handleSave = () => {
    const { consultant } = this.props
    const { id, first_name, last_name, image_url, phone, email, optin_email } = consultant
    const data = {
      id,
      first_name,
      last_name,
      image_url,
      phone,
      email,
      optin_email,
      function: consultant.function,
    }

    this.props.saveConsultant(data, true)
    this.setState({ isValid: false })

  }

  handleCheckSync = () => {
    checkCalendarSync(this.props.user.id).then((response) => {
      if (response !== undefined && response != null) {
        this.setState({ cronofy_synced: true })
      }
    })
  }

  componentDidMount() {
    if (this.props.user) {
      this.props.getConsultant(this.props.user.id)
    }


    this.handleGenerateSyncUrl();
    this.handleGenerateVisioUrl();
    this.handleCheckSync();
  }

  componentWillUnmount() {
    this.props.setConsultant(null)
  }

  renderTabs() {
    const links = [{ id: 'stats', label: "Mon profil" }]
    return <LineMenu value={links[0].id} links={links} />
  }


  renderContent = () => {
    const { consultant, office, syncInProgress } = this.props
    // const encodedRedirectUri = encodeURIComponent(config.CRONOFY_REDIRECT_URI)
    //const cronofySyncUrl = `${config.CRONOFY_BASE_URL}?client_id=${config.CRONOFY_CLIENT}&redirect_uri=${encodedRedirectUri}&response_type=code&scope=read_write`
    //const cronofySyncUrl = `${process.env.REACT_APP_API_MY_TALENTS_EVOLUTION}` + "calendars/auth"

    if (!office) return null
    const {
      first_name,
      last_name,
      phone,
      email,
      optin_email,
      image_url,
      cronofy_account,
      role
    } = consultant


    const locale = {
      name: "Nom",
      namePlaceHolder: "Votre nom",
      firstName: "Prénom",
      firstNamePlaceHolder: "Votre prénom",
      function: "Fonction",
      functionPlaceholder: "Votre fonction",
      company: "Entreprise",
      email: "Email",
      emailPlaceHolder: "Votre email",
      phone: "Numéro de téléphone",
      phonePlaceHolder: "Votre numéro de téléphone",
    }

    const cronofyButtonLabel = syncInProgress
      ? "Chargement"
      : cronofy_account
        ? "Changer d'agenda"
        : "Synchroniser"

    return (
      <div className="profile-page-content">
        <PrimaryButton
          disabled={!this.state.isValid}
          id="profile-save"
          label={"Sauvegarder"}
          onClick={this.handleSave}
        />
        <div className='profile-user-main-informations'>
          <div className="avatar-wrapper">
            <FileUpload accept="image/*" id="image_url" onChange={this.handleFileChange}>
              {({ onClick, uploading }) => (
                <AvatarField photoUrl={image_url} onClick={onClick} uploading={uploading} />
              )}
            </FileUpload>
            <div className='profile-user-name'>
              <h1>{first_name} {last_name} </h1>
              <p>{role}</p>
            </div>
          </div>
          <UserCard
            onChange={this.handleUserChange}
            phone={phone}
            email={email}
            firstName={first_name}
            name={last_name}
            company={office.name}
            function={consultant.function}
            hideAvatarAndHeader
            locale={locale}
          />
        </div>
        <div className="preference-wrapper">
          <div className='preference-wrapper-title'>
            <p className="preference-label">Vos préférences</p>
          </div>

          <div className='preference-messages'>
            <h2 className="preference-label">Messagerie</h2>
            <CheckBoxField
              name="email-preference"
              id="option_email"
              radio={true}
              value={!!optin_email}
              label={"Réception des messages par email"}
              onChange={this.handleUserChange}
            />
          </div>
          <div className='preference-agenda'>
            <h2 className="preference-label">Agenda</h2>
            <div className="cronofy-sync-wrapper">
              {this.state.cronofy_synced &&
                <div className="cronofy-account">
                  <div className='statut-cronofy-account sync'></div>
                  <p>Votre agenda est synchronisé</p>
                </div>}
              {!this.state.cronofy_synced && (
                <div className="cronofy-account">
                  <div className='statut-cronofy-account no-sync'></div>
                  <p className="cronofy-no-account">Vous n'avez pas synchronisé votre agenda</p>
                </div>
              )}
              <CronofySync
                onClick={this.handleOAuthClick}
                buttonLabel={cronofyButtonLabel}
                disabled={syncInProgress}
                popupTitle={"Synchroniser votre agenda"}
              />

              {this.state.cronofy_synced && (
                <CronofySync
                  onClick={this.handleVisioClick}
                  buttonLabel={"Choisir un outil de visioconférence"}
                />
              )}

            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { consultant } = this.props
    if (!consultant) return null

    const aside = <ConsultantPageAside />
    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return (
      <div className="consultant-profile-page">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(ConsultantModulesPage))