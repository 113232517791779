import { axiosInstance } from "./baseApi";


export const createAppointment = async (appointment) => {
    const response = await axiosInstance.post('appointments/add', appointment);
    return response.data;
}

export const getAppointmentById = async (appointment_id) => {
    const response = await axiosInstance.get(`appointments/${appointment_id}`);
    return response.data;
}

export const getAllAppointmentsByUserId = async (user_id) => {
    const response = await axiosInstance.get(`appointments/user/${user_id}/all`);
    return response.data;
}

export const updateAppointment = async (appointment) => {
    const response = await axiosInstance.put(`appointments/${appointment.id}/update`, appointment);
    return response.data;
}

export const deleteAppointmentById = async (appointment_id) => {
    const response = await axiosInstance.delete(`appointments/${appointment_id}/delete`);
    return response.data;
}

export const getContactsAppointments = async (appointment_id, user_id) => {
    const response = await axiosInstance.get(`appointments/${appointment_id}/user/${user_id}/contacts`);
    return response.data;
}

export const updateStatusGuestAppointment = async (appointment_id, user_id, data) => {
    const response = await axiosInstance.put(`appointments/${appointment_id}/user/${user_id}/update_invitation`, data);
    return response.data;
}

export const deleteParticipantAppointment = async (appointment_id, user_id) => {
    const response = await axiosInstance.delete(`appointments/${appointment_id}/user/${user_id}/delete`);
    return response.data;
}

export const relaunchInvitationAppointmentAllParticipants = async (appointment_id) => {
    const response = await axiosInstance.post(`appointments/${appointment_id}/relaunch_invitation`);
    return response.data;
}

export const relaunchInvitationAppointmentOneParticipant = async (appointment_id, user_id) => {
    const response = await axiosInstance.post(`appointments/${appointment_id}/user/${user_id}/relaunch_invitation`);
    return response.data;
}

export const sendTallysheetAppointmentAllParticipants = async (appointment_id) => {
    const response = await axiosInstance.post(`appointments/${appointment_id}/send_tallysheet`);
    return response.data;
}

export const sendTallysheetAppointmentOneParticipant = async (appointment_id, user_id) => {
    const response = await axiosInstance.post(`appointments/${appointment_id}/user/${user_id}/send_tallysheet`);
    return response.data;
}

export const updateTallysheetAppointment = async (appointment_id, user_id) => {
    const response = await axiosInstance.put(`appointments/${appointment_id}/user/${user_id}/update_tallysheet`);
    return response.data;
}

export const validateTallysheetAppointment = async (tallysheet_key) => {
    const response = await axiosInstance.get(`appointments/tallysheet/personal_key/${tallysheet_key}/sign`);
    return response.data;
}
