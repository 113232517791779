import _mapValues from 'lodash/mapValues'
import _pick from 'lodash/pick'
import 'moment/locale/fr'
import moment from 'moment/moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isValid, reduxForm } from 'redux-form'
import Icon from '../../../components/atoms/Icon/Icon'
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton'
import {
  getValidationRules,
  profileFieldsForConsultant,
} from '../../../helpers/beneficiaryProfileForm'
import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator'
import { actions as BeneficiaryActions } from '../../../redux/BeneficiaryRedux'
import { selectors as NavigationSelectors } from '../../../redux/NavigationRedux'
import { actions as NotesActions, selectors as NotesSelectors } from '../../../redux/NotesRedux'
import { actions as UserActions, selectors as UserSelectors } from '../../../redux/UserRedux'
import { selectors as WSSelectors } from '../../../redux/WSRedux'
import { BeneficiaryProfileContent } from '../../beneficiary/BeneficiaryProfileContent/BeneficiaryProfileContent'
import Page from '../../global/Page/Page'

import ConsultantBeneficiary from '../ConsultantBeneficiary/ConsultantBeneficiary'
import ConsultantBeneficiaryAside from '../ConsultantBeneficiaryAside/ConsultantBeneficiaryAside'
import { formatBeneficiary } from '../../../api/utils/BeneficiaryUtils'
import HeaderConsultantTab from '../../../components/atoms/HeaderConsultantTab/HeaderConsultantTab'
import HeaderIllustration from '../../../static/illustrations/InfoPerso.png'
const mapStateToProps = (state, props) => ({
  valid: isValid('beneficiary-profile')(state),
  user: UserSelectors.user(state),
  pathname: NavigationSelectors.pathname(state),
  consultantNotes: NotesSelectors.consultantNotes(state),
  saveBeneficiaryAction: WSSelectors.action(state, 'beneficiary.saveBeneficiary'),
  saveNotesAction: WSSelectors.action(state, 'notes.save'),
  getNotesAction: WSSelectors.action(state, 'notes.get'),
  initialValues: {
    ...props.beneficiary,
    ..._mapValues(
      _pick(props.beneficiary, [
        'birth_date',
        'accompaniment_start_date',
        'accompaniment_end_date',
      ]),
      (date) => {
        if (date !== null && date !== '') return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        return date
      },
    ),
  },
})

const mapDispatchToProps = (dispatch) => ({
  setBeneficiary: (beneficiary) => dispatch(BeneficiaryActions.setBeneficiary(beneficiary)),
  saveBeneficiary: (beneficiary) => dispatch(BeneficiaryActions.saveBeneficiary(beneficiary)),
  saveNotes: () => dispatch(NotesActions.saveNotes(true)),
  resetPassword: (email) => dispatch(UserActions.forgotPassword(email)),
  sendInvitation: (id) => dispatch(UserActions.sendInvitation(id)),
})

class ConsultantBeneficiaryProfileContainer extends Component {

  handleChange = ({ value }) => {
    const newBeneficiary = {
      ...this.props.beneficiary,
      ...value,
    }

    this.props.setBeneficiary(newBeneficiary)
  }

  getUser = (newUser) => {
    return newUser
  }

  isPrimary = () => {
    const { user, beneficiary } = this.props

    if (user.role === 'manager') {
      return true
    }
    return beneficiary.consultants.reduce(
      (res, consultant) => (consultant.id === user.id ? res || consultant.is_primary : res),
      false,
    )
  }

  renderContent = () => {
    const { beneficiary, groups, user } = this.props

    return (
      <BeneficiaryProfileContent
        userProps={beneficiary}
        groups={groups}
        onChange={this.handleChange}
        consultantEdition
        readOnly={false} //!isPrimary
        currentUserRole={user.role}
        isNew={false}
        sendUser={this.getUser}
      />
    )
  }

  renderAside = () => {
    return <ConsultantBeneficiaryAside />
  }

  newBeneficiaryIsDifferent = () => {
    const formattedStateBeneficiary = formatBeneficiary(this.state.beneficiary)
    const formattedPropsBeneficiary = formatBeneficiary(this.props.beneficiary)

    const beneficiaryIsDifferent = [
      'status',
      'last_name',
      'first_name',
      'image_url',
      'gender',
      'email',
      'birth_date',
      'phone_mobile',
      'address',
      'zip_code',
      'city',
      'phone_home',
      'cursus_formation',
      'cursus_professional_situation',
      'cursus_socio_professional_category',
      'diploma_name',
      'cursus_function',
      'cursus_company_activity_sector',
      'cursus_non_company_activity_sector',
      'cursus_company_size',
      'accompaniment_type',
      'accompaniment_duration',
      'accompaniment_duration_type',
      'accompaniment_start_date',
      'accompaniment_end_date',
      'accompaniment_survey_count',
      'accompaniment_appraisal_type',
      'accompaniment_appraisal_availability',
      'financer_state',
      'financer_type',
      'financer_opacif_type',
      'financer_name',
      'financer_support_request_file',
      'financer_support_agreement_file',
      'financial_ht',
      'financial_tva',
      'group_id',
      'cursus_handicap_status',
      'cursus_handicap_file',
      'cursus_cv_file',
      'source',
    ].reduce(
      (res, prop) => res || formattedStateBeneficiary[prop] !== formattedPropsBeneficiary[prop],
      false,
    )

    const consultantNotesState =
      this.state.consultantNotes !== null && this.state.consultantNotes.length > 0
        ? this.state.consultantNotes[0].notes
        : ''
    const consultantNotesProps =
      this.props.consultantNotes !== null && this.props.consultantNotes.length > 0
        ? this.props.consultantNotes[0].notes
        : ''

    return beneficiaryIsDifferent || consultantNotesState !== consultantNotesProps
  }

  disableSave = () => {
    const { saveBeneficiaryAction, saveNotesAction, valid } = this.props

    const { pending: beneficiarySaving } = saveBeneficiaryAction
    const { pending: notesSaving } = saveNotesAction

    const newBeneficiaryIsDifferent = this.newBeneficiaryIsDifferent()

    return beneficiarySaving || notesSaving || !newBeneficiaryIsDifferent || !valid
  }

  handleSave = () => {

    const { valid } = this.props
    if (valid) {

      if (this.isPrimary()) {
        const { beneficiary, saveBeneficiary } = this.props

        const newBeneficiary = formatBeneficiary(beneficiary)

        this.setState({
          resetBeneficiary: true,
          resetNotes: true,
        })
        saveBeneficiary(newBeneficiary)
      } else {
        const { saveNotes } = this.props

        this.setState({
          resetNotes: true,
        })

        saveNotes()
      }
    }
  }

  handleResetPassword = () => {
    const { beneficiary, resetPassword } = this.props
    resetPassword(beneficiary.email)
  }

  handleSendInvitation = () => {
    const { beneficiary, sendInvitation } = this.props
    sendInvitation(beneficiary.id)
  }

  renderResetPasswordButton() {
    const { beneficiary } = this.props
    const { id, activated_at } = beneficiary

    if (!id || !activated_at) {
      return null
    }

    return (
      <PrimaryButton
        id="reset"
        label={"Réinitialiser son mot de passe"}
        icon={Icon.icon.Key}
        onClick={this.handleResetPassword}
      />
    )
  }

  renderSendInvitationButton() {
    const { beneficiary } = this.props
    const { id, activated_at } = beneficiary

    if (!id || activated_at) {
      return null
    }

    return (
      <>
        <PrimaryButton
          id="send-invitation"
          label={"Renvoyer l'invitation"}
          icon={Icon.icon.Mail}
          onClick={this.handleSendInvitation}
        />

        <span>{"Le talent n'a pas encore activé son compte"}</span>
      </>
    )
  }

  static getDerivedStateFromProps(
    { beneficiary, saveBeneficiaryAction, getNotesAction, saveNotesAction, consultantNotes },
    state,
  ) {
    let stateUpdates = null

    if (
      beneficiary &&
      (state.beneficiary === null ||
        (state.resetBeneficiary && saveBeneficiaryAction.done === true))
    ) {
      stateUpdates = {
        beneficiary: {
          ...beneficiary,
          status: beneficiary.status || '',
        },
        resetBeneficiary: false,
      }
    }

    if (
      consultantNotes &&
      (state.consultantNotes === null ||
        getNotesAction.done === true ||
        (state.resetNotes && saveNotesAction.done === true))
    ) {
      stateUpdates = stateUpdates !== null ? stateUpdates : {}

      stateUpdates = {
        ...stateUpdates,
        consultantNotes,
        resetNotes: false,
      }
    }

    return stateUpdates
  }

  constructor(props) {
    super(props)

    this.state = {
      beneficiary: null,
      consultantNotes: null,
      resetBeneficiary: false,
      resetNotes: false,
    }
  }

  render() {
    return (
      <ConsultantBeneficiary
        renderAside={this.renderAside}
        disableSave={this.disableSave()}
        onSave={this.handleSave}
      >
        <div className="consultant-beneficiary-profile-page">
          <HeaderConsultantTab title="Informations personnelles" description={'Retrouvez ici les informations personnelles de votre talent'} illustration={HeaderIllustration} logo={null} />
          <form noValidate>{this.renderContent()}</form>

          {this.renderResetPasswordButton()}
        </div>
      </ConsultantBeneficiary>
    )
  }
}

ConsultantBeneficiaryProfileContainer = reduxForm(
  {
    form: 'beneficiary-profile',
    asyncBlurFields: profileFieldsForConsultant,
    asyncChangeFields: profileFieldsForConsultant,
    asyncValidate: (values, dispatch, props) => {
      const validationRules = getValidationRules(props.beneficiary, values, true)
      return asyncValidate(values, validationRules)
    },
  })(ConsultantBeneficiaryProfileContainer)

export default connect(mapStateToProps, mapDispatchToProps)(Page(ConsultantBeneficiaryProfileContainer))