const AccompanimentCoFollowupProjectLocale = {
    subtitle: "Solution",
    numberOfRdvLabel: "Nombre de rendez-vous",
    numberOfJobOfferLabel: "Nombre d'offres d'emploi étudiées",
    numberOfWorkshopLabel: "Nombre de participation aux ateliers",
}

const optionsSitutation = [
    { id: 'aucun', value: " ", label: "Aucun" },
    { value: 'professional-assessment', label: 'Bilan professionnel' },
    { value: 'project-validation', label: 'Validation de projet' },
    {value: 'job-search', label: "Recherche d'emploi"},  
    {value: 'reconversion', label: 'Reconversion avec formation longue'},
    { value: 'start-business', label: "Création d'entreprise" },
    { value: 'pension', label: "Retraite" },   
    { value: 'identified-solution', label: 'Solution identifiée' },
    { value: 'solution-exit', label: 'Sortie en solution' },
    { value: 'solution-exit-without-solution', label: 'Sortie sans solution' },
    { value: 'abandon', label: 'Abandon' },
]

const optionsNumbers = [
    {value: 0, label: "0"},
    {value: 1, label: "1"},
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 13, label: "13" },
    { value: 14, label: "14" },
    { value: 15, label: "15" },
    { value: 16, label: "16" },
    { value: 17, label: "17" },
    { value: 18, label: "18" },
    { value: 19, label: "19" },
    { value: 20, label: "20" },
    {value: "more", label: "+20"}
]
export {
    AccompanimentCoFollowupProjectLocale,
    optionsSitutation,
    optionsNumbers
}