import React, { Component } from 'react'
import { connect } from 'react-redux'

import Page from '../../containers/global/Page/Page'

import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../redux/BeneficiaryRedux'
import {
  actions as BeneficiaryGroupActions,
  selectors as BeneficiaryGroupSelectors,
} from '../../redux/BeneficiaryGroupRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import ConsultantBeneficiaryCalendarContainer from '../../containers/consultant/ConsultantBeneficiaryCalendarContainer/ConsultantBeneficiaryCalendarContainer'
import './ConsultantBeneficiaryCalendarPage.scss'

const mapStateToProps = (state, props) => ({
  id: props.match.params.user_id,
  pathname: NavigationSelectors.pathname(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
  groups: BeneficiaryGroupSelectors.groups(state),
})

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  getBeneficiary: (id) => dispatch(BeneficiaryActions.getBeneficiary(id)),
  getCurrentUserGroups: () => dispatch(BeneficiaryGroupActions.getCurrentUserGroups()),

  resetGroups: () => dispatch(BeneficiaryGroupActions.resetGroups()),
})

class ConsultantBeneficiaryCalendarPage extends Component {
  componentDidMount() {
    // if (!/\/(profile|courses)$/.test(this.props.pathname)) {
    //   this.props.redirect(`${this.props.pathname}/courses`)
    // }

    this.props.getBeneficiary(this.props.id)
    this.props.getCurrentUserGroups()
  }

  componentWillUnmount() {
    this.props.resetGroups()
  }

  render() {
    const { beneficiary, groups } = this.props

    if (beneficiary === null || !groups) {
      return null
    }

    return <ConsultantBeneficiaryCalendarContainer beneficiary={beneficiary} groups={groups} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(ConsultantBeneficiaryCalendarPage))