/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { actions as NavigationActions } from '../../../redux/NavigationRedux';
import { connect } from 'react-redux';
import { selectors as UserSelectors } from '../../../redux/UserRedux';
import { deleteCourseById, duplicateCourse, getCourseById } from '../../../api/CourseApi';
import {
  getTemplatesCourses,
  getTemplatesOfficeCourses,
  getTemplatesMyTalentsCourses,
} from '../../../api/TemplateCourseApi';

import CourseRemove from '../CourseRemove/CourseRemove';
import CourseTemplateRemove from '../CourseTemplateRemove/CourseTemplateRemove';
import LoadingSpinner from '../../../components/atoms/LoadingSpinner/LoadingSpinner';
import ParcoursCard from '../../../components/organisms/ParcoursCard/ParcoursCard';
import { Toaster } from '../../../components/atoms/Toaster/Toaster';
import { useNavigation } from '../../../hooks/useNavigation';
import { useToaster } from '../../../hooks/useToaster';
import { round } from 'lodash';
import './CourseTemplateList.scss';

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
});

const CourseTemplateList = ({ scope, canAdd, canDelete, enablePreview }) => {
  const { pathname, redirect } = useNavigation();
  const user = useSelector(UserSelectors.user);
  const [courses, setCourses] = useState([]);
  const [officeCourses, setOfficeCourses] = useState([]);
  const [mytalentsCourses, setMytalentsCourses] = useState([]);
  const [consultantCourses, setConsultantCourses] = useState([]);
  const [isDeletingCourse, setIsDeletingCourse] = useState(false);
  const [deleteCourseId, setDeleteCourseId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { toasters, addToaster, removeToaster } = useToaster();

  const getConsultantCourses = () => {
    const { id } = user;
    setIsLoading(true);
    getTemplatesCourses(id).then((response) => {
      // setCourses(response.courses);
      setConsultantCourses(response.courses)
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const getOfficeCourses = () => {
    const { id } = user;
    setIsLoading(true);
    getTemplatesOfficeCourses(id).then((response) => {
      // setCourses(response.courses);
      setOfficeCourses(response.courses);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const getMyTalentsCourses = () => {
    setIsLoading(true);
    getTemplatesMyTalentsCourses().then((response) => {
      // setCourses(response.courses);
      setMytalentsCourses(response.courses)
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (scope === 'mytalents') {
      getMyTalentsCourses();
    } else if (scope === 'office') {
      getOfficeCourses();
    } else if (scope === 'consultant') {
      getConsultantCourses();
    }
  }, [scope]);

  useEffect(() => {
    if (scope === 'mytalents') {
      setCourses(mytalentsCourses);
    } else if (scope === 'office') {
      setCourses(officeCourses);
    } else if (scope === 'consultant') {
      setCourses(consultantCourses);
    }
  }, [scope, mytalentsCourses, officeCourses, consultantCourses]);

  const handleEditClick = ({ id, isAdmin }) => {
    if (scope === 'consultant') {
      redirect(`${pathname}/${id}`);
    } else if (scope === 'office' || (scope === 'mytalents' && !isAdmin)) {
      redirect(`/beneficiary/courses/${id}`);
    } else if (scope === 'mytalents' && !isAdmin) {
      redirect(`${pathname}/${id}`);
    }
  };

  const handleCloneClick = async ({ id }) => {
    try {
      const response = await getCourseById(id);
      const courseDuplicate = { ...response.course, consultant_id: user.profile_id };
      const duplicateResponse = await duplicateCourse(courseDuplicate);
      setConsultantCourses([...consultantCourses, duplicateResponse.course]);

      addToaster(
        'Duplication du parcours',
        `Le parcours ${duplicateResponse.course.title} a été dupliqué avec succès dans l'onglet "Mes parcours"`,
        'success',
      );
    } catch (error) {
      addToaster(
        'Duplication du parcours',
        'Une erreur est survenue lors de la duplication du cours',
        'error',
      );
    }
  };

  const handleRemoveClick = ({ id }) => {
    setIsDeletingCourse(true);
    setDeleteCourseId(id);
  };

  const handleOnCloseRemoveCourse = () => {
    setIsDeletingCourse(false);
  };

  const handleRemove = async () => {
    try {
      await deleteCourseById(deleteCourseId);
      const updatedCourses = courses.filter((course) => course.id !== deleteCourseId);
      setCourses(updatedCourses);
      setIsDeletingCourse(false);
      addToaster('Suppression du parcours', 'Le parcours a été supprimé avec succès', 'success');
    } catch (error) {
      addToaster(
        'Suppression du parcours',
        'Une erreur est survenue lors de la suppression du parcours',
        'error',
      );
    }
  };

  const handlePreviewClick = ({ id }) => {
    redirect(`/beneficiary/courses/${id}`);
  };

  const handleClosePopin = () => {
    redirect(pathname.replace(/\/courses\/.+$/, '/courses'));
  };

  const handleTogglePublishClick = (template) => {
    template.template_published = !template.template_published;
  };

  const removePopin = () => {
    if (!/\/\d+\/remove/.test(pathname)) {
      return null;
    }
    return <CourseTemplateRemove onClose={handleClosePopin} />;
  };

  const hasPermission = (template_owner) => {
    const { role, profile_id } = user;

    switch (role) {
      case 'admin':
        return true;
      case 'consultant':
        return template_owner?.consultant_id === profile_id;
      default:
        return false;
    }
  };

  const templateCards = () => {
    const cards = courses?.map((template) => {
      const { id, title, cover_url, steps, template_published, percentage_done } = template;
      const isAdmin = user.role === 'admin';

      const modulesDonePercentage = round((template.nb_modules_done / template.nb_modules) * 100);
      return (
        <ParcoursCard
          course={{
            id,
            coverUrl: cover_url,
            title,
            stepsCount: template.nb_steps,
            modulesCount: template.nb_modules,
            published: template_published,
            percentage_done: modulesDonePercentage,
            isEditable: scope !== 'office' && scope !== 'mytalents',
            isRemovable: true,
            canPreview: false,
            isCloneable: true,
            isPublishable: hasPermission(template),
            canPublish: scope === 'office',
            canDelete: scope === 'consultant' || (scope === 'mytalents' && isAdmin) ? true : false,
          }}
          key={id}
          onCloneClick={handleCloneClick}
          onEditClick={handleEditClick}
          onRemoveClick={handleRemoveClick}
          onPreviewClick={handlePreviewClick}
          onTogglePublishClick={() => handleTogglePublishClick(template)}
        />
      );
    });

    return (
      <div className="course-templates">
        {isLoading && <LoadingSpinner />}
        {courses && courses.length > 0 && cards}
        {!isLoading && courses && courses.length === 0 && <p>Aucun parcours enregistré</p>}
      </div>
    );
  };

  return (
    <div className="course-template-list">
      {templateCards()}
      {removePopin()}
      {isDeletingCourse && (
        <CourseRemove
          open={isDeletingCourse}
          onClose={handleOnCloseRemoveCourse}
          handleRemove={handleRemove}
        />
      )}
      <div className="notification-container">
        {toasters.map((toaster) => (
          <Toaster
            key={toaster.id}
            title={toaster.title}
            message={toaster.message}
            type={toaster.type}
            onClose={() => removeToaster(toaster.id)}
          />
        ))}
      </div>
    </div>
  );
};

CourseTemplateList.propTypes = {
  canAdd: PropTypes.bool,
  enablePreview: PropTypes.bool,
  filters: PropTypes.object,
};

CourseTemplateList.defaultProps = {
  canAdd: true,
  enablePreview: false,
  filters: {},
};

export default connect(null, mapDispatchToProps)(CourseTemplateList);
