import { getEducationOptions, getAccompanimentTypeOptions, getSocioProfessionalCategoriesOptions, getActivityOptions, getAccompanimentDurationTypesOptions, getFinancerStatesOptions, getFinancerTypesOptions, getFinancerOPACIFTypesOptions, getAppraisalTypesOptions, getAppraisalAvailabilitiesOptions, getSituationOptions } from "./OptionsConst"

export const formatBeneficiary = (beneficiaryToFormat) => {
    let formattedBeneficiary = { ...beneficiaryToFormat }

    formattedBeneficiary = {
      ...formattedBeneficiary,
      birth_date: formattedBeneficiary.birth_date != null ? formattedBeneficiary.birth_date : '',
      cursus_formation:
        formattedBeneficiary.cursus_formation != null
          ? formattedBeneficiary.cursus_formation
          : getEducationOptions()[0].value,
      cursus_professional_situation:
        formattedBeneficiary.cursus_professional_situation != null
          ? formattedBeneficiary.cursus_professional_situation
          : getSituationOptions()[0].value,
      accompaniment_type:
        formattedBeneficiary.accompaniment_type != null
          ? formattedBeneficiary.accompaniment_type
          : getAccompanimentTypeOptions()[0].value,

      image_url: formattedBeneficiary.image_url != null ? formattedBeneficiary.image_url : '',
      phone_mobile:
        formattedBeneficiary.phone_mobile != null ? formattedBeneficiary.phone_mobile : '',
      address: formattedBeneficiary.address != null ? formattedBeneficiary.address : '',
      zip_code: formattedBeneficiary.zip_code != null ? formattedBeneficiary.zip_code : '',
      city: formattedBeneficiary.city != null ? formattedBeneficiary.city : '',
      phone_home: formattedBeneficiary.phone_home != null ? formattedBeneficiary.phone_home : '',
      cursus_socio_professional_category:
        formattedBeneficiary.cursus_socio_professional_category != null
          ? formattedBeneficiary.cursus_socio_professional_category
          : getSocioProfessionalCategoriesOptions()[0].value,
      diploma_name:
        formattedBeneficiary.diploma_name != null ? formattedBeneficiary.diploma_name : '',
      cursus_function:
        formattedBeneficiary.cursus_function != null ? formattedBeneficiary.cursus_function : '',
      cursus_company_activity_sector:
        formattedBeneficiary.cursus_company_activity_sector != null
          ? formattedBeneficiary.cursus_company_activity_sector
          : getActivityOptions()[0].value,
      accompaniment_duration:
        formattedBeneficiary.accompaniment_duration != null
          ? formattedBeneficiary.accompaniment_duration
          : 0,
      accompaniment_duration_type:
        formattedBeneficiary.accompaniment_duration_type != null
          ? formattedBeneficiary.accompaniment_duration_type
          : getAccompanimentDurationTypesOptions()[0].value,
      accompaniment_survey_count:
        formattedBeneficiary.accompaniment_survey_count != null
          ? formattedBeneficiary.accompaniment_survey_count
          : 0,
      financer_state:
        formattedBeneficiary.financer_state != null
          ? formattedBeneficiary.financer_state
          : getFinancerStatesOptions()[0].value,
      financer_type:
        formattedBeneficiary.financer_type != null
          ? formattedBeneficiary.financer_type
          : getFinancerTypesOptions()[0].value,
      financer_opacif_type:
        formattedBeneficiary.financer_opacif_type != null
          ? formattedBeneficiary.financer_opacif_type
          : getFinancerOPACIFTypesOptions()[0].value,
      financer_name:
        formattedBeneficiary.financer_name != null ? formattedBeneficiary.financer_name : '',
      financer_support_request_file:
        formattedBeneficiary.financer_support_request_file != null
          ? formattedBeneficiary.financer_support_request_file
          : '',
      financer_support_agreement_file:
        formattedBeneficiary.financer_support_agreement_file != null
          ? formattedBeneficiary.financer_support_agreement_file
          : '',
      financial_ht:
        formattedBeneficiary.financial_ht != null ? formattedBeneficiary.financial_ht : 0,
      financial_tva:
        formattedBeneficiary.financial_tva != null ? formattedBeneficiary.financial_tva : 20,
      cursus_handicap_status:
        formattedBeneficiary.cursus_handicap_status != null
          ? formattedBeneficiary.cursus_handicap_status
          : 'no',
      cursus_handicap_file:
        formattedBeneficiary.cursus_handicap_file != null
          ? formattedBeneficiary.cursus_handicap_file
          : '',
      cursus_cv_file:
        formattedBeneficiary.cursus_cv_file != null ? formattedBeneficiary.cursus_cv_file : '',
      source: formattedBeneficiary.source != null ? formattedBeneficiary.source : '',
    }

    // Manage dates
    if (formattedBeneficiary.birth_date === null || formattedBeneficiary.birth_date === '') {
      delete formattedBeneficiary.birth_date
    }

    if (
      formattedBeneficiary.accompaniment_start_date === null ||
      formattedBeneficiary.accompaniment_start_date === ''
    ) {
      delete formattedBeneficiary.accompaniment_start_date
    }

    if (
      formattedBeneficiary.accompaniment_end_date === null ||
      formattedBeneficiary.accompaniment_end_date === ''
    ) {
      delete formattedBeneficiary.accompaniment_end_date
    }

    // Manage Accompaniment linked props : accompaniment_appraisal_type & accompaniment_appraisal_availability
    if (formattedBeneficiary.accompaniment_type === 'appraisal') {
      formattedBeneficiary = {
        ...formattedBeneficiary,
        accompaniment_appraisal_type:
          formattedBeneficiary.accompaniment_appraisal_type != null
            ? formattedBeneficiary.accompaniment_appraisal_type
            : getAppraisalTypesOptions()[0].value,
        accompaniment_appraisal_availability:
          formattedBeneficiary.accompaniment_appraisal_availability != null
            ? formattedBeneficiary.accompaniment_appraisal_availability
            : getAppraisalAvailabilitiesOptions()[0].value,
      }
    } else {
      formattedBeneficiary = {
        ...formattedBeneficiary,
        accompaniment_appraisal_type: null,
        accompaniment_appraisal_availability: null,
      }
    }

    // Manage group
    if (Number.parseInt(formattedBeneficiary.group_id, 10) === 0) {
      formattedBeneficiary = {
        ...formattedBeneficiary,
        group_id: null,
      }
    }

    // Manage cursus non required field
    if (
      !formattedBeneficiary.cursus_company_size ||
      formattedBeneficiary.cursus_company_size === ''
    ) {
      formattedBeneficiary = {
        ...formattedBeneficiary,
        cursus_company_size: null,
      }
    }

    if (
      !formattedBeneficiary.cursus_non_company_activity_sector ||
      formattedBeneficiary.cursus_non_company_activity_sector === ''
    ) {
      formattedBeneficiary = {
        ...formattedBeneficiary,
        cursus_non_company_activity_sector: null,
      }
    }

    return formattedBeneficiary
  }