import React, { Component } from 'react'
import PropTypes from 'prop-types'

import AvatarCircle from '../../atoms/AvatarCircle/AvatarCircle'
import Icon from '../../atoms/Icon/Icon'
import IconChevron from '../../atoms/IconChevron/IconChevron'
import './SharedNoteHeader.scss'
export default class SharedNoteHeader extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    photoUrl: PropTypes.string,
    onClick: PropTypes.func.isRequired,
  }

  static defaultProps = {
    photoUrl: '',
  }

  handleClick = () => {
    this.props.onClick({ id: this.props.id })
  }

  render() {
    const { open, label, photoUrl } = this.props

    return (
      <div className="shared-note-header" onClick={this.handleClick}>
        {photoUrl && <AvatarCircle photoUrl={photoUrl} />}
        {!photoUrl && <Icon icon={Icon.icon.Pencil} color={Icon.color.Accent} />}
        <p className="label">{label}</p>
        <IconChevron open={open} />
      </div>
    )
  }
}
