import React, { useState, useEffect } from 'react';
import AddAppointmentPopin from '../../popins/appointment/AddAppointmentPopin/AddAppointmentPopin';
import EditAppointmentPopin from '../../popins/appointment/EditAppointmentPopin/EditAppointmentPopin';

import { updateModule, deleteModule } from '../../../api/CourseApi';
// import { getUser } from "../../../api/UserApi";

export const EditModuleAppointmentPopin = ({
  moduleProps,
  isOpenModal,
  onClose,
  setNewModule,
  setDeletedModule,
  setToaster,
  talent,
  user,
}) => {
  const [module, setModule] = useState(moduleProps);
  const [isNewAppointment, setIsNewModuleAppointment] = useState(true);


  const getNewAppointment = (newModule) => {
    if (newModule) {
      const data = { title: newModule.title, appointment_id: newModule.id, description: newModule.public_notes, rdv_at: newModule.rdv_at, rdv_to: newModule.rdv_to, order: module.data[0].order ?? 0, id: module?.data[0]?.id ?? 0, done: module.done };
      module.data[0] = data;
      module.title = newModule?.title;
      updateModule(module).then((response) => {
        setNewModule(response.module);
      });
    }
  };
  useEffect(() => {
    setModule(moduleProps);
    if (moduleProps.data && moduleProps.data.length > 0) {
      moduleProps.data[0].rdv_at !== null && moduleProps.data[0].rdv_to !== null
        ? setIsNewModuleAppointment(false)
        : setIsNewModuleAppointment(true);
    } else {
      setIsNewModuleAppointment(true);
    }
  }, [moduleProps]);

  const getAppointment = (newModule) => {
    setNewModule(newModule);
  };

  const getDeleteAppointment = (moduleDeleted) => {
    deleteModule(moduleDeleted.id);
    setDeletedModule(moduleDeleted);
  };

  const getToaster = (toaster) => {
  };
  return (
    <>
      {module && talent && isNewAppointment ? (
        <>
          <AddAppointmentPopin
            open={isOpenModal}
            onClose={onClose}
            setAppointment={getNewAppointment}
            isModule={true}
            talent={talent}
            user={user}
          />
        </>
      ) : (
        <>
          <EditAppointmentPopin
            open={isOpenModal}
            onClose={onClose}
            appointment={moduleProps?.data[0]}
            setEditAppointment={getAppointment}
            setDeletedAppointment={getDeleteAppointment}
            onCloseDeleteModal={onClose}
            isModule={true}
            module={moduleProps}
            setToaster={getToaster}
            talent={talent}
          />
        </>
      )}
    </>
  );
};
