export const handleParsingUrl = (input, setNewAppointment) => {
    const { value } = input;
    const newInputValue = value;

    const zoomRegex = /zoom.us/;
    const teamsRegex = /teams\.microsoft\.com/;
    const meetRegex = /meet\.google\.com/;
    const gotoRegex = /gotomeet\.me/;
    const indeedRegex = /indeed\.com\/video/;

    if (zoomRegex.test(newInputValue)) {
        setNewAppointment((prevAppointment) => ({
            ...prevAppointment,
            provider_description: 'zoom',
            join_url: value
        }));
    } else if (teamsRegex.test(newInputValue)) {
        setNewAppointment((prevAppointment) => ({
            ...prevAppointment,
            provider_description: 'ms_teams',
            join_url: value
        }));
    } else if (meetRegex.test(newInputValue)) {
        setNewAppointment((prevAppointment) => ({
            ...prevAppointment,
            provider_description: 'google_meet',
            join_url: value
        }));
    } else if (gotoRegex.test(newInputValue)) {
        setNewAppointment((prevAppointment) => ({
            ...prevAppointment,
            provider_description: 'go_to',
            join_url: value
        }));
    } else if (indeedRegex.test(newInputValue)) {
        setNewAppointment((prevAppointment) => ({
            ...prevAppointment,
            provider_description: 'indeed',
            join_url: value
        }));
    }
};