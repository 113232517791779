/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from '../components/atoms/LoadingSpinner/LoadingSpinner'
import LeavePopin from '../containers/global/LeavePopin/LeavePopin'
import NotificationList from '../containers/global/NotificationList/NotificationList'
import NavigationRouter from '../navigation/NavigationRouter'
import {
  actions as AppActions,
  getLeaveCallback,
  selectors as AppSelectors,
} from '../redux/AppRedux'

import {selectors as ColorThemeSelectors } from "../redux/ColorThemeRedux";
import {selectors as UserSelectors} from "../redux/UserRedux"

import '../stylesheets/main.css'
import '../stylesheets/main.scss'

import { usePortalCustomization } from '../hooks/usePortalCustomization'
import { getThemeColor } from '../api/officeApi'

const ConnectedApp = () => {
  const dispatch = useDispatch()
  const started = useSelector(AppSelectors.started)
  const user = useSelector(UserSelectors.user);
  const loading = useSelector(AppSelectors.loading)
  const themeStore = useSelector(ColorThemeSelectors.getTheme);
  const [office, setOffice] = useState()
  const [theme, setTheme] = useState()

  // eslint-disable-next-line no-unused-vars
  const _ = usePortalCustomization({ office: null })

  const handleWindowResize = useCallback(() => {
    dispatch(AppActions.updateSize())
  }, [dispatch])

  useEffect(() => {
    if(user) {
      if(user.role === "manager") {
        setOffice(user.office_id);
        getThemeColor(user.office_id).then((response) => {
          setTheme(response.theme_color.theme_color)
        })
      }
    }
  }, [user])

  // useEffect(() => {
  //   if(user && user.role === "manager") {
      // getThemeColor(user.office_id).then((response) => {
      //   setTheme(response.theme_color.theme_color)
      // })
  //   }
  //   else {
  //     setTheme(themeTest)
  //   }

  // }, [themeTest])
  

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [handleWindowResize])

  const cancelLeave = () => {
    const leaveCallback = getLeaveCallback()

    if (leaveCallback !== null) {
      leaveCallback(false)
    }

    dispatch(AppActions.hideLeavePopin())
  }

  const confirmLeave = () => {
    const leaveCallback = getLeaveCallback()

    if (leaveCallback !== null) {
      leaveCallback(true)
    }

    dispatch(AppActions.hideLeavePopin())
  }

  if (!started) {
    return null
  }
  return (
    <div className={"App"} data-theme={themeStore ? themeStore : "mytalents"}>
      <NavigationRouter />
      <NotificationList />
      {/* <LeavePopin cancel={cancelLeave} confirm={confirmLeave} /> */}

      {loading && <LoadingSpinner />}
    </div>
  )
}
export default ConnectedApp
