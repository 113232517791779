/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import CircleButton from '../../../../atoms/CircleButton/CircleButton';
import classNames from 'classnames';
// import IconRessource from "../../../../atoms/IconRessource/IconRessource";
import { connect } from 'react-redux';
import { actions as NavigationActions } from '../../../../../redux/NavigationRedux';
import { useLocation } from 'react-router';
import './DocumentToReturnRessource.scss';

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
});

const DocumentToReturnRessource = ({ ressource, step, moduleContainer, redirect }) => {
  const location = useLocation();
  const currentURL = location.pathname;
  const [isDisabledButton, setIsDisabledButton] = useState(false);

  // dowload document
  const downloadDocument = (url) => {
    const button = document.querySelector('.circle-button');
    button.download = url;
  };

  const download = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.target = "_blank";
    link.click();
    // fetch(url)
    //   .then(response => response.blob())
    //   .then(blob => {
    //     const link = document.createElement("a");
    //     link.href = URL.createObjectURL(blob);
    //     link.download = filename;
    //     link.click();
    // })
    // .catch(error => console.error('Erreur de fetch :', error));
  }

  // upload document

  // if locked
  const disabledButton = () => {
    if (ressource.done === 0 || ressource.done === null) {
      setIsDisabledButton(true);
    }
  };

  // Reduce url_uploaded
  const reduceUrl = (url) => {
    if (url.length > 25) {
      const newUrl = url.slice(0, 15) + '...' + url.slice(-7);
      return newUrl;
    } else {
      return url;
    }
  };
  // Get file name
  const getFileName = (fileName) => {
    return fileName.split('/').pop();
  };

  const openRessource = () => {
    redirect(`${currentURL}/step/${step}/modules/${moduleContainer}/module/${ressource.module_id}`);
  };

  useEffect(() => {
    disabledButton();
  }, [ressource]);

  return (
    <div className="document-to-return-ressource">
      <h2 >{ressource.title}</h2>
      <div className="document-to-return-ressource-all-documents" >
        {ressource.url && (
          <div className="document-to-download">
            <span>Document à télécharger</span>
            <div className="document-url">
              <p  >
                {reduceUrl(ressource.title)}
              </p>
              {/* <CircleButton
                icon={'download'}
                disabled={ressource.url ? false : true}
                onClick={(e) => {
                  download(ressource.url, ressource.title);
                  e.stopPropagation();
                }}
              /> */}
            </div>
          </div>
        )}
        <div className="document-to-upload">
          <span>Document à compléter</span>
          <div className="document-url">
            {ressource.url_return === null ? (
              <p className={classNames('waiting-document')}>En attente de document</p>
            ) : (
              <p href={ressource.url_return} target="_blank" download>{getFileName(ressource.url_return)}</p>
            )}
            {/* <CircleButton onClick={(e) => {
                  download(ressource.url_return, ressource.url_return);
                  e.stopPropagation();
                }} icon={'upload'} disabled={ressource.url_return === null} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(DocumentToReturnRessource);
