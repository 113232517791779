/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors as UserSelectors } from '../../redux/UserRedux';
import { selectors as NavigationSelectors } from '../../redux/NavigationRedux';

import { addTalentGroup, deleteTalentGroup, getGroup, updateGroup } from '../../api/groupsApi';
import { addTalentMission, deleteTalentMission } from '../../api/MissionsApi';

import { AddGroupPopin } from '../../containers/groups/AddGroupPopin/AddGroupPopin';
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout';
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside';
import LineMenu from '../../components/organisms/LineMenu/LineMenu';
import ManagerPageAside from '../../containers/manager/ManagerPageAside/ManagerPageAside';
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout';
import { GroupContainer } from '../../containers/groups/GroupContainer/GroupContainer';
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton';
import { Toaster } from '../../components/atoms/Toaster/Toaster';
import { useToaster } from '../../hooks/useToaster';
import './ConsultantGroupPage.scss';

export const ConsultantGroupPage = () => {
  const user = useSelector(UserSelectors.user);
  const path = useSelector(NavigationSelectors.lastParam);
  const [showAddModal, setShowAddModal] = useState(false);
  const [group, setGroup] = useState({});
  const [groupUpdated, setGroupUpdated] = useState();
  const [isUpdatedGroup, setIsUpdatedGroup] = useState(false);
  const [tab, setTab] = useState('info');
  const [members, setMembers] = useState([]);
  const [newMembers, setNewMembers] = useState([]);
  const [, setOldMembers] = useState([]);
  const { toasters, addToaster, removeToaster } = useToaster();

  useEffect(() => {
    if (newMembers) {
      setIsUpdatedGroup(true);
    }
  }, [newMembers]);

  useEffect(() => {
    if (groupUpdated) {
      const { client_id, mission_id } = groupUpdated;

      if (client_id && !mission_id) {
        setIsUpdatedGroup(false);
      } else if (client_id && mission_id) {
        updateGroup(groupUpdated.id, groupUpdated);
      }
    }
  }, [groupUpdated]);

  useEffect(() => {
    reloadDataMembers();
  }, []);

  const reloadDataMembers = () => {
    getGroup(+path).then((response) => {
      setGroup(response.group);
      setGroupUpdated(response.group);
      if (response.group.group_users) {
        setMembers(response.group.group_users);
        setOldMembers(response.group.group_users);
      }
    });
  };

  const handleUpdateGroup = () => {
    const { id, client_id, mission_id } = groupUpdated;

    updateGroup(id, groupUpdated)
      .then(() => {
        addToaster(
          'Groupe mis à jour',
          `Le groupe ${groupUpdated.name} a été mis à jour avec succès`,
          'success',
        );
      })
      .catch(() => {
        addToaster(
          'Erreur',
          `Une erreur est survenue lors de la mise à jour du groupe ${groupUpdated.name}`,
          'error',
        );
      });
    setIsUpdatedGroup(false);

    // Il y a que des nouveaux talents
    if (!members && newMembers.length > 0) {
      newMembers.map((talent) => handleAddTalentGroup(groupUpdated.id, talent.id));
      if (client_id && mission_id) {
        newMembers.map((talent) => handleAddTalentMission(mission_id, talent.id));
      }
    }

    // Il y a des talents + des nouveaux talents
    else if (members && newMembers.length > 0) {
      // const new_talents = newMembers.filter(member => !group.group_users.some(talent => talent.id === member.id));
      newMembers.map((talent) => handleAddTalentGroup(groupUpdated.id, talent.id));
      if ((client_id, mission_id)) {
        newMembers.map((talent) => handleAddTalentMission(mission_id, talent.id));
      }
    } else if (members && mission_id !== group.mission_id) {
      members.map((talent) => handleAddTalentMission(mission_id, talent.id));
      members.map((talent) => deleteTalentMission(group.mission_id, talent.id));
    }

    // Il n'y a pas de talents, ni de nouveaux talents, ni des nouvelle mission
    else return null;
  };

  const handleAddTalentMission = (mission_id, talent_id) => {
    const new_talent = { user_id: talent_id };
    addTalentMission(mission_id, new_talent)
      .then(() => {
        addToaster(
          'Talent ajouté',
          `Le talent à été ajouté au groupe et à la mission avec succès`,
          'success',
        );
      })
      .catch(() => {
        addToaster(
          'Erreur',
          `Une erreur est survenue lors de l'ajout du talent au groupe et à la mission`,
          'error',
        );
      });
  };

  const handleAddTalentGroup = (group_id, talent_id) => {
    const new_talent = { user_id: talent_id };
    addTalentGroup(group_id, new_talent)
      .then(() => {
        addToaster('Talent ajouté', `Le talent à été ajouté au groupe avec succès`, 'success');
      })
      .catch(() => {
        addToaster(
          'Erreur',
          `Une erreur est survenue lors de l'ajout du talent au groupe`,
          'error',
        );
      });
  };

  const handleDeleteTalentGroup = (talent) => {
    const filterMembers = members.filter((m) => m.id !== talent.id);
    setMembers(filterMembers);
    deleteTalentGroup(group.id, talent.id)
      .then(() => {
        addToaster('Talent retiré', `Le talent à été retiré du groupe avec succès`, 'success');
      })
      .catch(() => {
        addToaster(
          'Erreur',
          `Une erreur est survenue lors du retrait du talent de la mission`,
          'error',
        );
      });

    const mission_id = groupUpdated.mission_id;
    if (mission_id) {
      handleDeleteTalentMission(mission_id, talent.id);
    }
  };

  const handleDeleteTalentMission = (id, talent_id) => {
    deleteTalentMission(id, talent_id)
      .then(() => {
        addToaster(
          'Talent retiré',
          `Le talent à été retiré du groupe et de la mission avec succès`,
          'success',
        );
      })
      .catch(() => {
        addToaster(
          'Erreur',
          `Une erreur est survenue lors du retrait du talent du groupe et de la mission`,
          'error',
        );
      });
  };

  const handleInputChange = (input) => {
    const { id, value } = input;
    id === 'name' && setGroupUpdated({ ...groupUpdated, name: value });
    setIsUpdatedGroup(true);
  };

  const handleDropdownChange = (dropdown) => {
    const { id, value } = dropdown;
    setGroupUpdated({ ...groupUpdated, [id]: value.value });
    setIsUpdatedGroup(true);

    if (id === 'client_id' && !value.value) {
      setGroupUpdated({ ...groupUpdated, client_id: null, mission_id: null });
    }
  };

  const getNewMembers = (newMembers) => {
    setMembers((prevMembers) => [...prevMembers, ...newMembers]);
    setNewMembers((prevMembers) => [...prevMembers, ...newMembers]);

    setShowAddModal(false);
  };

  const handleTabOnChange = (tab) => {
    setTab(tab);
  };

  const renderGroupParcours = () => {
    return <p>Voir les parcours du groupe</p>;
  };

  const renderGroupInfos = () => {
    return (
      <div className="consultant-group-page">
        {isUpdatedGroup && (
          <div className="consultant-group-page-action">
            <PrimaryButton
              id={'save'}
              label={'Sauvegarder les changements'}
              onClick={handleUpdateGroup}
            />
          </div>
        )}
        {groupUpdated && (
          <GroupContainer
            user={user}
            group={groupUpdated}
            members={members ? members : []}
            deleteMember={handleDeleteTalentGroup}
            showModalAddMember={() => setShowAddModal(true)}
            handleInputChange={handleInputChange}
            handleDropdownChange={handleDropdownChange}
            reloadMembers={reloadDataMembers}
          />
        )}
        {showAddModal && (
          <AddGroupPopin
            open={showAddModal}
            onClose={() => setShowAddModal(false)}
            user={user}
            setMembers={getNewMembers}
            group_id={group.id}
          />
        )}
      </div>
    );
  };

  const renderGroupCV = () => {
    return <p>Voir les CV du groupe</p>;
  };

  const renderContent = () => {
    if (tab === 'parcours') return renderGroupParcours();
    if (tab === 'info') return renderGroupInfos();
    if (tab === 'cv') return renderGroupCV();
  };

  const renderTabs = () => {
    // const tabs = [{ id: "parcours", label: "Parcours suivis" }, { id: "info", label: "Informations du groupe" }]
    const tabs = [{ id: 'info', label: 'Informations du groupe' }];
    return <LineMenu value={tab} links={tabs} onChange={handleTabOnChange} />;
  };

  const aside = user.role === 'consultant' ? <ConsultantPageAside /> : <ManagerPageAside />;
  const content = <TabbedContentLayout header={renderTabs()} content={renderContent()} />;

  return (
    <div className="page">
      <div>
        <LeftAsideLayout aside={aside} content={content} />
      </div>
      <div className="notification-list">
        {toasters.map((toaster) => (
          <Toaster
            key={toaster.id}
            title={toaster.title}
            message={toaster.message}
            type={toaster.type}
            onClose={() => removeToaster(toaster.id)}
          />
        ))}
      </div>
    </div>
  );
};
