/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { selectors as UserSelectors } from '../../../redux/UserRedux';
import { actions as NavigationActions } from '../../../redux/NavigationRedux';
import { deleteModule, duplicateModule, updateModule } from '../../../api/CourseApi';
import { EditModulePopin } from '../../../containers/course/EditModulePopin/EditModulePopin';
import Icon from '../../atoms/Icon/Icon';
import IconRessource from '../../atoms/IconRessource/IconRessource';
import { RemoveRessource } from '../../../containers/course/RemoveRessource/RemoveRessource';
import { useLocation } from 'react-router';
import { ModuleTypes, DocumentSubTypes } from './ModuleResourceRow.states';
import './ModuleResourceRow.scss';
import { EditModuleAppointmentPopin } from '../../../containers/course/EditModuleAppointmentPopin/EditModuleAppointmentPopin';
import moment from 'moment';
import 'moment/locale/fr';
import { EditModuleNoAttributeCoursePopin } from '../../../containers/course/EditModuleNoAttributeCoursePopin/EditModuleNoAttributeCoursePopin';
import CentralTestModuleConsultant from '../CentralTestModuleConsultant/CentralTestModuleConsultant';
import { getUser } from '../../../api/userAPI';
import { getAppointmentById } from '../../../api/AppointmentsApi';

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
});

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
});

const ModuleResourceRow = ({
  user,
  moduleProps,
  actions,
  redirect,
  getRessourceDeleted,
  getNewModuleDuplicate,
  getRessourcesPublished,
  getRessourceUpdated,
  beneficiaryId,
  isAttruteCourse,
  setToaster,
}) => {
  const [module, setModule] = useState();
  const [isNewModule, setIsNewModule] = useState();
  const [type, setType] = useState({ label: '', value: '' });
  const [talent, setTalent] = useState(null);
  const [appointment, setAppointment] = useState(false);

  const [isOpenButtonActions, setIsOpenButtonActions] = useState(false);
  const [isOpenModalEditModule, setIsOpenModalEditModule] = useState(false);
  const [isOpenModalEditAppointmentModule, setIsOpenEditAppointmentModule] = useState(false);
  const [isOpenModalRemoveModule, setIsOpenModalRemoveModule] = useState(false);
  const [
    isOpenModalEditAppointmentNoAttributeCourse,
    setIsOpenModalEditAppointmentNoAttributeCourse,
  ] = useState(false);

  const location = useLocation();

  // use effects
  useEffect(() => {
    setModule(moduleProps);
    if (moduleProps.type === 'appointmentEvo') {
      getAppointmentFTD(moduleProps.data[0]);
    }
  }, []);

  useEffect(() => {
    module && getCurrentType();
  }, [module]);

  useEffect(() => {
    module && type && handleIsNewModule();
  }, [type, module]);

  useEffect(() => {
    getUser(beneficiaryId).then((response) => {
      const user = response.user;
      setTalent({ id: user.id, first_name: user.first_name, last_name: user.last_name, email: user.email });
    });
  }, [beneficiaryId]);

  // methods

  const getAppointmentFTD = async (mod) => {
    const appointment = await getAppointmentById(mod?.appointment_id).then((response) => {setAppointment(response.appointment); return response.appointment;});
    return appointment;
  };

  const getCurrentType = () => {
    const { type } = module;

    if (type === 'document') {
      const subtype = module.data[0]?.type ?? null;
      const typeName = DocumentSubTypes[subtype] || 'Type inconnu';
      setType({ label: typeName, value: subtype });
    } else {
      const typeName = ModuleTypes[type] || 'Type inconnu';
      setType({ label: typeName, value: type });
    }
  };

  const handleIsNewModule = () => {
    const { title } = module;

    if (module.appointment_type || module.type === 'appointmentEvo') {
      setIsNewModule(!title);
    } else {
      const submodule = module.data[0];
      if (!submodule) return;
      const { title_submodule, questions } = submodule;
      if (module.type !== 'form') {
        setIsNewModule(!(title || title_submodule));
      } else {
        setIsNewModule(!(title || questions));
      }
    }
  };

  const openModalEditResource = () => {
    const currentURL = location.pathname;
    const id = module.id;

    if (type.value === 'form') {
      const questions_id = module.data[0]?.id;

      redirect(`${currentURL}/module/${id}/form/${questions_id}`);
    } else if (type.value === 'appointmentEvo') {
      isAttruteCourse
        ? setIsOpenEditAppointmentModule(true)
        : setIsOpenModalEditAppointmentNoAttributeCourse(true);
    } else {
      setIsOpenModalEditModule(true);
    }
  };

  const handleActionRessource = (action) => {
    const { type } = module;
    const submodule = module.data[0];
    const { title, description, url, module_form_questions } = submodule;

    const isEmptyModule =
      (type === 'document' && !title && !url) ||
      (type === 'form' && module_form_questions.length === 0) ||
      ((type === 'feedback' || type === 'synthesis' || type === 'document_to_return') &&
        !title &&
        !description);

    const isRemoveModal =
      action === 'remove' ? (isEmptyModule ? (handleDeleteRessource(), false) : true) : false;
    action === 'edit' && openModalEditResource();
    action === 'duplicate' && handleDuplicateModule();
    action === 'lock' && handleToggleModule();
    action === 'publish' && handlePublishModule();

    setIsOpenModalRemoveModule(isRemoveModal);
  };

  const handleToggleModule = () => {
    const updatedLockedValue = module.locked === 0 ? 1 : 0;

    const done = (updatedLockedValue === 1 && module.order !== 1 && (module.done === 1 || module.done === 0)) ? 0 : (module.done === 0) ? 1 : module.done;
    const updatedModule = { ...module, locked: updatedLockedValue, done };
    setModule(updatedModule);
    updateModule(updatedModule);
  };

  const getNewModule = (new_module) => {
    setModule(new_module);
    getRessourceUpdated(new_module);
    setIsOpenModalEditModule(false);
    setIsOpenEditAppointmentModule(false);
    window.location.reload();
  };

  const getDeletedAppointment = (deleted_appointment) => {
    getRessourceDeleted(deleted_appointment.id);
  };

  ////////// methods action on modules ///////////
  // DUPLICATE MODULE
  const handleDuplicateModule = () => {
    const moduleDuplicate = { ...module, consultant_id: user.profile_id };
    duplicateModule(moduleDuplicate).then((response) => {
      response.module.order = module.order + 1;
      getNewModuleDuplicate(response.module);
      setToaster({
        title: 'Ressource dupliqueé',
        message: 'La ressource à été dupliquée avec succès',
        type: 'success',
      });
    });
  };

  // PUBLISH MODULE
  const handlePublishModule = () => {
    const updatedModule = { ...module, template_published: !module.template_published };
    setModule(updatedModule);
    updateModule(updatedModule).then((response) => {
      const message = updatedModule.template_published ? 'Ressource publiée' : 'Ressource dépubliée';
      const messageDetail = updatedModule.template_published ? 'La ressource a été publiée avec succès' : 'La ressource a été dépubliée avec succès';
      setToaster({
        title: message,
        message: messageDetail,
        type: 'success',
      });
      if (updatedModule.template_published) {
        getRessourcesPublished(response.module);
      }
    });
  };
  
  // DELETE MODULE
  const handleDeleteRessource = () => {
    deleteModule(module.id).then(() => {
      setToaster({
        title: 'Ressource supprimée',
        message: 'La ressource à été supprimée avec succès',
        type: 'success',
      });
    });
    getRessourceDeleted(module.id);
    setIsOpenModalRemoveModule(false);
  };

  ////////// specifics components ///////////
  const renderDocumentToReturn = () => {
    const url = module.data[0]?.url || module.data[0]?.upload_name;
    const { title } = module.data[0];
    return (
      <>
        {url ? (
          <a href={url} target="_blank" download>
            {title}
          </a>
        ) : (
          'Le talent doit soumettre le document demandé.'
        )}
      </>
    );
  };

  const renderFeedback = () => {
    const consultant_notes = module.data[0]?.consultant_notes;

    return consultant_notes && consultant_notes.length > 1000 ? (
      <p>{consultant_notes.slice(0, 1000) + '...'}</p>
    ) : (
      <p>{consultant_notes}</p>
    );
  };

  const renderFile = () => {
    const { url, title } = module.data[0];
    return (
      <a href={url} target="_blank" download>
        {' '}
        {title}
      </a>
    );
  };

  const renderForm = () => {
    const { module_form_questions } = module.data[0];
    return (
      <p>
        {module_form_questions.length > 1
          ? module_form_questions.length + ' questions'
          : module_form_questions.length + ' question'}
      </p>
    );
  };

  const renderLink = () => {
    const url = module.data[0].url;
    return (
      <a href={url} target="_blank">
        {url}
      </a>
    );
  };

  const renderSynthesis = () => {
    const { consultant_notes, consultant_private_notes } = module.data[0];
    return (
      <>
        {consultant_notes && consultant_notes.length > 0 && (
          <div className="notes-synthesis">
            <label>Synthèse publique : </label>
            {consultant_notes.length > 1000 ? (
              <p>{consultant_notes.slice(0, 500) + '...'}</p>
            ) : (
              <p>{consultant_notes}</p>
            )}
          </div>
        )}
        {consultant_private_notes && consultant_private_notes.length > 0 && (
          <div className="notes-synthesis">
            <label className="notes-synthesis-private">
              <Icon icon={'lock'} />
              Synthèse privée :{' '}
            </label>
            {consultant_private_notes.length > 1000 ? (
              <p>{consultant_private_notes.slice(0, 500) + '...'}</p>
            ) : (
              <p>{consultant_notes}</p>
            )}
          </div>
        )}
      </>
    );
  };

  const renderVideo = () => {
    const { url, title } = module.data[0];
    return (
      <a href={url} target="_blank">
        {title}
      </a>
    );
  };

  const getAppointment = async (id) => {
    const appointment = await getAppointmentById(id).then((response) => {return response});
    return appointment;
  }
  

  const renderAppointment =  () => {
    if (module.data.length > 0 && appointment.rdv_at && appointment.rdv_to) {
      const date = moment(appointment.rdv_at).format('Do MMMM YYYY');
      const start = moment(appointment.rdv_at).format('LT');
      const end = moment(appointment.rdv_to).format('LT');
      return (
        <div>
          <p>
            Rendez-vous prévu le{' '}
            <b>
              {date} de {start} à {end}
            </b>
          </p>
        </div>
      );
    } else {

      return (
        <div>
          <p>Aucune date n'a été saisie</p>
        </div>
      );
    }
  };

  const renderCentralTest = () => {
    return <CentralTestModuleConsultant module={module.data[0]} />;
  };
  const renderActions = (isLockedModule) => {
    return (
      <div className="actions pl-3" style={{paddingLeft: "15px"}}>
        {!isOpenButtonActions ? (
          <button className="button-toggle-actions" onClick={() => setIsOpenButtonActions(true)}>
            <Icon icon={'more-horizontal'} color={'grey1'} />
          </button>
        ) : (
          <div className="action-container">
            {actions.map((action, id) => (
              <div className="slide-in" key={id} onClick={() => handleActionRessource(action.id)}>
                <div className="action-container-tooltip">
                  {action.id === 'lock' && isLockedModule ? (
                    <>
                      <Icon icon={Icon.icon.Lock} color={Icon.color.Accent} />
                    </>
                  ) : action.id === 'lock' && !isLockedModule ? (
                    <>
                      <Icon icon={Icon.icon.Unlocked} color={Icon.color.Grey2} />
                    </>
                  ) : (
                    <Icon icon={action.icon} color={Icon.color.Grey2} />
                  )}
                  <span className="action-container-tooltip-text">
                    {action.id === 'lock'
                      ? !isLockedModule
                        ? 'Verrouiller'
                        : 'Déverrouiller'
                      : action.name}
                  </span>
                </div>
              </div>
            ))}
            <div className="slide-in" onClick={() => setIsOpenButtonActions(false)}>
              <Icon icon={'close2'} color={Icon.color.Grey2} />
            </div>
          </div>
        )}
        {isOpenModalEditModule && module.type !== 'form' && module.type !== 'appointmentEvo' && (
          <EditModulePopin
            isOpenModal={isOpenModalEditModule}
            onClose={() => setIsOpenModalEditModule(false)}
            moduleProps={module}
            setNewModule={getNewModule}
          />
        )}

        {isAttruteCourse
          ? isOpenModalEditAppointmentModule &&
            module.type === 'appointmentEvo' && (
              <EditModuleAppointmentPopin
                isOpenModal={isOpenModalEditAppointmentModule}
                onClose={() => setIsOpenEditAppointmentModule(false)}
                moduleProps={module}
                setNewModule={getNewModule}
                setDeletedModule={getDeletedAppointment}
                talent={talent}
                user={user}
              />
            )
          : isOpenModalEditAppointmentNoAttributeCourse && (
              <EditModuleNoAttributeCoursePopin
                open={isOpenModalEditAppointmentNoAttributeCourse}
                onClose={() => setIsOpenModalEditAppointmentNoAttributeCourse(false)}
              />
            )}

        {isOpenModalRemoveModule && (
          <RemoveRessource
            open={isOpenModalRemoveModule}
            onClose={() => setIsOpenModalRemoveModule(false)}
            handleRemove={handleDeleteRessource}
            isPublished={module.template_published}
          />
        )}
      </div>
    );
  };

  const renderHeader = () => {
    const { title, locked, template_published } = module;
    const isLockedModule = locked === 1;
    return (
      <div className="module-resource-row-header">
        <div className="module-resource-row-title" onClick={openModalEditResource}>
          {isLockedModule && <Icon icon="lock" color="grey1" />}
          <IconRessource icon={type.value} />
          <h1 className="label ressource">{isNewModule ? type.label : title}</h1>
        </div>
        {template_published ? <Icon icon="publish" color={Icon.color.Accent} /> : ""}

        {renderActions(isLockedModule)}
      </div>
    );
  };

  ////////// main components ///////////
  const renderNewModule = () => {
    return <div className="new-module-resource-row">{renderHeader()}</div>;
  };

  const renderModule = () => {
    return (
      <div className="module-resource-row">
        {renderHeader()}
        <div className="module-resource-row-content">
          {/* Content of the module */}
          
          {type.value === 'document_to_return' && renderDocumentToReturn()}
          {type.value === 'feedback' && renderFeedback()}
          {type.value === 'file' && renderFile()}
          {type.value === 'form' && renderForm()}
          {type.value === 'link' && renderLink()}
          {type.value === 'synthesis' && renderSynthesis()}
          {type.value === 'video' && renderVideo()}
          {type.value === 'appointmentEvo' && renderAppointment()}
          {type.value === 'centraltest' && renderCentralTest()}
        </div>
      </div>
    );
  };
  if (module) {
    return isNewModule ? renderNewModule() : (module && module.data && module.data.length > 0 && module.data[0] ? renderModule() : null);
  } else return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleResourceRow);
