import React, { useState, useEffect } from 'react';
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton';
// import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle'
import Icon from '../../atoms/Icon/Icon';
import { actions as centralTestActions } from '../../../redux/CentralTestRedux';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as officeSelectors } from '../../../redux/OfficeRedux';
import CheckBoxField from '../../molecules/CheckBoxField/CheckBoxField';
import config from '../../../config/AppConfig';
import { getOfficeInfos } from '../../../api/CentralTestApi';

import './CentralTestCard.scss';
const CentralTestCard = ({ canRegister }) => {
  const dispatch = useDispatch();
  const office = useSelector(officeSelectors.office);
  const [optin, setOptin] = useState(false);
  const [officeInfoCentralTest, setOfficeInfoCentralTest] = useState(null);

  // const handleConnectClick = async () => {
  //   await dispatch(centralTestActions.createAccount())
  // }

  const toggleOptin = () => {
    setOptin(!optin);
  };

  const handleGetOfficeInfoCentralTest = async (officeId) => {
    const res = await getOfficeInfos(officeId);
    try {
      setOfficeInfoCentralTest(res);
    }
    catch (e) {
      setOfficeInfoCentralTest(null);
    }
  };

  useEffect(() => {
    handleGetOfficeInfoCentralTest(office.id);
  }, [office]);

  const renderConnecterPill = () => {
    return <div className="state">
    <Icon icon={Icon.icon.Check} className="check" />
    <span className="description--check">Compte associé !</span>
    <span className="description--check">
        
        {officeInfoCentralTest?.nb_credits ?? 0} tests restants
      </span>
    { officeInfoCentralTest?.nb_credits < 10 && (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        // paddingTop: '25px',
        gap: '20px',
      }}>
        <span className=" description--error">
          Attention, vous avez bientôt épuisé la totalité de vos tests !
        </span>{' '}
        <PrimaryButton
          label="Contacter MyTalents"
          style={{ alignSelf: 'auto' }}
          onClick={() =>
            (window.location.href =
              'mailto:support@my-talents.com?subject=Contact%20MyTalents')
          }
        />
      </div>
    )}
  </div>
  }

  const renderPill = () => {
    switch (officeInfoCentralTest?.status) {
      // case 'pending':
      //   return (
      //     <div className="state">
      //       <Icon icon={Icon.icon.Refresh} className="refresh" />
      //       <span className="description--pending">Association en cours...</span>
      //     </div>
      //   )
      case 'active':
        return (
          renderConnecterPill()
        );
      case 'connected':
        return (
          renderConnecterPill()
        );
      default:
        return (
          <div className="state">
            <Icon icon={Icon.icon.Error} className="error" />
            <span className="description--default">Aucun compte associé</span>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                paddingTop: '25px',
              }}
            >
              <PrimaryButton
                label="Contacter MyTalents"
                onClick={() =>
                  (window.location.href =
                    'mailto:support@my-talents.com?subject=Contact%20MyTalents')
                }
              />
            </div>
          </div>
        );
    }
  };

  return (
    <div className="centraltest-card">
      <h2>Compte CentralTest</h2>
      <div className="card">
        <p className="label">Compte CentralTest</p>
        <p className="status">{renderPill()}</p>
        {/* {!office.ct_status && (
          <>
            <div className="optin">
              <CheckBoxField name="optin" onChange={toggleOptin} value={optin} />

              <a href={`${config.API_STATIC_URL}/documents/cgu_beneficiaire.pdf`} target="_blank">
                J'accepte les conditions d'utilisation du service CentralTest
              </a>
            </div>
            <PrimaryButton
              label="Créer un compte"
              id="contact-mytalent"
              onClick={handleConnectClick}
              disabled={!optin && canRegister}
            />
          </>
        )} */}
      </div>
    </div>
  );
};

export default CentralTestCard;
