import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Page from '../../containers/global/Page/Page'
 

import Icon from '../../components/atoms/Icon/Icon'
import ActionPopinHeader from '../../components/molecules/ActionPopinHeader/ActionPopinHeader'
import PopinLayout from '../../layouts/PopinLayout/PopinLayout'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import {
  actions as ModuleTemplateActions,
  selectors as ModuleTemplateSelectors,
} from '../../redux/ModuleTemplateRedux'
import QuizCombo from '../../components/molecules/QuizCombo/QuizCombo'
import QuizGroup from '../../components/molecules/QuizGroup/QuizGroup'
import QuizQcm from '../../components/molecules/QuizQcm/QuizQcm'
import QuizStars from '../../components/molecules/QuizStars/QuizStars'
import QuizTextArea from '../../components/molecules/QuizTextArea/QuizTextArea'
import QuizYesNo from '../../components/molecules/QuizYesNo/QuizYesNo'
import QuizOpinionScale from '../../components/molecules/QuizOpinionScale/QuizOpinionScale'
import QuizDate from '../../components/molecules/QuizDate/QuizDate'
import "./ConsultantFormModulePreviewPage.scss";

const mapStateToProps = (state, props) => ({
  formId: Number(props.match.params.form_id) || null,
  pathname: NavigationSelectors.pathname(state),
  form: ModuleTemplateSelectors.template(state),
})

const mapDispatchToProps = (dispatch) => ({
  back: (prefix = '') => dispatch(NavigationActions.back(prefix, true)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  setModule: (module) => dispatch(ModuleTemplateActions.setTemplate(module)),
})

 
class ConsultantFormModulePreviewPage extends Component {
  static propTypes = {
    form: PropTypes.shape({}),
  }

  handleBack = () => {
    if (!this.props.course) {
      this.props.back()
    } else {
      this.props.back('/modules')
    }
  }

  componentDidMount() {
    if (!this.props.form) {
      this.handleBack()
    }
  }

  renderHeader() {
    const { form } = this.props
    const { title } = form

    return (
      <ActionPopinHeader
        iconButton={Icon.icon.Back}
        boldTitle={`${title}`}
        onIconClick={this.handleBack}
      />
    )
  }

  renderQuestion(key, question, prefix_id = '') {

    switch (question.type) {
      case 'text': {
        return (
          <QuizTextArea
            key={key}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            name={`${prefix_id}${question.id}`}
            value=""
            placeholder={"Entrez votre réponse ici..."}
            maxLength={
              question.options.max_length != null && question.options.max_length
                ? parseInt(question.options.max_length, 10)
                : 0
            }
            disabled
          />
        )
      }
      case 'select': {
        const comboFieldValue =
          question.options.required != null && question.options.required
            ? question.options.choices[0]
            : ''

        const comboFieldProps = {
          name: `${prefix_id}${question.id}`,
          options: question.options.choices.map((opt) => ({ value: opt, label: opt })),
          value: comboFieldValue,
          placeholder: "Sélectionnez une réponse",
          clearable: question.options.required == null || !question.options.required,
          readOnly: true,
        }

        return (
          <QuizCombo
            key={key}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            comboFieldProps={comboFieldProps}
          />
        )
      }
      case 'multiple_choice': {
        const choices = question.options.choices.map((choice) => ({
          label: choice,
          isSelected: false,
        }))

        if (question.options.allow_other != null && question.options.allow_other) {
          choices.push({
            label: "Question à choix multiples",
            isSelected: false,
          })
        }

        return (
          <QuizQcm
            key={key}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            options={choices}
            disabled
          />
        )
      }
      case 'group': {
        return (
          <QuizGroup
            key={key}
            label={question.title}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
          >
            {question.questions
              .sort((q1, q2) => q1.order - q2.order)
              .map((itemQuestion, key) =>
                this.renderQuestion(key + 1, itemQuestion, `${question.id}_`),
              )}
          </QuizGroup>
        )
      }
      case 'yes_no': {
        return (
          <QuizYesNo
            key={key}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            id={`${prefix_id}${question.id}`}
            value={null}
            yesLabel={"Oui"}
            noLabel={"Non"}
            disabled
          />
        )
      }
      case 'rating': {
        return (
          <QuizStars
            key={key}
            id={`${prefix_id}${question.id}`}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            starsCount={5}
            selectedStars={0}
            disabled
          />
        )
      }
      case 'opinion_scale': {
        return (
          <QuizOpinionScale
            key={key}
            id={`${prefix_id}${question.id}`}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            scale={question.options.scale ? question.options.scale : 10}
            value={-1}
            disabled
          />
        )
      }
      case 'date': {
        return (
          <QuizDate
            key={key}
            id={`${prefix_id}${question.id}`}
            label={`${question.title}${
              question.options.required != null && question.options.required ? ' *' : ''
            }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            monthLabels={["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]}
            dayTitle={"Jour"}
            monthTitle={"Mois"}
            yearTitle={"Année"}
            value=""
            disabled
          />
        )
      }
      default: {
        return null
      }
    }
  }

  renderContent() {
    const { form } = this.props
    const { data } = form
    const { questions } = data

    return (
      <div className="questions">
        {questions
          .sort((q1, q2) => q1.order - q2.order)
          .map((question, key) => this.renderQuestion(key + 1, question))}
        <div>{"*Ces champs sont obligatoires"}</div>
      </div>
    )
  }

  render() {
    const { form } = this.props

    if (!form) {
      return null
    }

    const header = this.renderHeader()
    const contentLayout = this.renderContent()

    return (
      <div className="consultant-form-module-preview-page">
        <PopinLayout header={header} content={contentLayout} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(ConsultantFormModulePreviewPage))