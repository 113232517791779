 import React from 'react';
import Icon from '../Icon/Icon';
import './HeaderConsultantTab.scss';
 
 const HeaderConsultantTab = ({title, logo, description, illustration}) => {
  return (
    <>
      <div className="card-title">
        <img src={illustration} alt="illustration" />
        <div>
          <h2 style={{ fontWeight: 'bold', display: 'flex', gap: '16px' }}>
            {logo && <Icon icon={logo} />}
            
            {title}
          </h2>
          <p>{description}</p>
        </div>
      </div>
      
    </>
  );
};

export default HeaderConsultantTab;
