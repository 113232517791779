import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { actions as AppointmentActions } from '../../../redux/AppointmentRedux'
 

import { Popin } from '../../global/Popin/Popin'
import AlertPopin from '../../../components/organisms/AlertPopin/AlertPopin'

const mapDispatchToProps = (dispatch, props) => ({
  confirm: (id) => dispatch(AppointmentActions.confirm(id)),
})

class AppointmentConfirmPopin extends PureComponent {
  static propTypes = {
    id: PropTypes.number,
    confirm: PropTypes.func.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    open: false,
  }

  handleConfirm = () => {
    this.props.confirm(this.props.id)
    this.props.onClose()
  }

  render() {
    const { onClose, open } = this.props

    return (
      <Popin onClose={onClose} open={open}>
        <AlertPopin
          label={"Confirmation de rendez-vous"}
          text={"Voulez - vous confirmer le rendez-vous ?"}
          labelCancelButton={"Annuler"}
          labelConfirmButton={"Confirmer"}
          onCancelButtonClick={onClose}
          onConfirmButtonClick={this.handleConfirm}
        />
      </Popin>
    )
  }
}

export default connect(null, mapDispatchToProps)(AppointmentConfirmPopin)