import 'moment/locale/fr';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isValid, reduxForm } from 'redux-form';
import {
  getValidationRules,
  profileFieldsForConsultant,
} from '../../../helpers/beneficiaryProfileForm';
import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator';
import { selectors as NavigationSelectors } from '../../../redux/NavigationRedux';
import { selectors as UserSelectors } from '../../../redux/UserRedux';
import { selectors as BeneficiarySelectors } from '../../../redux/BeneficiaryRedux';
import Page from '../../global/Page/Page';

import ConsultantBeneficiary from '../ConsultantBeneficiary/ConsultantBeneficiary';
import ConsultantBeneficiaryAside from '../ConsultantBeneficiaryAside/ConsultantBeneficiaryAside';

import './ConsultantBeneficiaryExplorerContainer.scss';
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton';

import CircleButton from '../../../components/atoms/CircleButton/CircleButton';
import DataTable from '../../../components/molecules/DataTable/DataTable';
import { useSelector } from 'react-redux';
import {
  getCompagnyTests,
  putAssignTest,
  getTalentsTests,
  getTalentMap,
  postResendTest,
  getOfficeInfos,
} from '../../../api/CentralTestApi';
import LoadingSpinner from '../../../components/atoms/LoadingSpinner/LoadingSpinner';
import AssignTestModal from './AssignTestModal/AssignTestModal';
import icon from '../../../components/atoms/Icon/Icon';
import Icon from '../../../components/atoms/Icon/Icon';
import { Toaster } from '../../../components/atoms/Toaster/Toaster';
import { useToaster } from '../../../hooks/useToaster';
import HeaderConsultantTab from '../../../components/atoms/HeaderConsultantTab/HeaderConsultantTab';
import HeaderIllustration from '../../../static/illustrations/Centraltest.png'

const mapStateToProps = (state, props) => ({
  valid: isValid('beneficiary-profile')(state),
  user: UserSelectors.user(state),
  pathname: NavigationSelectors.pathname(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
});

const columns = [
  'Intitulé',
  'Statut',
  'Débuté le',
  'Terminé le',
  'Rapport',
  'Actions',
];

const mapDispatchToProps = (dispatch) => ({});

const ConsultantBeneficiaryExplorerContainer = ({ beneficiary }) => {
  const [office, setOffice] = useState(null);
  const u = useSelector(UserSelectors.user);
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [tests, setTests] = useState([]);
  const [individualTests, setIndividualTests] = useState([]);
  const { toasters, addToaster, removeToaster } = useToaster();
  const [officeInfoCentralTest, setOfficeInfoCentralTest] = useState({});

  const renderAside = () => {
    return <ConsultantBeneficiaryAside />;
  };

  const getCompagnyTestsF = async () => {
    if (!office || tests.length > 0 ) return;
    setLoading(true);
    await getCompagnyTests(office.id).then((data) => {
      setTests(data);
    });
    setLoading(false);
  };

  const getData = async () => {
    if (!office && tests.length > 0) return;
    if (office?.id) {
      setLoading(true);
      // await getCompagnyTests(office.id).then((data) => {
      //   setTests(data);
      // });
      await getTalentsTests(beneficiary.id).then((data) => {
        setIndividualTests(data);
      });
      setLoading(false);
    }
  };

  const getOfficeInfoCentralTest = async () => {
    if (!office) return;
    await getOfficeInfos(office.id).then((data) => {
      setOfficeInfoCentralTest(data);
    });
  };

  useEffect(() => {
    getOfficeInfoCentralTest();
    getData();
  }, [office]);

  useEffect(() => {
    if (u && u.office) {
      setOffice(u.office);
    }
  }, [u]);

  const handleAssign = async (res) => {
    setLoading(true);
    const data = {
      user_id: beneficiary.id,
      consultant_id: u.profile_id,
      test_id: res.test.id,
      test_title: res.test.label,
      detail_id: res.detail?.id ?? null, //optional only IT test
      detail_level_id: res.level?.value ?? null, //optional only IT test
      test_language_id: res.lang?.id ?? null, //optional only IT test
      office_id: office.id,
    };
    try {
      await putAssignTest(data);
      await getData();
      setLoading(false);
      addToaster('Le test à bien été attribué', 'Un e-mail a été envoyé au talent.', 'success');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        await getData();
        setLoading(false);
        addToaster('Erreur', "Le test n'a pas pu être attribué. Veuillez réessayer.", 'error');
      } else {
        await getData();
        setLoading(false);
        addToaster('Erreur', "Une erreur inattendue s'est produite. Veuillez réessayer.", 'error');
      }
    }

    setShowAddModal(false);
  };

  const handleGetTalentMap = async () => {
    setLoading(true);
    const response = await getTalentMap(beneficiary.id);
    setLoading(false);
  };

  const handlePostResendTest = async (testId) => {
    setLoading(true);
    const data = {
      user_id: beneficiary.id,
      test_id: testId,
    };
    const response = await postResendTest(data);
    setLoading(false);
  };

  const renderContent = () => {
    return (
      <div
        className="beneficiary-explorer-container"
        style={{ position: 'relative', height: '100%' }}
      >
        {loading && <LoadingSpinner />}
        <div className="consultant-beneficiary-explorer-table">
          {/* Mettre le bandeau */}
          <HeaderConsultantTab title={"Central Test"} logo={icon.icon.CentralTest} description={"Planifiez ici vos tests psychométriques et tests de compétences"} illustration={HeaderIllustration} />
          {u.role === 'consultant' && (
            <div
              className="invite-test-wrapper"
              style={{ display: 'flex', gap: '10px', paddingBottom: '25px', paddingTop: '25px' }}
            >
              <CircleButton icon={'plus'} onClick={() => setShowAddModal(true)} />
              Inviter à passer le test
            </div>
          )}

          <div className="tests-list-wrapper">
            <DataTable
              columns={columns}
              data={individualTests}
              postResendTest={handlePostResendTest}
              addToaster={addToaster}
              isConsultant={true}
              officeInfo={officeInfoCentralTest}
              setLoading={setLoading}
            />
          </div>
        </div>
        <AssignTestModal
          isOpen={showAddModal}
          onClose={() => setShowAddModal(false)}
          onAssign={handleAssign}
          beneficiary={beneficiary}
          tests={tests}
          getTests={getCompagnyTestsF}
        />
        <div className="notification-container" style={{ zIndex: 99999 }}>
          {toasters.map((toaster) => (
            <Toaster
              key={toaster.id}
              title={toaster.title}
              message={toaster.message}
              type={toaster.type}
              onClose={() => removeToaster(toaster.id)}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <ConsultantBeneficiary renderAside={renderAside} disableSave>
      <div className="consultant-beneficiary-explorer-page" style={{ height: '100%' }}>
        {renderContent()}
      </div>
    </ConsultantBeneficiary>
  );
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  Page(
    reduxForm({
      form: 'beneficiary-profile',
      asyncBlurFields: profileFieldsForConsultant,
      asyncChangeFields: profileFieldsForConsultant,
      asyncValidate: (values, dispatch, props) => {
        const validationRules = getValidationRules(props.beneficiary, values, true);
        return asyncValidate(values, validationRules);
      },
    })(ConsultantBeneficiaryExplorerContainer),
  ),
);

export default ConnectedComponent;
