export const keyValueToValueLabel = (options) => {
  return options.map((item) => ({
    value: item.key,
    label: item.value,
  }))
}

export const getEducationOptions = () => {
  const beneficiaryFormations = [
    { key: "I", value: "Bac + 5 et + : Master 2 / Ecole d'ingénieur et +" },
    { key: "II", value: "Bac + 3/4 : Licence / Master 1" },
    { key: "III", value: "Bac + 2 : BTS, DUT..." },
    { key: "IV", value: "Bac général, technologique ou professionnel, BP, BT ou équivalent" },
    { key: "V", value: "Sortie après l'année de terminale de CAP ou sortie du second cycle général et technologique avant l'année de terminale (seconde ou première)" },
    { key: "Vbis", value: "Sortie en cours de cycle de l'enseignement secondaire (de la 6ème à la 3ème)" },
    { key: "VI", value: "Abandon en cours de CAP ou BEP avant l'année de terminale" },
  ]

  return keyValueToValueLabel(beneficiaryFormations)
}

export const getAccompanimentTypeOptions = () => {
  const beneficiaryAccompanimentTypes = [
    { key: "appraisal", value: "Bilan de compétences" },
    { key: "outplacement_individual", value: "Outplacement Individuel" },
    { key: "vae", value: "VAE" },
    { key: "coaching", value: "Coaching" },
    { key: "orientation_review", value: "Bilan orientation" },
    { key: "business_creation", value: "Création d'entreprise" },
    { key: "collective_scheme", value: "Dispositif collectif" },
    { key: "outplacement_group", value: "Outplacement Collectif" },
    { key: "formation", value: "Formation" },
    { key: "other", value: "Autre" },
  ]

  return keyValueToValueLabel(beneficiaryAccompanimentTypes)
}

export const getActivityOptions = () => {
  const beneficiaryCompanyActivitySectors = [
    { key: "other", value: "Autre" },
    { key: "agriculture_forestry_fishing", value: "Agriculture, sylviculture, pêche" },
    { key: "industry", value: "Industrie" },
    { key: "building_civil_agricultural_engineering", value: "Bâtiment, génie civil et agricole" },
    { key: "trade_commercial", value: "Commerce et services marchands" },
    { key: "insurance_financial", value: "Assurances et services financiers" },
    { key: "non_market", value: "Services non marchands" },
  ]

  return keyValueToValueLabel(beneficiaryCompanyActivitySectors)
}

export const getSituationOptions = () => {
  const beneficiaryProfessionalSituations = [
    { key: "cdd", value: "Salarié(e) CDD" },
    { key: "cdi", value: "Salarié(e) CDI" },
    { key: "jobseeker", value: "Demandeur d'emploi" },
    { key: "student", value: "Etudiant(e)" },
    { key: "other", value: "Autre" },
  ]

  return keyValueToValueLabel(beneficiaryProfessionalSituations)
}

export const getSocioProfessionalCategoriesOptions = () => {
  const beneficiarySocioProfessionalCategories = [
    { key: "unskilled_worker", value: "Ouvrier(e) non qualifié(e)" },
    { key: "qualified_worker", value: "Ouvrier(e) qualifié(e)" },
    { key: "employee", value: "Employé(e)" },
    { key: "agent_control", value: "Agent de maîtrise" },
    { key: "technician", value: "Technicien" },
    { key: "engineer", value: "Ingénieur" },
    { key: "frame", value: "Cadre" },
    { key: "assimilated_frame", value: "Assimilé cadre" },
    { key: "executive_frame", value: "Cadre dirigeant" },
    { key: "other", value: "Autre" },

  ]

  return keyValueToValueLabel(beneficiarySocioProfessionalCategories)
}

export const getAccompanimentDurationTypesOptions = () => {
  const beneficiaryAccompanimentDurationTypes = [
    { key: "hours", value: "Heures" },
    { key: "months", value: "Mois" },
    { key: "days", value: "Jours" },
  ]

  return keyValueToValueLabel(beneficiaryAccompanimentDurationTypes)
}

export const getFinancerStatesOptions = () => {
  const beneficiaryFinancerStates = [
    { key: "to_fill", value: "A renseigner" },
    { key: "pending", value: "En attente" },
    { key: "done", value: "Validé" },
  ]

  return keyValueToValueLabel(beneficiaryFinancerStates)
}


export const getFinancerTypesOptions = () => {
  const beneficiaryFinancerTypes = [
    { key: "individual", value: "Particulier" },
    { key: "pole_emploi", value: "France travail" },
    { key: "company", value: "Entreprise" },
    { key: "state", value: "Etat" },
    { key: "territorial_collectivity", value: "Collectivité territoriale" },
    { key: "public_institution", value: "Etablissement public à caractère administratif" },
    { key: "cpf", value: "CPF" },
    { key: "other", value: "Autre" },
  ]

  return keyValueToValueLabel(beneficiaryFinancerTypes)
}

export const getFinancerOPACIFTypesOptions = () => {
  const beneficiaryFinancerOPACIFTypes = [
    { key: "fongecif", value: "fongecif" },
    { key: "fafsea", value: "fafsea" },
    { key: "afdas", value: "afdas" },
    { key: "apcm", value: "apcm" },
    { key: "faftt", value: "faftt" },
    { key: "opcalim", value: "opcalim" },
    { key: "unifaf", value: "unifaf" },
    { key: "uniformation", value: "uniformation" },
    { key: "unagecif", value: "unagecif" },
  ]

  return keyValueToValueLabel(beneficiaryFinancerOPACIFTypes)
}

export const getAppraisalTypesOptions = () => {
  const beneficiaryAppraisalTypes = [
    { key: "pole_emploi", value: "France travail" },
    { key: "formation_plan", value: "Plan de développement des compétences" },
    { key: "leave_of_absence", value: "Financement personnel" },
    { key: "cpf", value: "CPF" },
    { key: "individual", value: "Particulier" },
    { key: "other", value: "Autre" },
  ]

  return keyValueToValueLabel(beneficiaryAppraisalTypes)
}

export const getAppraisalAvailabilitiesOptions = () => {
  const beneficiaryAppraisalAvailabilities = [
    { key: "within_work", value: "Durant le travail" },
    { key: "out_of_work", value: "Hors horaires de travail" },
  ]

  return keyValueToValueLabel(beneficiaryAppraisalAvailabilities)
}