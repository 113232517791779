import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors as UserSelectors } from '../../redux/UserRedux';
import { selectors as NavigationSelectors } from '../../redux/NavigationRedux';

import { getMission } from '../../api/MissionsApi';

import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside';
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout';
import { IconBack } from '../../components/atoms/IconBack/IconBack';
import LineMenu from '../../components/organisms/LineMenu/LineMenu';
import ManagerPageAside from '../../containers/manager/ManagerPageAside/ManagerPageAside';
import { MissionContainer } from '../../containers/missions/MissionContainer/MissionContainer';
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout';
import { TalentClientCard } from '../../components/organisms/TalentClientCard/TalentClientCard';
import SearchField from '../../components/molecules/SearchField/SearchField';
import { Toaster } from '../../components/atoms/Toaster/Toaster';

import { useToaster } from '../../hooks/useToaster';

import { getUser } from '../../api/userAPI';
import { shareBeneficiary, getConsultantsByTalents } from '../../api/ConsultantApi';

import './ConsultantMissionPage.scss';
import BigCombo from '../../components/molecules/BigCombo/BigCombo';

export const ConsultantMissionPage = () => {
  const user = useSelector(UserSelectors.user);
  const [tab, setTab] = useState('info');
  const [mission, setMission] = useState({
    name: null,
    responsable: [],
    client_id: null,
    consultants: [],
  });
  const [isValidMission, setIsValidMission] = useState(true);
  const [disabledButtonSaveMission, setDisabledButtonSaveMission] = useState();
  const path = useSelector(NavigationSelectors.lastParam);
  const [talents, setTalents] = useState([]);
  const [saveTalents, setSaveTalents] = useState([]);
  const [query, setQuery] = useState('');
  const { toasters, addToaster, removeToaster } = useToaster();
  const [isEditable, setIsEditable] = useState();
  const [filters, setFilters] = useState({ direction: 'asc', attribute: 'first_name' });

  const sortQuery = {
    first_name_asc: {
      direction: 'asc',
      attribute: 'first_name',
    },
    first_name_desc: {
      direction: 'desc',
      attribute: 'first_name',
    },
    last_name_asc: {
      direction: 'asc',
      attribute: 'last_name',
    },
    last_name_desc: {
      direction: 'desc',
      attribute: 'last_name',
    },
  };

  const sortOptionsConsultants = [
    ...mission.consultants.map((consultant) => ({
      label: consultant.first_name + ' ' + consultant.last_name,
      value: consultant.id,
    })),
    { label: 'Tous les consultants', value: 'all' },
  ];

  const sortOptions = [
    {
      label: 'Ordre alphabétique décroissant (Prénom)',
      value: 'first_name_desc',
    },
    {
      label: 'Ordre alphabétique croissant (Prénom)',
      value: 'first_name_asc',
    },
    {
      label: 'Ordre alphabétique décroissant (Nom)',
      value: 'last_name_desc',
    },
    {
      label: 'Ordre alphabétique croissant (Nom)',
      value: 'last_name_asc',
    },
  ];

  const handleConsultantOfTalent = async (talent) => {
    try {
      const consultants = await getConsultantsByTalents(talent.id);
      const consultant_primary = consultants.consultants.filter(
        (consultant) => consultant.is_primary === 1,
      );
      const rep = consultant_primary.filter((consultant) => consultant.is_primary === 1)[0];
      return { ...talent, consultant: rep };
      return rep;
      return consultant_primary.filter((consultant) => consultant.is_primary === 1)[0];
    } catch (error) { }
  };

  const handleTabsOnChange = (tab) => {
    setTab(tab);
    getMission(+path).then((response) => {
      setMission(response.mission);
      const consultant = response.mission.talents.map((talent) => handleConsultantOfTalent(talent));
      setTalentsWithConsultant(response.mission.talents);
      // setTalents(response.mission.talents.map((talent) => ({ ...talent, consultant: handleConsultantOfTalent(talent) })));
      // setTalents(response.mission.talents);
    });
  };

  const handleIsMissionCompleted = (isMissionCompleted) => {
    if (mission && mission.id) {
      setIsValidMission(isMissionCompleted);
      setDisabledButtonSaveMission(!isMissionCompleted);
    }

  };

  const getMissionUpdated = (missionUpdated) => {
    setMission(missionUpdated);
    missionUpdated !== mission ? setMission(missionUpdated) : setDisabledButtonSaveMission(true);
  };

  const handleViewTalent = async (consultant) => {
    if (mission && mission.shared) {
      const userTmp = await getUser(consultant.id);
      try {
        if (userTmp.user.beneficiary_id) {
          const response = await shareBeneficiary({
            beneficiary_id: userTmp.user.beneficiary_id,
            consultant_id: user.profile_id,
          });
        }

        window.location.href = `/consultant/beneficiaries/${consultant.id}/profile`;
      } catch (error) {
        console.error('Erreur lors de la requête:', error);
      }
    } else {
      const isUser = user.beneficiaries.filter((b) => b.id === consultant.id);
      if (isUser.length) {
        window.location.href = `/consultant/beneficiaries/${consultant.id}/profile`;
      }
    }
  };

  const handleOnSearch = (search) => {
    setQuery(search.value);
    const lowerCaseQuery = search.value.toLowerCase();

    const filteredTalents = mission.talents.filter((talent) => {
      const talentFirstName = talent.first_name.toLowerCase();
      const talentLastName = talent.last_name.toLowerCase();
      return talentFirstName.includes(lowerCaseQuery) || talentLastName.includes(lowerCaseQuery);
    });

    setTalents(filteredTalents);
  };

  const setTalentsWithConsultant = async (talents) => {
    if (!talents) return;
    const talentsWithConsultants = await Promise.all(
      talents.map(async (talent) => await handleConsultantOfTalent(talent)),
    );
    setTalents(talentsWithConsultants);
    setSaveTalents(talentsWithConsultants);
  };

  useEffect(() => {
    getMission(+path).then((response) => {
      setMission(response.mission);
      setTalentsWithConsultant(response.mission.talents);
    });
  }, [path]);

  useEffect(() => {
    if (mission) {
      if (user.id === mission?.responsable[0]?.id) {
        setIsEditable(true);
      } else setIsEditable(false);
    }
  }, [user, mission]);

  const renderMissionInformations = () => {
    return (
      <div className="mission-informations">
        {/* {isEditable && (
          <PrimaryButton
            label={'Sauvegarder les modifications'}
            disabled={disabledButtonSaveMission}
            onClick={handlesSaveChangesOnClick}
          />
        )} */}
        <MissionContainer
          missionProps={mission}
          isMissionCompleted={handleIsMissionCompleted}
          sendMission={getMissionUpdated}
          talents={talents}
          setTalentsWithConsultant={setTalentsWithConsultant}
        />
        {!isValidMission && (
          <p className="alert-invalid-mission">
            *Les champs obligatoires n'ont pas été remplis. Veuillez fournir le nom de la mission,
            le responsable de mission ainsi que le client.
          </p>
        )}
      </div>
    );
  };

  const filterUsers = (users, direction, attribute) => {
    if (!users || users.length === 0) return users;
    return users.sort((a, b) => {
      if (direction === 'desc') {
        return a[attribute].localeCompare(b[attribute]);
      } else if (direction === 'asc') {
        return b[attribute].localeCompare(a[attribute]);
      } else {
        throw new Error('Direction doit être "asc" ou "desc"');
      }
    });
  };

  useEffect(() => {
    if (filters) {
      if (filters.attribute && filters.direction) {
        setTalents(filterUsers(talents, filters.direction, filters.attribute));
      }
    }
  }, [filters, talents]);

  const handleSortChangeConsultant = ({ value }) => {
    if (value.value === 'all') {
      setTalents(saveTalents)
    } else {
      setTalents(saveTalents.filter(
        (talent) => talent.consultant.user_id === value.value,
      ));
    }
  };

  const handleSortChange = ({ value }) => {
    const { attribute, direction } = sortQuery[value?.value];

    setFilters({ direction, attribute });

    // dispatch(
    //   ConsultantActions.setBeneficiariesFilter({
    //     ...beneficiariesFilter,
    //     orderAttribute: attribute,
    //     orderDirection: direction,
    //   }),
    // )
  };

  const renderMissionTalents = () => {
    let talents_filtered = [];
    let check = 0;

    if (talents) {
      for (let i = 0; i < talents.length; i++) {
        for (let j = 0; j < talents_filtered.length; j++) {
          if (talents[i].id === talents_filtered[j].id) check = 1;
        }

        if (check === 0) talents_filtered.push(talents[i]);

        check = 0;
      }
    }

    return (
      <div className="mission-talents">
        <div className="mission-talents-filters">
          <SearchField
            placeholder={'Rechercher par nom de talent'}
            value={query}
            onChange={handleOnSearch}
          />
          <div className="filters">
            {/* <BigCombo value={selected} options={options} onChange={handleBigComboChange} /> */}
            <BigCombo
              options={sortOptions}
              onChange={handleSortChange}
              dropDownProps={{
                placeholder: 'Trier',
              }}
            />
            <BigCombo
              options={sortOptionsConsultants}
              onChange={handleSortChangeConsultant}
              value={'all'}
              placeholder={'Sélectionner un consultant principal...'}
              dropDownProps={{
                placeholder: 'Sélectionner un consultant principal...',
              }}
            />
            <span className="separator" />
          </div>
        </div>
        <div className="mission-all-talents">
          {talents &&
            talents_filtered &&
            talents_filtered.length > 0 &&
            talents_filtered.map((talent, index) => (
              <TalentClientCard
                talent={talent}
                mission={mission}
                onClick={() => handleViewTalent(talent)}
                key={index}
              />
            ))}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        {tab === 'info' && renderMissionInformations()}
        {tab === 'talents' && renderMissionTalents()}
      </>
    );
  };

  const renderTabs = () => {
    const tabs = [
      { id: 'info', label: 'Informations de la mission' },
      { id: 'talents', label: 'Les talents de la mission' },
    ];
    return <LineMenu value={tab} links={tabs} onChange={handleTabsOnChange} />;
  };

  const aside = user.role === 'consultant' ? <ConsultantPageAside /> : <ManagerPageAside />;
  const content = () => {
    return (
      <div className="consultant-mission-page">
        <div className="left-navbar">
          <IconBack iconColor={'accent'} isText={true} />
        </div>
        <TabbedContentLayout header={renderTabs()} content={renderContent()} />
      </div>
    );
  };

  return (
    <div className="page">
      <div>
        <LeftAsideLayout aside={aside} content={content()} />
      </div>
      <div className="notification-list">
        {toasters.map((toaster) => (
          <Toaster
            key={toaster.id}
            title={toaster.title}
            message={toaster.message}
            type={toaster.type}
            onClose={() => removeToaster(toaster.id)}
          />
        ))}
      </div>
    </div>
  );
};
