import React from "react"
import { Modal } from "../../../components/atoms/Modal/Modal"

const NoCommentCvPopin = ({ onClose, isOpen, text }) => {

    const renderContent = () => {
        return (
            <div>
                <p>{text}</p>
            </div>
        )
    }

    return (
        <Modal
            onClosePopin={onClose}
            openPopin={isOpen}
            title={"Action non disponible"}
            content={renderContent()}
            cancelButton={"Annuler"}
            actionButton={"D'accord"}
            handleActionButton={onClose}
        />
    )
}

export default NoCommentCvPopin