import React, { useEffect, useState } from "react";

export default function ActiveConversation(props) {

    const [activeConversation, setActiveConversation] = useState(props.activeConversation);
    
    useEffect(() => {
        setActiveConversation(props.activeConversation);
    }, [props]);

    if (activeConversation !== undefined) {
        return (
            
            <div className="container container-active-conversation mt-2 ms-4">
                <img src={activeConversation.profile_picture === null || activeConversation.profile_picture === undefined ? "../static/icons/person-missing.svg" : `${process.env.REACT_APP_ASSETS_API_STATIC_URL}${(activeConversation.profile_picture).replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')}`} alt=" " />
                <p><span className="at-symbol">@</span>{props.nameActiveConversation}</p>
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}