/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useRouteMatch } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { actions as NavigationActions } from '../../redux/NavigationRedux'
import { connect } from "react-redux";

import { getModuleById, createSubmodule, updateModule, deleteSubmodule } from "../../api/CourseApi"
import { getFormsType } from "../../api/TemplateCourseApi";

import AsideModuleSelector from "../../containers/course/AsideModuleSelector/AsideModuleSelector"
import DescriptionTextArea from "../../components/molecules/DescriptionTextArea/DescriptionTextArea"
import EditQuestionFormModulePopin from "../../containers/module/EditQuestionFormModulePopin/EditQuestionFormModulePopin"
import { EmptyTitleFormModal } from "../../containers/popins/course/EmptyTitleFormModal/EmptyTitleFormModal"
import { NoQuestionFormModal } from "../../containers/popins/course/NoQuestionFormModal/NoQuestionFormModal";
import FormQuestionRow from "../../components/molecules/FormQuestionRow/FormQuestionRow"
import Icon from "../../components/atoms/Icon/Icon"
// import PrimaryButton from "../../components/atoms/PrimaryButton/PrimaryButton"
import RightAsideLayout from "../../layouts/RightAsideLayout/RightAsideLayout"

import './FormEditPage.scss'

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";


const mapDispatchToProps = (dispatch) => ({
    back: (prefix = '') => dispatch(NavigationActions.back(prefix, true)),
})

const FormEditPage = (props) => {
    const [module, setModule] = useState({});
    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const [questions, setQuestions] = useState([]);
    const [question, setQuestion] = useState({})
    const [questionsType, setQuestionsType] = useState([]);
    const [isEditQuestion, setIsEditQuestion] = useState(false);
    const [nbQuestions, setNbQuestions] = useState();
    const match = useRouteMatch();
    const moduleId = match.params.module_id;
    const formId = match.params.form_id;
    const tabs = [{ id: 'new', label: "Boite à outils" }];
    const [isLoading, setIsLoading] = useState(false);
    const [isTitle, setIsTitle] = useState(false);
    const [isOpenModalEnterTitleQuiz, setIsOpenModalEnterTitleQuiz] = useState(false);
    const [isOpenModalEnterQuestion, setIsOpenModalEnterQuestion] = useState(false);
    const [droppableAreaClass, setDroppableAreaClass] = useState('droppable-area');
    const [isFormChanged, setIsFormChanged] = useState(false)

    const history = useHistory();
    const location = useLocation();

    const fetchModule = () => {
        getModuleById(moduleId).then((response) => {
            const module = response.module;
            const questions = response.module.data[0].module_form_questions;
            if (response.module.description)
                setDescription(response.module.description)

            if (response.module.title)
                setTitle(response.module.title)
            setModule(module);
            setQuestions(parseObjectQuestions(questions))
        })
    }

    const fetchQuestionsType = () => {
        getFormsType().then((response) => {
            setQuestionsType(response)
        })
    }

    useEffect(() => {
        fetchModule();
        fetchQuestionsType()
    }, [])

    useEffect(() => {
        setNbQuestions(questions?.length)
    }, [questions])

    useEffect(() => {
        const { title } = module;
        title ? setIsTitle(true) : setIsTitle(false);
    }, [module])

    useEffect(() => {
        if (module && module.data && questions.length > 0) {
            if (module.data[0].module_form_questions.length === questions.length) {
                setIsFormChanged(false)
            }
            else {
                setIsFormChanged(true)
            }
        }
    }, [module, questions])

    const goBack = () => {
        if(questions.length === 0) {
            setIsOpenModalEnterQuestion(true)
        }
        else if(!isTitle) {
            setIsOpenModalEnterTitleQuiz(true) 
        }
        else {
            history.push({
                pathname: `/consultant/templates/courses/${match.params.course_id}`,
                state: module.modules_container_id
            }); 
        }
    }

    const handleOnChangeTitle = (event) => {
        const title = event.target.value;
        setTitle(title)
        const newModule = { ...getNewModule(questions), title: title }
        setModule(newModule)
        updateModule(newModule);
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.value);
        const newModule = { ...getNewModule(questions), description: event.value }
        setModule(newModule)
        updateModule(newModule)
    }

    const handleEditQuestion = (value) => {
        const questionToEdit = questions.filter(question => question.id === +value.id);
        setQuestion(questionToEdit[0])
        setIsEditQuestion(true)
    }

    const handleCopyQuestion = (question) => {
        const originalQuestion = questions.find(q => q.id === +question.id);
        const duplicateQuestion = { module_id: moduleId, module_form_id: formId, type: originalQuestion.type, title: originalQuestion.title, answer: "", options: JSON.stringify(originalQuestion.options), order: questions?.length + 1, image: originalQuestion.image };
        setIsLoading(true);
        createSubmodule(duplicateQuestion).then((response) => {
            const questions = response.module.data[0].module_form_questions;
            const newQuestions = parseObjectQuestions(questions);
            setQuestions(newQuestions);

        })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleDeleteQuestion = (question) => {
        const { id } = question;
        const questionDeleted = questions.find(q => q.id === +id);
        const filterQuestions = questions.filter(q => q.id !== +id);

        const updatedQuestions = questions
        .filter(q => q.id !== +id)
        .map((q, index) => ({ ...q, order: index + 1 }));
        updatedQuestions.forEach((questionToEdit) => {
            setQuestion(questionToEdit);
        });

        setQuestions(updatedQuestions);
        const newModule = { ...getNewModule(updatedQuestions) }
        updateModule(newModule)

        setIsLoading(true)
        deleteSubmodule(module.type, id).finally(() => {
            setIsLoading(false);
        })
    }

    const handleOnClosePopin = () => {
        setIsEditQuestion(false)
    }

    const parseObjectQuestions = (questions) => {
        const newQuestions = questions.map((question) => {
            question.options = JSON.parse(question.options);
            return question
        })

        return newQuestions;
    }

    const getNewQuestion = (newQuestion) => {
        const updateQuestions = questions?.map((q) => {
            if (q.id === newQuestion.id) {
                q = newQuestion
            }
            return q;
        })
        setIsLoading(true)
        updateModule(getNewModule(updateQuestions)).then((response) => {
            setQuestions(parseObjectQuestions(response.module.data[0].module_form_questions))
            const q = response.module.data[0].module_form_questions.filter(q => q.id === question.id);
            setQuestion(q[0])
        })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const getNewModule = (q) => {
        const newQuestions = q.map((question) => (
            { ...question, options: JSON.stringify(question.options) }
        ))

        const newModuleData = { ...module.data[0], module_form_questions: newQuestions }
        const newModule = { ...module, data: [newModuleData] }

        return newModule;
    }

    const onDragStart = () => {
        setDroppableAreaClass('droppable-area-dragging');
    }

    const onDragEnd = (result) => {
        setDroppableAreaClass('droppable-area');
        if (result.source.droppableId === "new-ressources") {
            const type = result.draggableId.split("ressource-")[1].split(" ")[0].trim();
            const length = questions?.length;
            const question = { module_id: moduleId, module_form_id: formId, type: type, title: "", answer: "", options: '{"required": false, "choices": [""]}', order: length + 1, image: null };
            setIsLoading(true)
            createSubmodule(question).then((response) => {
                const questions = response.module.data[0].module_form_questions;
                const newQuestions = parseObjectQuestions(questions);
                setQuestions(newQuestions)
            })
                .finally(() => {
                    setIsLoading(false);
                })
        }

        else {
            const copyQuestions = [...questions];
            const [removed] = copyQuestions.splice(result.source.index, 1);
            copyQuestions.splice(result.destination.index, 0, removed);

            const reoderedQuestions = copyQuestions.map((question, index) => (
                { ...question, order: index + 1, options: JSON.stringify(question.options) }
            ))
            const newModuleData = { ...module.data[0], module_form_questions: reoderedQuestions }
            const newModule = { ...module, data: [newModuleData] }
            updateModule(newModule);

            const newQuestions = parseObjectQuestions(reoderedQuestions);
            setQuestions(newQuestions)
        }
    }

    const renderHeader = () => {
        return (
            <div className="form-edit-page-header">
                <div className="form-edit-page-title">
                    <button onClick={goBack}>
                        <Icon icon="back" color={"accent"} />
                    </button>
                    <input placeholder="Cliquez ici pour renseigner le titre du formulaire" value={title ? title : ''} onChange={handleOnChangeTitle} className="question-title" />
                </div>
                {isFormChanged &&
                    <p>{isLoading ? "Enregistrement en cours..." : "Formulaire enregistré"}</p>
                }
            </div>
        )
    }

    const renderNumberOfQuestions = () => {
        return <p><span>{nbQuestions} </span>{nbQuestions + 1 > 2 ? 'questions' : 'question'}</p>
    }

    const renderDescription = () => {
        return (
            <DescriptionTextArea
                name="description"
                placeholder={"Description..."}
                onChange={(event) => handleDescriptionChange(event)}
                value={!description ? '' : description}
            />
        )
    }

    const renderForm = () => {
        const sortedQuestions = questions?.sort((a, b) => a.order - b.order);

        return (
            <Droppable droppableId="form" type="FORM" >
                {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className={`${droppableAreaClass} ${snapshot.isDraggingOver ? 'droppable-area-over' : ''}`}>
                        {sortedQuestions?.map((question, index) => (
                            <Draggable key={question.id} draggableId={`question-${question.id}`} index={index}>
                                {(provided) => (
                                    <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                        <FormQuestionRow
                                            id={`${question.id}`}
                                            questionType={question.type}
                                            questionNumber={question.order}
                                            title={question.title ? question.title : 'Nouvelle question'}
                                            onEditClick={handleEditQuestion}
                                            onDeleteClick={handleDeleteQuestion}
                                            onCopyClick={handleCopyQuestion}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}

                        <div className="droppable-new-question">
                            <p>Glisser la nouvelle question ici</p>
                        </div>
                    </div>
                )}
            </Droppable>
        )
    }

    const renderContent = () => {
        return (
            <>
                {renderHeader()}
                <div className="form-edit-page-content">
                    {renderNumberOfQuestions()}
                    {renderDescription()}
                    {renderForm()}
                </div>
            </>
        )
    }
    return (
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <div className="form-edit-page">
                <RightAsideLayout aside={<AsideModuleSelector ressources={questionsType} tabs={tabs} />} content={renderContent()} />
                {isEditQuestion && <EditQuestionFormModulePopin open={isEditQuestion} onClose={handleOnClosePopin} questionKey={question.id} question={question} setQuestion={setQuestion} setNewQuestion={getNewQuestion} />}
                {isOpenModalEnterTitleQuiz && <EmptyTitleFormModal open={isOpenModalEnterTitleQuiz} onClose={() => setIsOpenModalEnterTitleQuiz(false)} />}
                {isOpenModalEnterQuestion && <NoQuestionFormModal open={isOpenModalEnterQuestion} onClose={() => setIsOpenModalEnterQuestion(false)} />}
            </div>
        </DragDropContext>
    )
}

export default connect(null, mapDispatchToProps)(FormEditPage)