/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { updateModule } from "../../../api/CourseApi";

import DescriptionTextArea from "../../../components/molecules/DescriptionTextArea/DescriptionTextArea";
import DropStepZone from "../../../components/molecules/DropStepZone/DropStepZone";
import InputField from "../../../components/molecules/InputField/InputField";
import { Popin } from "../../global/Popin/Popin";
import PrimaryButton from "../../../components/atoms/PrimaryButton/PrimaryButton";

import moment from 'moment';
import { ModuleTypes, DocumentSubTypes } from './EditModulePopinStates';

import './EditModulePopin.scss';
import Icon from "../../../components/atoms/Icon/Icon";

export const EditModulePopin = ({ moduleProps, isOpenModal, onClose, setNewModule }) => {
    const [disabledSaveButton, setDisabledSaveButton] = useState(true);
    const [module, setModule] = useState();
    const [type, setType] = useState({ label: "", value: "" });
    const [selectedOption, setSelectedOption] = useState('document');
    const [seletedOptionDocumentToReturn, setSelectedOptionDocumentToReturn] = useState('return');

    ////////// use effects ///////////
    useEffect(() => {
        setModule(moduleProps)
    }, [])

    useEffect(() => {
        module && getCurrentType();
    }, [module])

    useEffect(() => {
        if (module && type)
            handleDisabledSaveButton();
    }, [module, type, seletedOptionDocumentToReturn])

    useEffect(() => {
        if (module && module.title && (type.value === "file" || type.value === "video")) {
            const { url } = module.data[0];
            url ? setSelectedOption('link') : setSelectedOption('document');
        }
    }, [module, type])

    ////////// methods ///////////
    const getCurrentType = () => {
        const { type } = module;

        if (type === "document") {
            const type = module.data[0].type;
            const typeName = DocumentSubTypes[type] || "Type inconnu";
            setType({ label: typeName, value: type })

        }
        else {
            const typeName = ModuleTypes[type] || "Type inconnu";
            setType({ label: typeName, value: type })
        }
    }

    const handleOnChangeTitle = (title) => {
        const { id, value } = title;
        setModule({ ...module, [id]: value, data: [{ ...module.data[0], [id]: value }] });
        setDisabledSaveButton(true);

    }

    const handleTextAreaOnChange = (textarea) => {
        const { id, value } = textarea;
        setModule({ ...module, data: [{ ...module.data[0], [id]: value }] })
        setDisabledSaveButton(false);

    }

    const handleOnChangeInput = (input) => {
        const { id, value } = input;
        setModule({ ...module, data: [{ ...module.data[0], [id]: value }] })
        setDisabledSaveButton(false);

    }

    const cleanFileNameForURL = (fileName) => {
        const splitedName = fileName
          .normalize('NFD') // Normalise les accents
          .replace(/[\u0300-\u036f]/g, '') // Supprime les diacritiques
          .replace(/[^\w.-]+/g, '-') // Remplace les caractères non valides par des tirets (y compris ’)
          .replace(/-+/g, '-') // Remplace les multiples tirets par un seul
          .replace(/^-+|-+$/g, '')
          .replace(' ', '_')
          .replace("'", '-')
          .toLowerCase().split('.'); // Convertit en minuscule pour uniformiser
          return (splitedName[0] + '-' + moment().format('YYYY-MM-DD-HH-mm-ss') + '.' + splitedName[1]);
      };

    const getFile = (file) => {
        const fileName = cleanFileNameForURL(file.file.name);
        const reader = new FileReader();
    
        reader.onload = (e) => {
            let base64;
            if (file.file.type.startsWith('image')) {
                const img = new Image();
                img.onload = function () {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const maxWidth = 800;
                    const maxHeight = 600;
                    let width = img.width;
                    let height = img.height;
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
    
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);
                    base64 = canvas.toDataURL('image/jpeg');
                    setModule({ ...module, data: [{ ...module.data[0], upload_name: fileName, upload: base64, url: "" }] });
                };
                img.src = e.target.result;
            } else {
                base64 = e.target.result
                setModule({ ...module, data: [{ ...module.data[0], upload_name: fileName, upload: base64, url: "" }] });
            }
        };
    
        reader.readAsDataURL(file.file);
    }


    const handleDeleteDocumentUpload = (file) => {
        const subModule = module.data[0];

        subModule.upload_name = "";
        subModule.upload = null;
        subModule.url = "";
        setModule({...module, upload_name: "", upload: null, url: ""})
        setDisabledSaveButton(false);
    }


    const onDropFile = (file) => {
        getFile(file)
    }

    const handleSaveModuleOnClick = () => {
        const new_module = updateModule(module);
        new_module.then(data => {
            setNewModule(data.module);
          }).catch(error => {
          });
    }

    const handleDisabledSaveButton = () => {
        const { title, description } = module;
        const subModule = module.data[0];

        let isDisabled = true;

        if (type.value === "link") {
            const { url } = subModule;
            (title && url) ? isDisabled = false : isDisabled = true;
        }

        if (type.value === "feedback") {
            const { consultant_notes } = subModule;
            (title) ? isDisabled = false : isDisabled = true;
        }
        if (type.value === "synthesis") {
            (title) ? isDisabled = false : isDisabled = true; //Add description if description is required
        }

        if (type.value === "document_to_return") {
            const { upload_name, url } = subModule;
            if (seletedOptionDocumentToReturn === "return") {
                (title) ? isDisabled = false : isDisabled = true;
            }
            else {
                title ? isDisabled = false : isDisabled = true;
            }
        }
        if (type.value === "video" || type.value === "file") {
            const { upload_name, url } = subModule;
            (title && (upload_name || url)) ? isDisabled = false : isDisabled = true;

        }

        setDisabledSaveButton(isDisabled);
        // setDisabledSaveButton(false);
    }

    ////////// specifics components ///////////
    const renderDragNDrop = () => {
        const { title, url } = module.data[0];
        return (
            <div>
                {url ? <a href={url} target="_blank" download>Document déposé</a> : "Pas de lien"}
                <div className="url-fields">
                    <DropStepZone onDrop={onDropFile} onClick={onDropFile} label={"Cliquez ou glissez et déposez votre document ici"} />
                </div>
            </div>
        )
    }

    const renderDocumentUpload = (upload_doc) => {
        return (
            <div className="document-upload">
                <p>{upload_doc}</p>
                <div className="delete-document-upload" onClick={() => handleDeleteDocumentUpload(upload_doc)}>
                    <Icon icon="close" color={"grey2"}/>
                </div>
            </div>
        )
    }

    const renderOptionsDocumentToReturn = () => {
        const submodule = module.data[0];
        const { upload_name } = submodule;
        const { title, url } = module.data[0];


        return (
            <div className='module-edit-popin-options'>
                {/* <div className='input-field-choices'>
                    <label>
                        <input type="radio" name="option" value="return" checked={seletedOptionDocumentToReturn === 'return'} onChange={(event) => setSelectedOptionDocumentToReturn(event.target.value)} />
                        Document à retourner
                    </label>
                    <label>
                        <input type="radio" name="option" value="submit" checked={seletedOptionDocumentToReturn === 'submit'} onChange={(event) => setSelectedOptionDocumentToReturn(event.target.value)} />
                        Document à déposer
                    </label>
                </div> */}
                <div>
                    <p>Votre document :</p>
                </div>
                {seletedOptionDocumentToReturn === "return" && (!upload_name && !url) && renderDragNDrop()}
                {seletedOptionDocumentToReturn === "return" && (upload_name || url) && renderDocumentUpload(upload_name ? upload_name : (title ? title : url))}
            </div>
        )
    }

    const renderOptions = () => {
        const submodule = module.data[0];
        const { upload_name } = submodule;
        return (
            <div className='module-edit-popin-options'>
                <div className='input-field-choices'>
                    <label>
                        <input type="radio" name="option" value="document" checked={selectedOption === 'document'} onChange={(event) => setSelectedOption(event.target.value)} />
                        Déposer un document
                    </label>

                    <label>
                        <input type="radio" name="option" value="link" checked={selectedOption === 'link'} onChange={(event) => setSelectedOption(event.target.value)} />
                        Ajouter un lien
                    </label>
                </div>
                {selectedOption === "link" && renderLink()}
                {selectedOption === "document" && !upload_name && renderDragNDrop()}
                {selectedOption === "document" && upload_name && renderDocumentUpload(upload_name)}
            </div>
        )
    }

    const renderLink = () => {
        const submodule = module.data[0];
        const { url } = submodule;
        return (
            <InputField name="url" title={"Lien"} required value={url ? url : ''} placeholder={"https://..."} onChange={handleOnChangeInput} type="url" />
        )
    }

    const renderFeedback = () => {
        const submodule = module.data[0];
        const { consultant_notes } = submodule;
        return (
            <div>
                <DescriptionTextArea name="consultant_notes" placeholder={"Votre feedback..."} onChange={handleTextAreaOnChange} value={consultant_notes ? consultant_notes : ''} />
            </div>
        )
    }

    const renderDocumentToReturn = () => {
        return (
            <div>
                {renderOptionsDocumentToReturn()}
            </div>
        )
    }

    const renderSynthesis = () => {
        const submodule = module.data[0];
        const { consultant_notes, consultant_private_notes } = submodule;
        return (
            <div>
                <label>Votre synthèse</label>
                <DescriptionTextArea name="consultant_notes" placeholder={"Ecrivez votre synthèse..."} onChange={handleTextAreaOnChange} value={consultant_notes ? consultant_notes : ''} />
                <label>Votre synthèse privée</label>
                <DescriptionTextArea name="consultant_private_notes" placeholder={"Ecrivez votre synthèse privée..."} onChange={handleTextAreaOnChange} value={consultant_private_notes ? consultant_private_notes : ''} />
            </div>
        )
    }

    const renderDocumentAndVideo = () => {
        return (
            <div>
                {renderOptions()}
            </div>
        )
    }

    ////////// main components ///////////
    const renderContent = () => {
        const { title } = module;
        const submodule = module.data[0];
        const { description } = submodule;

        return (
            <div className="module-edit-content">
                <InputField type="text" title={"Titre"} name="title" required onChange={handleOnChangeTitle} value={title ? title : ''} placeholder={"Ajouter le titre de la ressource"} autoFocus />
                {type.value !== "feedback" &&
                    <DescriptionTextArea name="description" placeholder={"Description..."} onChange={handleTextAreaOnChange} value={description ? description : ''} />
                }
                {type.value === "feedback" && renderFeedback()}
                {type.value === "document_to_return" && renderDocumentToReturn()}
                {type.value === "link" && renderLink()}
                {type.value === "synthesis" && renderSynthesis()}
                {(type.value === "file" || type.value === "video") && renderDocumentAndVideo()}
            </div>
        )
    }

    const renderHeader = () => {
        const { title } = moduleProps;
        const modal_title = title ? "Modifier" : "Ajouter";

        return (
            <div className="popin-header">
                <h1>{modal_title} {type.label}</h1>
            </div>
        )
    }

    const renderFooter = () => {
        const { title } = moduleProps;
        const label = title ? "Modifier" : "Créer";
        return (
            <div className="module-edit-actions">
                <PrimaryButton id="cancel" label={"Annuler"} disabled={false} onClick={onClose} outline />
                <PrimaryButton id="save" label={label} disabled={disabledSaveButton} onClick={handleSaveModuleOnClick} />
            </div>
        )
    }

    return (
        <Popin onClose={onClose} open={isOpenModal} focusTrapped={false}>
            {module &&
                <div className="module-edit-popin">
                    {renderHeader()}
                    {renderContent()}
                    {renderFooter()}
                </div>
            }
        </Popin>
    )
}
